import { Input } from "reactstrap";

interface InputSectionProps {
  value: null | string;
  onChange: (value: string) => void;
  onEnter: () => void;
}

const InputSection = ({ value, onChange, onEnter }: InputSectionProps) => {
  return (
    <div className="position-relative">
      <Input
        type="textarea"
        className="form-control form-control-lg chat-input"
        style={{
          resize: 'none',
          outline: 'none'
        }}
        id="chat-input"
        placeholder="Nhập nội dung tin nhắn"
        value={value || ""}
        onChange={(e: any) => {
          if (
            // Nếu người dùng paste vào input thì không cần xử lý
            (e.nativeEvent.inputType === "insertFromPaste") ||
            (e.target.value.substr(e.target.value.length - 1) === '\n')
          ) {
            return;
          }
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            if (!e.shiftKey) {
              onEnter();
            } else {
              onChange(value + '\n');
            }
          }
        }}
        onPaste={(e: any) => { // Xử lý đoạn người dùng paste vào input ở đây
          const items = e.clipboardData.items;
          let isFilePasted = false;
          for (let index in items) {
            const item = items[index];
            if (item.kind === 'file') {
              e.preventDefault();
              isFilePasted = true;
              break;
            }
          }
          if (!isFilePasted) {
            const pastedText = e.clipboardData.getData('Text');
            console.log("pastedText", pastedText)
            onChange(pastedText);
          }
        }}

        autoComplete="off"
      />
    </div>
  );
};
export default InputSection;
