import React, { useEffect } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// components
import Header from "./Header";

interface IndexProps {
  children: any;
  infoSetting: any;
}

const Index = (props: IndexProps) => {
  return (
    <div style={{
      height: '100vh',
      overflow: 'hidden'
    }}>
      <Header infoSetting={props.infoSetting} />
      {props.children}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Index;
