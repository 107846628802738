import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getOverviewData = (params: any) => {
    return api.create(url.STATISTICS + url.CHANNEL, {
        startTime: params.startTime,
        endTime: params.endTime,
        period: params.period,
        channelIds: params.channelIds?.split(','),
    });
};

export {
    getOverviewData,
};


const getReportInteract = (params: any) => {
    return api.create(url.INTERACT, {
        ...params,
        channelIds: params.channelIds?.split(','),
    });
};

export {
    getReportInteract,
};

