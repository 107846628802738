import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import classnames from "classnames";
// hooks
import {
  getQuickRepliesList,
  deleteQuickReplies,
  exportQuickReplies,
  getQuickRepliesTopic,
  deleteQuickRepliesTopic,
  downloadTemplate,
  updateTopicAll,
  deleteMultiQuickReplies,
} from "../../api/index";
// router
import { useSearchParams } from "react-router-dom";
// component
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FilterOutlined,
  HddFilled,
  HolderOutlined,
  LoadingOutlined,
  SortDescendingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Tooltip, Modal, Input, Button, message } from "antd";
import {
  DeleteTwoTone,
  EditFilled,
  SearchOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Row, Col } from "reactstrap";
import AddQuickReply from "./AddQuickReply/index";
import { toast } from "react-toastify";
import Table, { ColumnsType } from "antd/es/table";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { hexToRgba } from "../../utils/color";
import EmptyData from "./components/EmptyData";
import UploadQuickReplies from "./Upload";
import AddTopicModal from "./AddTopicModal";
import { on } from "events";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DragEndEvent, DndContext } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { CSS } from "@dnd-kit/utilities";
import { set } from "date-fns";
import SortQuickReplies from "./SortQuickReplyModal";
import ModalSyncQuickReply from "./ModalSyncQuickReply";
interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}
const RowContext = React.createContext<RowContextProps>({});
const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}
const RowTable: React.FC<RowProps> = props => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

interface IndexProps {}
const Index = (props: IndexProps) => {
  const { confirm } = Modal;

  const [searchParams] = useSearchParams();
  const channelId = searchParams.get("channelId");

  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState<boolean>(false);
  const [isOpenSortModal, setIsOpenSortModal] = useState<boolean>(false);
  const [showModalSync, setShowModalSync] = useState<boolean>(false);

  const [quickRepliesList, setQuickRepliesList] = useState<any>(null);
  const [messageSelected, setMessageSelected] = useState<any>(null);
  const [listQuickReplies, setlistQuickReplies] = useState<any[]>([]);
  const [quickRepliesKeyword, setQuickRepliesKeyword] = useState<string>("");
  const [isMultipleDelete, setMultipleDelete] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);

  const [topicList, setTopicList] = useState<any>(null);
  const [isOpenTopicModal, setIsOpenTopicModal] = useState<boolean>(false);
  const [listTopic, setListTopic] = useState<any[]>([]);
  const [topicKeyword, setTopicKeyword] = useState<string>("");
  const [topicSelected, setTopicSelected] = useState<any>(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleToggle = () => {
    setMultipleDelete(!isMultipleDelete);
  };

  const columnsQuickReplies: ColumnsType<any> = [
    {
      title: "STT",
      width: 100,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.index}</span>
        </div>
      ),
    },
    {
      title: "Kí tự tắt",
      width: 300,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.shortcut}</span>
        </div>
      ),
    },
    {
      title: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Chủ đề</div>
          <Tooltip title="Sắp xếp">
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={onOpenSort}
              style={{
                padding: "8px",
                display: "inline-block",
                borderRadius: "4px",
                backgroundColor: isHovered ? "#ebebeb" : "transparent",
                transition: "background-color 0.3s",
              }}
            >
              <HddFilled
                style={{
                  color: isHovered ? "#818181" : "#b1b1b1",
                  transition: "color 0.3s",
                }}
              />
            </div>
          </Tooltip>
        </div>
      ),
      width: 200,
      filters: [
        {
          text: (
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "1px 8px",
                backgroundColor: "#f0f0f0",
                borderRadius: 20,
              }}
            >
              Không chọn chủ đề
            </div>
          ),
          value: "",
        },
        ...(topicList?.items || []).map((topic: any) => ({
          text: (
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "1px 8px",
                backgroundColor: topic.color,
                color: "#ffffff",
                borderRadius: 20,
              }}
            >
              {topic.name}
            </div>
          ),
          value: topic.id,
        })),
      ],
      onFilter: (value, record) =>
        value === "" ? !record.topicId : record.topicId === value,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 100,
            borderRadius: 10,
            color: "white",
            backgroundColor: topicList?.items?.find(
              (topic: any) => topic.id === record?.topicId,
            )?.color,
          }}
        >
          <span>
            {
              topicList?.items?.find(
                (topic: any) => topic.id === record?.topicId,
              )?.name
            }
          </span>
        </div>
      ),
    },
    {
      title: "Tin nhắn",
      render: record =>
        record?.messages?.map((message: any, index: number) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            {message?.photos?.length > 0 && (
              <div
                style={{
                  position: "relative",
                  width: 24,
                  height: 24,
                  marginRight: 8,
                  borderRadius: 6,
                }}
              >
                <img
                  src={message?.photos[0]?.location}
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 6,
                  }}
                />
                {message?.photos?.length > 1 && (
                  <Tooltip
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {message?.photos?.map((photo: any, index: number) => (
                          <img
                            key={index}
                            src={photo?.location}
                            style={{
                              width: 24,
                              height: 24,
                              borderRadius: 6,
                              marginLeft: index === 0 ? 0 : 8,
                            }}
                          />
                        ))}
                      </div>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 24,
                        height: 24,
                        borderRadius: 6,
                        backgroundColor: "#0000004d",
                      }}
                    >
                      <span style={{ color: "white" }}>
                        +{message?.photos?.length - 1}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            {message?.files?.length > 0 && (
              <Tooltip
                title={message?.files?.map((file: any, index: number) => (
                  <div key={index}>{file?.originalname}</div>
                ))}
              >
                <div
                  style={{
                    width: 14,
                    height: 14,
                    position: "relative",
                    marginRight: 16,
                  }}
                >
                  <PaperClipOutlined style={{ fontSize: 14 }} />
                  <div
                    style={{
                      fontSize: message?.files?.length > 99 ? 7 : 10,
                      position: "absolute",
                      top: -6,
                      right: -9,
                      width: 16,
                      height: 16,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                      border: "1px solid white",
                    }}
                  >
                    {message?.files?.length > 99
                      ? "99+"
                      : message?.files?.length}
                  </div>
                </div>
              </Tooltip>
            )}
            <Tooltip title={message?.message}>
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {message?.message}
              </span>
            </Tooltip>
          </div>
        )),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: 100,
          }}
        >
          <div
            className="btn-delete-tag"
            onClick={() => onOpenUpdateQuickReplies(record)}
          >
            <EditFilled
              style={{
                fontSize: 20,
                marginRight: 16,
              }}
            />
          </div>
          <div
            className="btn-delete-tag"
            onClick={() => onDeleteQuickReplies(record?.id)}
          >
            <DeleteTwoTone
              twoToneColor="red"
              style={{
                fontSize: 20,
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const columnsTopic: ColumnsType<any> = [
    { key: "sort", align: "center", width: 60, render: () => <DragHandle /> },
    {
      title: "Tên chủ đề",
      width: 400,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.name}</span>
        </div>
      ),
    },
    {
      title: "Màu chủ đề",
      render: record => (
        <div
          style={{
            backgroundColor: record.color,
            width: 80,
            height: 20,
            borderRadius: 10,
          }}
        />
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: 100,
          }}
        >
          <div
            className="btn-delete-tag"
            onClick={() => onOpenUpdateTopic(record)}
          >
            <EditFilled
              style={{
                fontSize: 20,
                marginRight: 16,
              }}
            />
          </div>
          <div
            className="btn-delete-tag"
            onClick={() => onDeleteTopic(record?.id)}
          >
            <DeleteTwoTone
              twoToneColor="red"
              style={{
                fontSize: 20,
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      const ids = selectedRows.map((row: any) => row.id);
      setSelectedIds(ids);
      setSelectedCount(selectedRows.length);
    },
  };

  useEffect(() => {
    if (channelId) {
      getTopicListData({
        channelId: channelId || "",
      });
      getQuickRepliesListData({
        channelId: channelId || "",
      });
    }
  }, [channelId]);

  const getQuickRepliesListData = async (data: any) => {
    try {
      const response: any = await getQuickRepliesList({
        channelId: data.channelId,
        isAll: true,
      });
      if (response?.data?.items) {
        if (response?.data?.items) {
          setQuickRepliesList(response.data);
          if (topicKeyword === "") {
            setlistQuickReplies(
              response.data.items?.sort(
                (a: any, b: any) => a.sequence - b.sequence,
              ),
            );
          } else {
            setlistQuickReplies(
              response.data.items
                ?.filter((item: any) =>
                  item.name.toLowerCase().includes(topicKeyword.toLowerCase()),
                )
                ?.sort((a: any, b: any) => a.sequence - b.sequence),
            );
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTopicListData = async (data: any) => {
    try {
      const response: any = await getQuickRepliesTopic({
        channelId: data.channelId,
        isAll: true,
      });
      if (response?.data?.items) {
        if (response?.data?.items) {
          setTopicList(response.data);
          if (topicKeyword === "") {
            setListTopic(
              response.data.items?.sort(
                (a: any, b: any) => a.sequence - b.sequence,
              ),
            );
          } else {
            setListTopic(
              response.data.items
                ?.filter((item: any) =>
                  item.name.toLowerCase().includes(topicKeyword.toLowerCase()),
                )
                ?.sort((a: any, b: any) => a.sequence - b.sequence),
            );
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onOpen = () => {
    setIsOpenCreateModal(true);
  };

  const onOpenTopic = () => {
    setIsOpenTopicModal(true);
  };

  const onOpenUpload = () => {
    setIsOpenUploadModal(true);
  };

  const onOpenSort = () => {
    setIsOpenSortModal(true);
  };

  const onOpenUpdateQuickReplies = (quickReplies: any) => {
    setMessageSelected(quickReplies);
    setIsOpenCreateModal(true);
  };

  const onOpenUpdateTopic = (topic: any) => {
    setTopicSelected(topic);
    setIsOpenTopicModal(true);
  };

  const onCloseCreateModal = () => {
    setIsOpenCreateModal(false);
    setMessageSelected(null);
  };

  const onCloseUploadModal = () => {
    setIsOpenUploadModal(false);
  };

  const onCloseSortModal = () => {
    setIsOpenSortModal(false);
  };

  const onCloseTopicModal = () => {
    setIsOpenTopicModal(false);
  };

  const onSuccess = () => {
    setIsOpenCreateModal(false);
    setMessageSelected(null);
    getQuickRepliesListData({
      channelId: channelId || "",
    });
  };

  const onSuccessSort = () => {
    setIsOpenSortModal(false);
  };

  const onSuccessTopic = () => {
    setIsOpenTopicModal(false);
    setTopicSelected(null);
    getTopicListData({
      channelId: channelId || "",
    });
  };

  const onDragEndTopic = ({ active, over }: DragEndEvent) => {
    if (active && over && active.id !== over.id) {
      const oldIndex = topicList?.items?.findIndex(
        (item: any) => item.id === active.id,
      );
      const newIndex = topicList?.items?.findIndex(
        (item: any) => item.id === over.id,
      );
      const newList = arrayMove(topicList?.items, oldIndex, newIndex).map(
        (item: any, key: number) => ({ ...item, sequence: key }),
      );
      setTopicList({
        ...topicList,
        items: newList,
      });
      if (topicKeyword === "") {
        setListTopic(newList);
      } else {
        setListTopic(
          newList?.filter((item: any) =>
            item.name.toLowerCase().includes(topicKeyword.toLowerCase()),
          ),
        );
      }
      onUpdateTopic(newList);
    }
  };

  const onUpdateTopic = async (newList: any) => {
    try {
      const response: any = await updateTopicAll(channelId || "", {
        items: newList,
      });
      if (response?.data && response?.success) {
        message.success("Cài đặt đã được cập nhật");
      } else {
        toast.error(
          response?.data?.message?.error ||
            response?.data?.message ||
            "Có lỗi xảy ra",
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onUpdateQuickRepiles = async (newList: any) => {
    try {
      const response: any = await updateTopicAll(channelId || "", {
        items: newList,
      });
      if (response?.data && response?.success) {
        message.success("Cài đặt đã được cập nhật");
      } else {
        toast.error(
          response?.data?.message?.error ||
            response?.data?.message ||
            "Có lỗi xảy ra",
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onDeleteQuickReplies = async (quickRepliesId: string) => {
    try {
      const response: any = await deleteQuickReplies(quickRepliesId);
      if (response?.data && response?.success) {
        getQuickRepliesListData({
          channelId: channelId || "",
        });
        toast.success("Xóa thành công");
      } else {
        toast.error(
          response?.data?.message?.error ||
            response?.data?.message ||
            "Có lỗi xảy ra",
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onMultiDeleteQuickReplies = async (quickRepliesIds: any) => {
    try {
      console.log("quickRepliesIds", quickRepliesIds);
      const response: any = await deleteMultiQuickReplies(quickRepliesIds);
      if (response?.data && response?.success) {
        getQuickRepliesListData({
          channelId: channelId || "",
        });
        setSelectedCount(0);
        setSelectedIds([]);
        toast.success("Xóa thành công");
      } else {
        toast.error(
          response?.data?.message?.error ||
            response?.data?.message ||
            "Có lỗi xảy ra",
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onDeleteTopic = async (topicId: string) => {
    try {
      const response: any = await deleteQuickRepliesTopic(topicId);
      if (response?.data && response?.success) {
        getTopicListData({
          channelId: channelId || "",
        });
        toast.success("Xóa thành công");
      } else {
        toast.error(
          response?.data?.message?.error ||
            response?.data?.message ||
            "Có lỗi xảy ra",
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const searchQuickRepliess = (e: any) => {
    const value = e.target.value;
    setQuickRepliesKeyword(value);
    if (value === "") {
      setlistQuickReplies(quickRepliesList?.items);
    } else {
      setlistQuickReplies(
        quickRepliesList?.items?.filter(
          (item: any) =>
            item.shortcut.toLowerCase().includes(value.toLowerCase()) ||
            item.messages?.some((message: any) =>
              message.message.toLowerCase().includes(value.toLowerCase()),
            ),
        ),
      );
    }
  };

  const searchTopic = (e: any) => {
    const value = e.target.value;
    setTopicKeyword(value);
    if (value === "") {
      setListTopic(
        topicList?.items?.sort((a: any, b: any) => a.sequence - b.sequence),
      );
    } else {
      setListTopic(
        topicList?.items
          ?.filter((item: any) =>
            item.name.toLowerCase().includes(value.toLowerCase()),
          )
          ?.sort((a: any, b: any) => a.sequence - b.sequence),
      );
    }
  };

  const onExport = async () => {
    setLoadingExcel(true);
    const data = {
      channelId,
    };
    const res: any = await exportQuickReplies(data);
    setLoadingExcel(false);
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `Danh_sách_trả_lời_nhanh.xlsx`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div
      className={classnames("user-chat", "w-100", "overflow-auto")}
      id="user-chat"
      style={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="chat-content p-3 p-lg-4 w-100">
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <span
            className="username"
            style={{ fontWeight: "500", fontSize: 18 }}
          >
            Quản lý trả lời nhanh
          </span>
        </div>
        <div className="p-3 bg-white" style={{ borderRadius: 8 }}>
          <div
            style={{
              marginBottom: 24,
              borderBottom: "1px solid #d0d0d0",
            }}
          >
            <span
              className="username"
              style={{
                fontSize: 15,
                color: localStorage.getItem("colorTheme") || "#4eac6d",
              }}
            >
              Trả lời nhanh
            </span>
            <div
              style={{
                marginTop: 16,
                width: 88,
                height: 2,
                backgroundColor:
                  localStorage.getItem("colorTheme") || "#4eac6d",
              }}
            />
          </div>
          <Row className="align-items-center">
            <Col sm={4}>
              <Input
                placeholder="Tìm kiếm tin nhắn"
                prefix={<SearchOutlined />}
                onChange={searchQuickRepliess}
                value={quickRepliesKeyword}
              />
            </Col>
            <Col sm={8} className="d-flex gap-2 justify-content-end">
              <div
                style={{
                  color: localStorage.getItem("colorTheme") || "#4eac6d",
                  textAlign: "center",
                  padding: "4px 8px",
                  borderRadius: 10,
                  display: selectedCount > 0 ? "flex" : "none",
                  backgroundColor: hexToRgba(
                    localStorage.getItem("colorTheme") || "#2f54eb",
                    0.3,
                  ),
                }}
              >
                Đã chọn {selectedCount}
              </div>
              <Button
                style={{ display: selectedCount > 0 ? "flex" : "none" }}
                type="primary"
                danger={isMultipleDelete}
                icon={<DeleteOutlined />}
                onClick={() => onMultiDeleteQuickReplies(selectedIds)}
              ></Button>
              <Button
                type={isMultipleDelete ? "primary" : "default"}
                icon={<CheckOutlined />}
                onClick={handleToggle}
              />
              <div style={{ backgroundColor: "#ccc", width: "1px" }}></div>
              <Tooltip placement="topLeft" title={"Tải lên"}>
                <Button icon={<UploadOutlined />} onClick={onOpenUpload} />
              </Tooltip>
              <Tooltip placement="topLeft" title={"Tải xuống"}>
                <Button
                  icon={
                    loadingExcel ? <LoadingOutlined /> : <DownloadOutlined />
                  }
                  onClick={onExport}
                />
              </Tooltip>
              <Tooltip placement="topLeft" title={"Sao chép"}>
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => setShowModalSync(true)}
                />
              </Tooltip>
              <Button type="primary" onClick={onOpen}>
                Thêm mẫu
              </Button>
            </Col>
          </Row>
          {isOpenCreateModal && (
            <AddQuickReply
              isOpen={isOpenCreateModal}
              onClose={onCloseCreateModal}
              onSuccess={onSuccess}
              channelSelected={channelId || ""}
              messageSelected={messageSelected}
              listTopic={topicList?.items?.length > 0 ? topicList?.items : []}
            />
          )}
          {isOpenUploadModal && (
            <UploadQuickReplies
              isOpen={isOpenUploadModal}
              onClose={() => {
                setIsOpenUploadModal(false);
                getQuickRepliesListData({
                  channelId: channelId || "",
                });
              }}
              onSuccess={onSuccess}
              channelId={channelId || ""}
            />
          )}
          {isOpenSortModal && (
            <SortQuickReplies
              isOpen={isOpenSortModal}
              quickRepliesList={quickRepliesList?.items || []}
              topicList={topicList || []}
              onClose={onCloseSortModal}
              onSuccess={onSuccessSort}
            />
          )}
          {showModalSync && (
            <ModalSyncQuickReply
              isOpen={showModalSync}
              onClose={() => setShowModalSync(false)}
              channelId={channelId || ""}
              allQuickReply={quickRepliesList?.items}
              topicList={topicList || []}
            />
          )}
          <div
            className="bg-white"
            id="quickReplies-list"
            style={{
              borderRadius: 8,
              marginTop: 16,
              zIndex: 1,
              height: "calc(50vh - 230px)",
              overflowY: "auto",
              overflowX: "hidden",
              border: "1px solid #d0d0d0",
              position: "relative",
            }}
          >
            <Table
              rowKey="key"
              rowClassName="btn-tag"
              rowSelection={
                isMultipleDelete
                  ? { type: "checkbox", ...rowSelection }
                  : undefined
              }
              pagination={false}
              components={{ body: { row: RowTable } }}
              columns={columnsQuickReplies}
              dataSource={(listQuickReplies || []).map((e, index) => ({
                key: e.id,
                index: index + 1,
                ...e,
              }))}
            />
          </div>
        </div>
      </div>

      <div className="chat-content w-100" style={{ padding: "0 1.5rem" }}>
        <div className="flex p-3 bg-white" style={{ borderRadius: 8 }}>
          <span style={{ fontSize: 15, fontWeight: 500 }}>
            Chủ đề câu trả lời nhanh
          </span>
          <div
            style={{
              color: localStorage.getItem("colorTheme") || "#4eac6d",
              textAlign: "center",
              padding: "0px 8px",
              marginLeft: 8,
              borderRadius: 10,
              display: listTopic?.length > 0 ? "inline-flex" : "none",
              backgroundColor: hexToRgba(
                localStorage.getItem("colorTheme") || "#2f54eb",
                0.3,
              ),
            }}
          >
            {listTopic?.length} chủ đề{" "}
          </div>

          <div style={{ fontSize: 12, paddingTop: 4, paddingBottom: 16 }}>
            Chủ đề cho mẫu câu trả lời nhanh
          </div>
          <Row className="align-items-center">
            <Col sm={4}>
              <Input
                placeholder="Tìm kiếm chủ đề"
                prefix={<SearchOutlined />}
                onChange={searchTopic}
                value={topicKeyword}
              />
            </Col>
            <Col sm={8} className="d-flex gap-2 justify-content-end">
              <Button type="primary" onClick={onOpenTopic}>
                Thêm chủ đề
              </Button>
            </Col>
          </Row>
          {isOpenTopicModal && (
            <AddTopicModal
              isOpen={isOpenTopicModal}
              onClose={onCloseTopicModal}
              onSuccess={onSuccessTopic}
              channelSelected={channelId || ""}
              topicLength={topicList?.total || 0}
              topicSelected={topicSelected}
              topicList={topicList?.items?.length > 0 ? topicList?.items : []}
            />
          )}
          {listTopic?.length > 0 ? (
            <div
              className="bg-white"
              id="topic-list"
              style={{
                borderRadius: 8,
                marginTop: 16,
                height: "calc(50vh - 230px)",
                overflowY: "auto",
                overflowX: "hidden",
                border: "1px solid #d0d0d0",
              }}
            >
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEndTopic}
              >
                <SortableContext
                  items={listTopic.map((i, key) => i.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    rowKey="key"
                    rowClassName="btn-tag"
                    pagination={false}
                    components={{ body: { row: RowTable } }}
                    columns={columnsTopic}
                    dataSource={listTopic.map((e, index) => ({
                      key: e.id,
                      index: index + 1,
                      ...e,
                    }))}
                  />
                </SortableContext>
              </DndContext>
            </div>
          ) : (
            <EmptyData key={"topic"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
