const EmptyData = () => {
  return (
    <div
      className="bg-white"
      id="tag-list"
      style={{
        borderRadius: 8,
        marginTop: 16,
        height: 'calc(50vh - 230px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        border: '1px solid #d0d0d0',
        width: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          fontSize: 20,
          color: '#d0d0d0',
        }}
      >
        Không có dữ liệu
      </div>
    </div>
  );
}
export default EmptyData;