import React, { useEffect, useState } from "react";

// component
import { Input, Tooltip } from 'antd';

interface IndexProps {
    groupKey?: string;
    onSetName?: (groups: any) => void;
    name: string;
    disabled?: boolean;
    groups?: any;
}

const GroupName = (props: IndexProps) => {
    const { groupKey, groups, name, disabled, onSetName } = props;
    const [groupName, setGroupName] = useState<string>(name);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setGroupName(e.target.value);
    };

    const onBlur = () => {
        setIsEdit(false);
        const arr = groups.map((item: any) => {
            if (item.key === groupKey) {
                return {
                    ...item,
                    name: groupName,
                }
            }
            return item;
        });
        onSetName && onSetName(arr);
    }

    return (
        <>
            {
                isEdit ? (
                    <Input
                        value={groupName}
                        onChange={onChange}
                        onPressEnter={onBlur}
                        onBlur={onBlur}
                        autoFocus
                        style={{
                            width: 80,
                            height: 25,
                        }}
                        onKeyDown={e => e.stopPropagation()}
                    />
                ) : disabled ? (
                    <span style={{fontSize: 16}}>
                        {name}
                    </span>
                ) : (
                    <Tooltip title={'Double click để chỉnh sửa'}>
                        <span
                            onDoubleClick={() => setIsEdit(true)}
                            style={{fontSize: 16}}
                        >
                            {name}
                        </span>
                    </Tooltip>
                )
            }
        </>
    );
};

export default GroupName;
