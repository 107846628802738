// channel
export const GET_PAGE_LIST = "/channels/fb-pages";
export const CHANNELS = "/channels";
export const CHANNEL = "/channel";

// conversation
export const GET_CONVERSATION_LIST = "/conversations";
export const GET_CONVERSATION_DETAIL = "/conversations";

// message
export const MESSAGES = "/messages";
export const HISTORIES = "/histories";

// tags
export const TAGS = "/tags";
export const SYNC = "/sync";

// quickReplies
export const QUICk_REPLIES = "/quick-replies";
export const QUICK_REPLIES_TOPIC = "/quick-replies-topic";

// statistics
export const STATISTICS = "/statistics";
export const INTERACT = "/statistics/engagements";

//auth
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const JWT_REGISTER = "/post-jwt-register";
export const POST_FAKE_REGISTER = "/post-fake-register";
export const AUTH_ME = "/auth/me";
export const USER_CHANGE_PASSWORD = "/user-change-password";

// profile & settings
export const GET_PROFILE_DETAILS = "/profile-details";
export const GET_USER_SETTINGS = "/user-settings";
export const UPDATE_ETTINGS = "/update-user-settings";
export const GET_SETTINGS = "/settings/public";


// contacts
export const GET_CONTACTS = "/user-contacts";
export const INVITE_CONTACT = "/invite-contact";

// calls
export const GET_CALLS_LIST = "/calls-list";

// bookmarks
export const GET_BOOKMARKS_LIST = "/bookmarks-list";
export const DELETE_BOOKMARK = "/bookmarks-delete";
export const UPDATE_BOOKMARK = "/bookmarks-update";

// chats
export const GET_FAVOURITES = "/get-favourites";
export const GET_DIRECT_MESSAGES = "/get-direct-messages";
export const GET_CHANNELS = "/get-channles";
export const ADD_CONTACTS = "/add-contact";
export const CREATE_CHANNEL = "/create-channel";
export const GROUP = "/groups";
export const UPDATE_MULTIPLE = "/update-multiple";
export const GET_CHAT_USER_DETAILS = "/get-user-details";
export const GET_CHAT_USER_CONVERSATIONS = "/get-user-conversations";
export const SEND_MESSAGE = "/messages";
export const RECEIVE_MESSAGE = "/receive-message";
export const READ_MESSAGE = "/read-message";
export const RECEIVE_MESSAGE_FROM_USER = "/receive-message-from-user";
export const DELETE_MESSAGE = "/delete-message";
export const FORWARD_MESSAGE = "/forward-message";
export const DELETE_USER_MESSAGES = "/delete-user-messages";
export const TOGGLE_FAVOURITE_CONTACT = "/toggle-favourite-contact";
export const GET_ARCHIVE_CONTACT = "/get-archive-contacts";
export const TOGGLE_ARCHIVE_CONTACT = "/toggle-archive-contact";
export const READ_CONVERSATION = "/read-conversation";
export const DELETE_IMAGE = "/user-delete-img";

// groups
export const GET_CHANNEL_DETAILS = "/get-channel-details";

// order

export const CREATE_ORDER = "/customers";
export const GETLIST_ORDER = "/customers";
export const CHECKOUT_ORDER = "/customers";

export const GET_LIST_COUNTRIES = "/locations/countries";
export const GETLIST_ORDER_REPORT = "/orders";
export const EXPORT_ORDER_REPORT = "/orders/export-xlsx";
export const GETLIST_CUSTOMERS = "/customers";
export const GETLIST_CHANNELS = "/channels";

//  files
export const CONTENT = "/content";
export const FOLDER = "/folder";


//  translations
export const TRANSLATIONS_DETECT = "/translations/detect";
export const TRANSLATIONS_TRANSLATE= "/translations/translate";
export const TRANSLATIONS_LANGUAGES= "/translations/languages";
export const UPDATE_TRANSLATIONS_MESSID = "/translations";



// customer
export const CUSTOMERS = "/customers";


//quick_notes
export const QUICK_NOTES = "/quick-notes";

export const ON_SYNC = "/channels";

// users 
export const USERS = "/users";



// teams 
export const TEAMS = "/teams";
export const TEAM_MEMBERS = "/team-members";


