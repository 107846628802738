import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();


const createOrder = (data: any) => {
  return api.create(`${url.CREATE_ORDER}/${data.selectedChat}/orders`, data);
};

const checkoutOrder = (data: any) => {
  return api.create(`${url.CHECKOUT_ORDER}/${data.selectedChat}/orders/checkout`, data);
};



const getListOrders = (params: any) => {
  return api.get(`${url.GETLIST_ORDER}/${params.selectedChat}/orders`,{ params: params });
};

const getCountries = () => {
  return api.get(`${url.GET_LIST_COUNTRIES}`);
};

const getListOrdersReport = (params: any) => {
  return api.get(`${url.GETLIST_ORDER_REPORT}`,{ params: params });
};

const exportListOrderReport = (data: any) => {
  return api.export(`${url.EXPORT_ORDER_REPORT}`, data);
};


const getListCustomers = (params: any) => {
  return api.get(`${url.GETLIST_CUSTOMERS}`,{ params: params });
};
const getListChannels = (params: any) => {
  return api.get(`${url.GETLIST_CHANNELS}`,{ params: params });
};


export {
    createOrder,
    getCountries,
    getListOrders,
    checkoutOrder,
    getListOrdersReport,
    getListCustomers,
    getListChannels,
    exportListOrderReport
};
