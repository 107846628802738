import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getPageListConnected = (params: any) => {
    return api.get(url.CHANNELS, { params: params });
};

const getPageSelected = (params: any) => {
    let obj: any = {
        channelIds: params.channelIds?.split(','),
    };
    if (params.isAll) {
        obj.isAll = params.isAll;
    }
    return api.create(url.CHANNELS + '/list', obj);
};

const getPageList = (params: any) => {
    return api.get(url.GET_PAGE_LIST, { params: params });
};

const connectPage = (data: any) => {
    return api.create(url.CHANNELS, data);
};

const cancelPage = (id: string) => {
    return api.delete(url.CHANNELS + '/' + id);
};

const getGroupDetail = (id: string) => {
    return api.get(url.CHANNELS + url.GROUP + '/' + id);
};

export {
    getPageListConnected,
    getPageSelected,
    getPageList,
    connectPage,
    cancelPage,
    getGroupDetail
};
