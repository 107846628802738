import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();


const getListUsers = (params: any) => {
  return api.get(`${url.USERS}`,{ params: params });
};

const updateUser = (data: any) => {
    return api.create(`${url.USERS}/update-info`, data);
};

const updateSettings = (data: any) => {
  return api.create(`${url.USERS}/me/update-settings`, data);
};

  

export {
    getListUsers,
    updateUser,
    updateSettings,
};
