import React, { useEffect, useRef, useState } from "react";
import _, { values } from 'lodash';

import { Input, Select, Button, Form } from 'antd';

import styled from "styled-components";


const Option = Select.Option;

interface PopUpEditUserProps {
  className?: string;
  itemSelected: any;
  onUpdate: any;
}

const STATUS = [
  {
    id: "1",
    name: 'Hoạt động',
  },
  {
    id: "2",
    name: 'Không hoạt động',
  },
]
const ROLE = [
  {
    id: 'SystemAdmin',
    name: 'System admin',
  },
  {
    id: 'Admin',
    name: 'Admin',
  },
  {
    id: 'Manager',
    name: 'Manager',
  },
  {
    id: 'User',
    name: 'User',
  },
]

const PopUpEditUser = (props: PopUpEditUserProps) => {
  const {itemSelected, onUpdate} = props;


  const onFinish = (values: any) => {
    onUpdate({
      userId: itemSelected.id,
      active: values.active === '1' ? true : false,
      role: values.role,
    });
  }
  const onFinishFailed = () => {
    
  }


  return (
    <div className={props.className}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ name: itemSelected.name, active: itemSelected.active ? '1' : "2", role: itemSelected.role }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Tên người dùng"
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập tên người dùng' }]}
        >
          <Input disabled placeholder="Nhập tên người dùng"/>
        </Form.Item>

        <Form.Item
          label="Trạng thái"
          name="active"
          rules={[{ required: true, message: 'Vui lòng chọn trạng thái' }]}
        >
          <Select
            placeholder="Chọn trạng thái"
          >
            {
                STATUS.map((item: any) => (
                    <Option key={item.id} value={item.code}>{item.name}</Option>
                ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: 'Vui lòng chọn role' }]}
        >
          <Select
            placeholder="Chọn Role"
          >
            {
                ROLE.map((item: any) => (
                    <Option key={item.id} value={item.code}>{item.name}</Option>
                ))
            }
          </Select>
        </Form.Item>


        <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default styled(PopUpEditUser)`
  width: 100%;
  overflow: hidden;
  margin-top: 40px;
  .filtter {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .title_page {
    margin-bottom: 15px;
    font-weight: 600;
  }

`