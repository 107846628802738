import React, { useEffect, useState } from "react";

// scss
import "./assets/scss/theme.scss";

//Route
import Routes from "./routes";
import { getAuthMe, getSettingsApp } from "./api/auth";
import { ConfigProvider } from "antd";
import { authLoginApiResponseSuccess } from "./redux/actions";
import { AuthLoginActionTypes } from "./redux/auth/login/types";

const App = () => {
  const [infoSetting, setInfoSetting] = useState({});
  const [colorTheme, setColorTheme] = useState('');

  const getColorTheme = (title?: string) => {
    if (title && title.length && title.includes('HHQ')) {
      return '#f86f12';
    }
    return '#4eac6d';
  }

  function hexToRgb(hex: any) {
    hex = hex.replace(/^#/, '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `${r}, ${g}, ${b}`;
  }


  const getLogoTitle = async () => {
    const res: any = await getSettingsApp();
    if (res?.success) {
      localStorage.setItem("setting", JSON.stringify(res?.data));
      document.title = res?.data?.title || '';
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = res?.data?.logoUrl || '';
      setInfoSetting(res?.data)
      localStorage.setItem("colorTheme", getColorTheme(res?.data?.title));
      setColorTheme(getColorTheme(res?.data?.title));
      // set color theme
      document.documentElement.style.setProperty("--bs-primary-rgb", hexToRgb(getColorTheme(res?.data?.title)));
    }
  }


  const customTheme = {
    token: {
      colorPrimary: colorTheme,
    },
  };

  useEffect(() => {
    getLogoTitle()
  }, []);

 

  return (<ConfigProvider theme={customTheme}>
    <Routes infoSetting={infoSetting} />
  </ConfigProvider>);

};

export default App;
