import { Navigate } from "react-router-dom";

const AuthProtected = (props: any) => {
  const token = localStorage.getItem("token");

  /*
    redirect is un-auth access protected routes via url
  */
  if (!token) {
    return (
      <Navigate to={{ pathname: "/auth-login"}} />
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };
