import {
  FileAddOutlined,
  FileExcelOutlined,
  FileOutlined,
  FileSyncOutlined,
  HddFilled,
  PaperClipOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Table,
  Tooltip,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useState } from "react";
import { downloadTemplate, importQuickReplies, uploadQuickReplies } from "../../../api";
import { toast } from "react-toastify";
import { hexToRgba } from "../../../utils/color";
import { ColumnsType } from "antd/es/table";
import { RowTable } from "../components/RowTable";

interface UploadQuickRepliesProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  channelId: string;
}

interface CustomRequestProps {
  file: File;
}

const UploadQuickReplies = ({
  isOpen,
  onClose,
  onSuccess,
  channelId,
}: UploadQuickRepliesProps) => {
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [modeValue, setModeValue] = useState("UPDATE_DUPLICATE");
  const [isScreenUpload, setIsScreenUpload] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [listQuickReplies, setListQuickReplies] = useState<any[]>([]);
  const [listTopic, setListTopic] = useState<any[]>([]);
  const [quickReplyIds, setQuickReplyIds] = useState<any[]>([]);

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: ".xlsx, .csv",
    customRequest: async (options: any) => {
      const { file, onSuccess, onError } = options;
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response: any = await uploadQuickReplies(formData);
        if (response?.data && response?.success) {
          // check if response.quickReplies has id in row
          let quickReplyIds = response.data.quickReplies.filter(
            (item: any) => item.id !== undefined,
          );
          setListQuickReplies(quickReplyIds);
          setListTopic(response.data.topices);
          setIsDisabled(false);
          onSuccess(response.data);
        } else {
          toast.error(
            response?.data?.message?.error ||
              response?.data?.message ||
              "Có lỗi xảy ra",
          );
        }
      } catch (error) {
        onError("Có lỗi xảy ra");
      }
    },
    beforeUpload: (file: any) => {
      const isValidType =
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "text/csv";

      if (!isValidType) {
        // message.error('Chỉ được phép tải lên các tệp .xlsx hoặc .csv!');
      }

      return isValidType;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status === "done") {
        setUploadedFileName(info.file.name);
      } else if (status === "error") {
        setUploadedFileName(null);
      }
    },
  };

  const onSelectQuickReply = (e: any, id: string) => {
    if (e.target.checked) {
      setQuickReplyIds([...quickReplyIds, id]);
    } else {
      setQuickReplyIds(quickReplyIds.filter((item: any) => item !== id));
    }
  };

  const onChangeQuickReply = (e: any) => {
    if (e.target.checked) {
      setQuickReplyIds(listQuickReplies?.map((item: any) => item.id));
    } else {
      setQuickReplyIds([]);
    }
  };

  const columnsQuickReplies: ColumnsType<any> = [
    {
      key: "sort",
      align: "center",
      title: (
        <Checkbox
          indeterminate={
            quickReplyIds?.length > 0 &&
            quickReplyIds?.length < listQuickReplies?.length
          }
          onChange={onChangeQuickReply}
          checked={quickReplyIds?.length === listQuickReplies?.length}
        />
      ),
      width: 50,
      render: record => (
        <Checkbox
          onChange={e => onSelectQuickReply(e, record?.id)}
          checked={quickReplyIds?.includes(record?.id)}
        />
      ),
    },
    {
      title: "STT",
      width: 80,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.index}</span>
        </div>
      ),
    },
    {
      title: "Kí tự tắt",
      width: 120,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{record.shortcut}</span>
        </div>
      ),
    },
    {
      title: "Chủ đề",
      width: 150,
      render: record => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 100,
            borderRadius: 10,
            color: "white",
            backgroundColor: listTopic?.find(
              (topic: any) => topic.name === record?.topicName,
            )?.color,
          }}
        >
          <span>
            {
              listTopic?.find((topic: any) => topic.name === record?.topicName)
                ?.name
            }
          </span>
        </div>
      ),
    },
    {
      title: "Tin nhắn",
      render: record =>
        record?.messages?.map((message: any, index: number) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            {message?.photos?.length > 0 && (
              <div
                style={{
                  position: "relative",
                  width: 24,
                  height: 24,
                  marginRight: 8,
                  borderRadius: 6,
                }}
              >
                <img
                  src={message?.photos[0]?.location}
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 6,
                  }}
                />
                {message?.photos?.length > 1 && (
                  <Tooltip
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {message?.photos?.map((photo: any, index: number) => (
                          <img
                            key={index}
                            src={photo?.location}
                            style={{
                              width: 24,
                              height: 24,
                              borderRadius: 6,
                              marginLeft: index === 0 ? 0 : 8,
                            }}
                          />
                        ))}
                      </div>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 24,
                        height: 24,
                        borderRadius: 6,
                        backgroundColor: "#0000004d",
                      }}
                    >
                      <span style={{ color: "white" }}>
                        +{message?.photos?.length - 1}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            {message?.files?.length > 0 && (
              <Tooltip
                title={message?.files?.map((file: any, index: number) => (
                  <div key={index}>{file?.originalname}</div>
                ))}
              >
                <div
                  style={{
                    width: 14,
                    height: 14,
                    position: "relative",
                    marginRight: 16,
                  }}
                >
                  <PaperClipOutlined style={{ fontSize: 14 }} />
                  <div
                    style={{
                      fontSize: message?.files?.length > 99 ? 7 : 10,
                      position: "absolute",
                      top: -6,
                      right: -9,
                      width: 16,
                      height: 16,
                      backgroundColor: "red",
                      borderRadius: "50%",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                      border: "1px solid white",
                    }}
                  >
                    {message?.files?.length > 99
                      ? "99+"
                      : message?.files?.length}
                  </div>
                </div>
              </Tooltip>
            )}
            <Tooltip title={message?.message}>
              <span
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {message?.message}
              </span>
            </Tooltip>
          </div>
        )),
    },
  ];

  const onChangeMode = (e: RadioChangeEvent) => {
    setModeValue(e.target.value);
  };
  const UploadFile = () => {
    return (
      <Row style={{ width: "65%", margin: "24px 0" }}>
        <Dragger className="w-100" {...props}>
          <div className="d-flex flex-column align-items-center justify-content-center py-5">
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: "50%",
                backgroundColor: "#F2F4F7",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#AEB5C3",
                fontSize: 20,
                marginBottom: 12,
              }}
            >
              {uploadedFileName ? <FileExcelOutlined /> : <FileOutlined />}
            </div>
            <div style={{ fontSize: 16, fontWeight: 500 }}>
              {uploadedFileName ? uploadedFileName : "Thêm tệp mới"}
            </div>
            <div style={{ color: "#667085" }}>
              {uploadedFileName
                ? "Click chọn hoặc kéo thả để thay đổi tệp"
                : "hoặc kéo thả tệp để thêm nhanh"}
            </div>
          </div>
        </Dragger>
      </Row>
    );
  };

  const Instruct = () => {
    return (
      <Row style={{ width: "65%", marginLeft: "-24px" }}>
        <Col>
          <Row>
            <div
              style={{
                width: 32,
                display: "flex",
                justifyContent: "center",
                paddingLeft: 4,
              }}
            >
              <QuestionCircleOutlined />
            </div>
            <div>File mẫu</div>
          </Row>
          <ul style={{ color: "#667085" }}>
            <li>
              Thêm mới tệp Excel (.xlsx) hoặc{" "}
              <a style={{ color: "#1677ff" }} onClick={onDownloadTemplate}>
                Tải xuống tệp mẫu
              </a>{" "}
              để điền dữ liệu
            </li>
            <li>Làm theo hướng dẫn trong tệp mẫu để tránh mất dữ liệu.</li>
            <li>Phương thức tải lên</li>
          </ul>
        </Col>
      </Row>
    );
  };

  const ListQuickReplies = () => {
    return (
      <div style={{ width: "100%", padding: "24px" }}>
        <Row>
          <div
            style={{
              width: 20,
              display: "flex",
              justifyContent: "start",
            }}
          >
            <QuestionCircleOutlined />
          </div>
          <div>Chọn mẫu câu</div>
        </Row>
        <div className="d-flex justify-content-between">
          <span>
            Tuỳ chọn câu trả lời để thêm mới hoặc thay thế vào danh sách mẫu câu
            hiện tại
          </span>
          <div
            style={{
              color: localStorage.getItem("colorTheme") || "#4eac6d",
              textAlign: "center",
              padding: "0px 8px",
              borderRadius: 10,
              display: quickReplyIds?.length > 0 ? "block" : "none",
              backgroundColor: hexToRgba(
                localStorage.getItem("colorTheme") || "#2f54eb",
                0.3,
              ),
            }}
          >
            Đã chọn {quickReplyIds?.length}
          </div>
        </div>
        <div
          className="bg-white"
          style={{
            borderRadius: 8,
            marginTop: 16,
            paddingBottom: 4,
            border: "1px solid #d0d0d0",
            height: 330,
          }}
        >
          <Table
            rowKey="key"
            rowClassName="btn-tag"
            pagination={false}
            components={{ body: { row: RowTable } }}
            columns={columnsQuickReplies}
            dataSource={(listQuickReplies || []).map((tag, index) => ({
              key: tag.id,
              index: index + 1,
              ...tag,
            }))}
          />
        </div>
      </div>
    );
  };

  const UploadMethod = () => {
    return (
      <Row style={{ width: "65%", marginLeft: "-24px", paddingBottom: 8 }}>
        <Col style={{ width: "100%" }}>
          <Row>
            <div style={{ paddingLeft: 12 }}>Phương thức tải lên</div>
          </Row>
          <Row style={{ paddingLeft: 12 }}>
            <Radio
              onChange={onChangeMode}
              value={"UPDATE_DUPLICATE"}
              checked={modeValue == "UPDATE_DUPLICATE"}
              style={{ width: "50%" }}
            >
              <FileAddOutlined style={{ marginRight: 6 }} />
              Thêm vào danh sách
            </Radio>
            <Radio
              onChange={onChangeMode}
              value={"REPLACE_ALL"}
              checked={modeValue === "REPLACE_ALL"}
            >
              <FileSyncOutlined style={{ marginRight: 6 }} />
              Thay thế danh sách đã có
            </Radio>
          </Row>
        </Col>
      </Row>
    );
  };

  const onDownloadTemplate = async () => {
    const data = {
      channelId,
    };
    const res: any = await downloadTemplate(data);
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `Template_trả_lời_nhanh.xlsx`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const onImportQuickReplies = async () => {
    // fliter with quickReplyIds
    let listQuickRepliesUpload = listQuickReplies.filter((item: any) =>
      quickReplyIds.includes(item.id),
    );
    const data = {
      channelId,
      quickReplies:listQuickRepliesUpload,
      mode: modeValue,
    };
    try {
      const response: any = await importQuickReplies(data);
      if (response?.success) {
        toast.success("Tải lên thành công");
        onSuccess();
        onClose();
      } else {
        toast.error(
          response?.data?.message?.error ||
            response?.data?.message ||
            "Có lỗi xảy ra",
        );
      }
    } catch (error) {
      toast.error("Có lỗi xảy ra");
    }
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={onClose} width={860}>
      <div className="">
        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 12 }}>
          Tải lên câu trả lời nhanh
        </div>
        <div style={{ backgroundColor: "#F2F4F7", padding: 16 }}>
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {!isScreenUpload ? (
              <>
                <UploadFile />
                <Instruct />
                <UploadMethod />
              </>
            ) : (
              <div style={{ height: 443, width:"100%" }}>
                <ListQuickReplies />
              </div>
            )}

            <Divider style={{ margin: "12px 0px" }} />
            {!isScreenUpload ? (
              <Button
                type="primary"
                style={{ width: "65%", marginBottom: 12 }}
                disabled={isDisabled}
                onClick={() => {
                  if (!isScreenUpload) {
                    setIsScreenUpload(true);
                  }
                }}
              >
                Tải dữ liệu lên
              </Button>
            ) : (
              <Row>
                <Button
                  style={{ width: "200px", marginBottom: 12, marginRight: 16 }}
                  onClick={() => {
                    setIsScreenUpload(false);
                  }}
                >
                  Quay lại
                </Button>
                <Button
                  type="primary"
                  style={{ width: "200px", marginBottom: 12 }}
                  disabled={quickReplyIds?.length === 0}
                  onClick={onImportQuickReplies}
                >
                  Tiếp tục
                </Button>
              </Row>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default UploadQuickReplies;
