import React, { useState } from "react";
import {
} from "reactstrap";
import { useRedux } from "../../../hooks/index";

//images
import iconTranslate from "../../../assets/images/icon_translate.png";
import { createSelector } from "reselect";

// utils

interface MessageProps {
  message: any;
  isFromMe: boolean;
}
const MessageTranslate = ({
  message,
  isFromMe,
}: MessageProps) => {
  const { useAppSelector } = useRedux();

  const data = createSelector(
        (state : any) => state.Chats,
        (state) => ({
          selectedConversation: state.selectedConversation,
    
        })
      );
  // Inside your component
  const { selectedConversation } = useAppSelector(data);
  const textTranslate = message?.extraInfo?.translation?.translations?.find(
      (item: any) => 
              (item.language == selectedConversation?.extraInfo?.translation?.translateReceivedTo) 
                    && item.message !== "");
  
  return (
    (selectedConversation?.extraInfo?.translation?.translateReceivedTo && textTranslate?.message) && (
        <p className="text-translate mb-0" style={isFromMe ? {color: 'red', textAlign: 'left'} : {color: 'red'}}>
          <span><img style={{width: 17, marginRight: 4}} 
            src={iconTranslate} alt="iconTranslate" 
            className="iconTranslate" />
          </span>
          {textTranslate?.message || ''}
        </p>
    )
  );
};

export default MessageTranslate;
