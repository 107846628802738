import moment from 'moment';

const checkKeyInsert = (value: string, selectedConversation: any, channelSelected: any) => {
    let txt = value;
    if (txt.includes('#{FULL_NAME}')) {
        txt = txt.replaceAll('#{FULL_NAME}', selectedConversation?.from?.name || '');
    }
    if (txt.includes('#{FIRST_NAME}')) {
        txt = txt.replaceAll('#{FIRST_NAME}', selectedConversation?.from?.name?.split(' ')[0] || '');
    }
    if (txt.includes('#{LAST_NAME}')) {
        txt = txt.replaceAll('#{LAST_NAME}', selectedConversation?.from?.name?.split(' ')[1] || '');
    }
    if (txt.includes('#SEX{MALE | FEMALE | UNKNOWN}')) {
        txt = txt.replaceAll('#SEX{MALE | FEMALE | UNKNOWN}', '');
    }
    if (txt.includes('#{PAGE_NAME}')) {
        txt = txt.replaceAll('#{PAGE_NAME}', channelSelected?.name || '');
    }
    if (txt.includes('#{SPIN_1 | SPIN_2}')) {
        txt = txt.replaceAll('#{SPIN_1 | SPIN_2}', '#{SPIN_1 | SPIN_2}'.split(' | ')[Math.floor(Math.random() * 2)]);
    }
    if (txt.includes('#{STAFF_NAME}')) {
        txt = txt.replaceAll('#{STAFF_NAME}', '');
    }
    if (txt.includes('#{STAFF_FIRST_NAME}')) {
        txt = txt.replaceAll('#{STAFF_FIRST_NAME}', '');
    }
    if (txt.includes('#{STAFF_LAST_NAME}')) {
        txt = txt.replaceAll('#{STAFF_LAST_NAME}', '');
    }
    if (txt.includes('#{STAFF_DETAILS}')) {
        txt = txt.replaceAll('#{STAFF_DETAILS}', '');
    }
    if (txt.includes('#{TODAY(DD/MM/YYYY)}')) {
        txt = txt.replaceAll('#{TODAY(DD/MM/YYYY)}', moment().format('DD/MM/YYYY'));
    }
    if (txt.includes('#{TODAY(MM/DD/YYYY)}')) {
        txt = txt.replaceAll('#{TODAY(MM/DD/YYYY)}', moment().format('MM/DD/YYYY'));
    }
    if (txt.includes('#{TODAY(LL)}')) {
        txt = txt.replaceAll('#{TODAY(LL)}', moment().format('DD') + ' tháng ' + moment().format('M') + ' năm ' + moment().format('YYYY'));
    }
    if (txt.includes('#{TOMORROW(DD/MM/YYYY)}')) {
        txt = txt.replaceAll('#{TOMORROW(DD/MM/YYYY)}', moment().add(1, 'days').format('DD/MM/YYYY'));
    }
    if (txt.includes('#{TOMORROW(MM/DD/YYYY)}')) {
        txt = txt.replaceAll('#{TOMORROW(MM/DD/YYYY)}', moment().add(1, 'days').format('MM/DD/YYYY'));
    }
    if (txt.includes('#{TOMORROW(LL)}')) {
        txt = txt.replaceAll('#{TOMORROW(LL)}', moment().add(1, 'days').format('DD') + ' tháng ' + moment().format('M') + ' năm ' + moment().format('YYYY'));
    }
    if (txt.includes('#{YESTERDAY(DD/MM/YYYY)}')) {
        txt = txt.replaceAll('#{YESTERDAY(DD/MM/YYYY)}', moment().subtract(1, 'days').format('DD/MM/YYYY'));
    }
    if (txt.includes('#{YESTERDAY(MM/DD/YYYY)}')) {
        txt = txt.replaceAll('#{YESTERDAY(MM/DD/YYYY)}', moment().subtract(1, 'days').format('MM/DD/YYYY'));
    }
    if (txt.includes('#{YESTERDAY(LL)}')) {
        txt = txt.replaceAll('#{YESTERDAY(LL)}', moment().subtract(1, 'days').format('DD') + ' tháng ' + moment().format('M') + ' năm ' + moment().format('YYYY'));
    }
    return txt;
};


export {
    checkKeyInsert,
};
  