


const checkApplication = () => {
    try {
        const setting: any = localStorage.getItem("setting");
        const settingObject = JSON.parse(setting);
        if(settingObject?.title.includes('HHQ')) { 
            return 1
        } else {
            return 2
        }
    } catch (error) {
        return 0;
    }
 
};
export { checkApplication };
