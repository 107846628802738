import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();


const getHistoryChat = ( params: any) => {
    // eslint-disable-next-line no-useless-concat
    return api.get(url.GET_CONVERSATION_LIST + '/' + `${params?.conversationId}` + '/tag-histories', {
    });
};



export {
    getHistoryChat,
};
