import React, { useState } from "react";
import { format } from 'date-fns';

// hooks
import { useRedux, useProfile } from "../../../hooks/index";
// actions
import {
  onSendMessage,
  onSendMultiMsg,
  toggleUserDetailsTab,
} from "../../../redux/actions";

// components
import UserHead from "./UserHead";
import MessagesHistories from "./MessagesHistories";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";
import { createSelector } from "reselect";
import { notification } from "antd";

interface IndexProps {
}

const Index = (props: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();
  const data = createSelector(
    (state : any) => state.Chats,
    (state) => ({
      selectedChat: state.selectedChat,
      selectedConversation: state.selectedConversation,
      replyMsgSelected: state.replyMsgSelected,
      extensionReady: state.extensionReady,
    })
  );
  // Inside your component
  const { selectedChat, selectedConversation, replyMsgSelected, extensionReady } = useAppSelector(data);

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };


  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: any, fileList: any, type: string) => {
    if (!data?.text && fileList.length === 0) {
      notification.error({
        message: 'Không thể trả lời vào lúc này',
        description: "Vui lòng nhập nội dung trả lời hoặc hình ảnh, file đính kèm",
      });
      return;
    }
    if (
      extensionReady &&
      fileList?.length > 1
    ) {
      const formattedDate = new Date();
      let params: any = {
        attachments: fileList?.map((file: any) => ({
          name:file.originalname,
          type: file.mimetype,
          url: file.location
        })),
        id: selectedChat,
        conversation: selectedConversation,
        conversationId: "",
        createdAt: formattedDate,
        createdBy: {
          name: userProfile?.name,
        },
        extraInfo: {
        },
        from: {
          id: selectedConversation?.channelId || "",
        },
        channelId: selectedConversation?.channelId || "",
        messageId: '',
        refParentId: replyMsgSelected || '',
        text: data.text && data.text,
        to: {},
        type: type,
      };
      dispatch(onSendMultiMsg(params));
    } else if (fileList?.length > 0) {
      for (var i = 0; i < (!data?.text ? fileList.length : fileList.length + 1); i++) {
        const formattedDate = new Date();
        let params: any = {
          attachments: !data?.text ?
            [
              {
                name: fileList[i].originalname,
                type: fileList[i].mimetype,
                url: fileList[i].location
              }
            ] : (
              i == 0 ?
                [] :
                [
                  {
                    name: fileList[i - 1].originalname,
                    type: fileList[i - 1].mimetype,
                    url: fileList[i - 1].location
                  }
                ]
            ),
          id: selectedChat,
          conversation: selectedConversation,
          conversationId: "",
          createdAt: formattedDate,
          createdBy: {
            name: userProfile?.name,
          },
          extraInfo: {
          },
          from: {
            id: selectedConversation?.channelId || "",
          },
          channelId: selectedConversation?.channelId || "",
          messageId: '',
          refParentId: replyMsgSelected || '',
          text: !data?.text ? '' : (i == 0 ? data.text : ''),
          to: {},
          type: type,
          contentId: !data?.text ? fileList[i].id : (i == 0 ? '' : fileList[i - 1]?.id)
        };
        dispatch(onSendMessage(params));
      }
    } else {
      // Giả lập message thay mỗi nội dung để add vào array history
      const formattedDate = new Date();
      let params: any = {
        attachments: [],
        id: selectedChat,
        conversation: selectedConversation,
        conversationId: "",
        createdAt: formattedDate,
        createdBy: {
          name: userProfile?.name,
        },
        extraInfo: {
          translation: {
            translations: [
              {language: selectedConversation.extraInfo?.translation?.translateSentTo, message: data.textOriginal}
            ]
          }
        },
        from: {
          id: selectedConversation?.channelId || "",
        },
        channelId: selectedConversation?.channelId || "",
        messageId: '',
        refParentId: replyMsgSelected || '',
        text: data.text && data.text,
        to: {},
        type: type
      };
      dispatch(onSendMessage(params));
    }
  };



  return (
    <div style={{height: 'calc(100vh - 56px)', display: 'flex', flexDirection: 'column'}}>
      <UserHead
        onOpenUserDetails={onOpenUserDetails}
      />
      <MessagesHistories />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
      />
    </div>
  );
};

export default Index;
