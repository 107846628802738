import React, { useState, useCallback, useEffect } from "react";
import {
    Row,
    Col,
    Modal,
    Input,
    Layout,
    Upload,
    UploadProps,
    Tooltip,
    message,
    Dropdown,
    theme,
    Spin
} from 'antd';
import { toast } from "react-toastify";
import _ from "lodash";

//api
import {
    getMedias,
    uploadMedia,
    updateMedia,
    deleteMedia,
    getFolders,
    createFolder,
    updateFolder,
    deleteFolder,
} from "../../api/index";

// component
import LightBox from "../../components/LightBox";
import InputImage from "./InputImage";
import InputFolder from "./InputFolder";
import {
    SearchOutlined,
    CloseOutlined,
    ClockCircleTwoTone,
    DeleteOutlined,
    HeartFilled
} from '@ant-design/icons';
import EmptyImg from "../../assets/images/empty_photos.png";
import IconLike from "../../assets/images/like.svg";
import IconLiked from "../../assets/images/liked.svg";
import IconView from "../../assets/images/view.svg";
import IconDelete from "../../assets/images/delete.svg";
import IconDeleteAll from "../../assets/images/ic_delete.svg";
import IconTip from "../../assets/images/ic_tip.svg";
import IconEnter from "../../assets/images/ic_enter.svg";
import IconFolder from "../../assets/images/ic_folder.svg";
import IconOption from "../../assets/images/option.svg";
import IconEdit from "../../assets/images/edit.svg";

// styles
import styled from "styled-components";

const { useToken } = theme;
const { Sider } = Layout;

const dataTip = [
    {
        name: 'Chọn tất cả',
        character1: '⌘',
        character2: 'A',
    },
    {
        name: 'Gửi ảnh ngay',
        character1: '⌥',
        character2:
            <img
                src={IconEnter}
                alt=""
                style={{
                    width: 16,
                    height: 16,
                }}
            />,
    },
    {
        name: 'Kéo thả để sắp xếp thư mục vào vị trí thích hợp',
    },
    {
        name: 'Kéo thả ảnh mới vào thư mục để thêm ảnh',
    }
];

interface ModalAddImageProps {
    className?: string;
    isOpen: boolean;
    onlyOne?: boolean;
    channelId: string;
    fileLists:any;
    idItem:any;
    onClose: () => void;
    onSetFileList: (v: any) => void;
    onReplaceFileList: (v: any) => void;
}

const Index = ({
    isOpen,
    onlyOne,
    channelId,
    onClose,
    onSetFileList,
    onReplaceFileList,
    fileLists,
    idItem,
    className
}: ModalAddImageProps) => {
    const { confirm } = Modal;
    const [imgList, setImgList] = useState<any>(null);
    const [imgSelected, setImgSelected] = useState<any>(fileLists);
    const [valueSearch, setValueSearch] = useState<string>('');
    const [valueFolder, setValueFolder] = useState<string>('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [randomNumber, setRandomNumber] = useState(Math.floor(Math.random() * 4));
    const [folderList, setFolderList] = useState<any>(null);
    const [folderIdSelected, setFolderIdSelected] = useState<string>('0');
    const [idEdit, setIdEdit] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { token } = useToken();

    useEffect(() => {
        if(idItem){
            setImgSelected(fileLists)
        }
        else {
            setImgSelected([])
        }
    }, [idItem]);

    const contentStyle: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    useEffect(() => {
        // fetch data
        if (folderList === null && channelId) {
            getFolderListData({ page: 1, limit: 50, valueFolder: '' });
        }
    }, [channelId]);

    const getFolderListData = async (data: any) => {
        try {
            const response: any = await getFolders({ channelId: channelId, page: data?.page, limit: data?.limit, q: data?.valueFolder });
            if (response?.data?.items) {
                const arr = imgList?.items && data.page !== 1 ? [...imgList?.items, ...response.data.items] : response.data.items;
                setFolderList({
                    total: response.data.total,
                    page: data.page,
                    items: arr?.sort((a: any, b: any) => a.stt - b.stt),
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const onSearchFolder = _.debounce((value: string) => {
        setValueFolder(value);
        getFolderListData({ page: 1, limit: 50, valueFolder: value });
    }, 300)

    useEffect(() => {
        // fetch data
        if (channelId && folderIdSelected) {
            getImgListData({ page: 1, limit: 50, valueSearch: '' });
        }
    }, [channelId, folderIdSelected]);

    const handleScrollToBottom = useCallback(() => {
        const element = document.getElementById("img-list");
        if (element) {
            const { scrollTop, clientHeight, scrollHeight } = element;
            if ((scrollTop + clientHeight + 1 >= scrollHeight) && imgList?.total > imgList?.items?.length) {
                getImgListData({
                    page: imgList?.page + 1,
                    limit: 50,
                    valueSearch: valueSearch
                });
            }
        }
    }, [imgList]);

    useEffect(() => {
        const element = document.getElementById("img-list");
        if (element) {
            element.addEventListener("scroll", handleScrollToBottom);
        }
        return () => {
            if (element) {
                element.removeEventListener("scroll", handleScrollToBottom);
            }
        };
    }, [handleScrollToBottom]);

    const getImgListData = async (data: any) => {
        try {
            const response: any = await getMedias({ channelId: channelId, folderIdSelected: folderIdSelected, page: data?.page, limit: data?.limit, q: data?.valueSearch });
            if (response?.data?.items) {
                const arr = imgList?.items && data.page !== 1 ? [...imgList?.items, ...response.data.items] : response.data.items;
                setImgList({
                    total: response.data.total,
                    page: data.page,
                    items: arr,
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const onUpdateImg = async (name: string, item: any) => {
        try {
            const response: any = await updateMedia(
                item?.id,
                {
                    ...item,
                    originalname: name,
                }
            );
            if (response?.data && response?.success) {
                const arr = [...imgList?.items];
                arr?.splice(arr?.findIndex((img: any) => img?.id === item?.id), 1, {
                    ...item,
                    originalname: name,
                });
                setImgList({
                    total: imgList?.total,
                    page: imgList?.page,
                    items: arr,
                });
                if (imgSelected?.find((img: any) => img?.id === item?.id)) {
                    const _arr = [...imgSelected];
                    _arr?.splice(_arr?.findIndex((img: any) => img?.id === item?.id), 1, {
                        ...item,
                        originalname: name,
                    });
                    setImgSelected(_arr);
                    onReplaceFileList(_arr);
                }
                toast.success('Chỉnh sửa tên ảnh thành công');
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const changeLikeImg = async (value: boolean, item: any) => {
        try {
            const response: any = await updateMedia(
                item?.id,
                {
                    ...item,
                    frequentlyUsed: value,
                }
            );
            if (response?.data && response?.success) {
                if (folderIdSelected === '1' && !value) {
                    setImgList({
                        total: imgList?.total - 1,
                        page: imgList?.page,
                        items: imgList?.items?.filter((img: any) => img?.id !== item?.id),
                    });
                    setImgSelected(imgSelected?.filter((img: any) => img?.id !== item?.id));
                    onReplaceFileList(imgSelected?.filter((img: any) => img?.id !== item?.id));
                } else {
                    const arr = [...imgList?.items];
                    arr?.splice(arr?.findIndex((img: any) => img?.id === item?.id), 1, {
                        ...item,
                        frequentlyUsed: value,
                    });
                    setImgList({
                        total: imgList?.total,
                        page: imgList?.page,
                        items: arr,
                    });
                    if (imgSelected?.find((img: any) => img?.id === item?.id)) {
                        const _arr = [...imgSelected];
                        _arr?.splice(_arr?.findIndex((img: any) => img?.id === item?.id), 1, {
                            ...item,
                            frequentlyUsed: value,
                        });
                        setImgSelected(_arr);
                        onReplaceFileList(_arr);
                    }
                }
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const onSearch = _.debounce((value: string) => {
        setValueSearch(value);
        getImgListData({ page: 1, limit: 50, valueSearch: value });
      }, 300);

    const onChangeList = (item: any) => {
        if (onlyOne) {
            setImgSelected([item]);
            onReplaceFileList([item]);
        } else {
            if (imgSelected?.find((img: any) => img?.id === item?.id)) {
                setImgSelected(imgSelected.filter((img: any) => img?.id !== item?.id));
                onReplaceFileList(imgSelected.filter((img: any) => img?.id !== item?.id));
            } else {
                setImgSelected([...imgSelected, item]);
                onReplaceFileList([...imgSelected, item]);
            }
        }
    }

    const confirmDelete = (id: string) => {
        confirm({
            title: 'Bạn có chắc chắn muốn xóa ảnh không?',
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            content: '',
            centered: true,
            okText: 'Xóa ảnh',
            cancelText: 'Đóng',
            async onOk() {
                try {
                    const response: any = await deleteMedia(id);
                    if (response?.data && response?.success) {
                        setImgList({
                            total: imgList?.total - 1,
                            page: imgList?.page,
                            items: imgList?.items?.filter((img: any) => img?.id !== id),
                        });
                        setImgSelected(imgSelected?.filter((img: any) => img?.id !== id));
                        onReplaceFileList(imgSelected?.filter((img: any) => img?.id !== id));
                        message.success('Xóa ảnh thành công');
                    } else {
                        toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
                    }
                } catch (error) {
                    console.log("error", error);
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const confirmDeleteAll = () => {
        confirm({
            title: 'Bạn có chắc chắn muốn xóa ảnh không?',
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            content: '',
            centered: true,
            okText: 'Xóa ảnh',
            cancelText: 'Đóng',
            async onOk() {
                setImgList({
                    total: imgList?.total,
                    page: imgList?.page,
                    items: imgList?.items?.filter((img: any) => !imgSelected?.find((item: any) => item?.id === img?.id)),
                });
                setImgSelected([]);
                onReplaceFileList([]);
                for (var i = 0; i < imgSelected?.length; i++) {
                    const response: any = await deleteMedia(imgSelected[i]?.id);
                    if (response?.data && response?.success && i === imgSelected?.length - 1) {
                        message.success('Xóa ảnh thành công');
                    }
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const onCreateFolder = async (name: string) => {
        try {
            const response: any = await createFolder(
                {
                    name: name,
                    channelId: channelId,
                    stt: folderList?.items?.length > 0 ? folderList?.items[folderList?.items?.length - 1]?.stt + 1 : 0
                }
            );
            if (response?.data && response?.success) {
                setFolderList((list: any) => {
                    return {
                        ...folderList,
                        items: list?.items?.concat([response?.data])?.sort((a: any, b: any) => a.stt - b.stt)
                    };
                });
                toast.success('Tạo thư mục mới thành công');
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const onUpdateFolder = async (name: string, item: any) => {
        try {
            const response: any = await updateFolder(
                item?.id,
                {
                    ...item,
                    folderId: item?.id,
                    name: name,
                }
            );
            if (response?.data && response?.success) {
                const arr = [...folderList?.items];
                arr?.splice(arr?.findIndex((folder: any) => folder?.id === item?.id), 1, {
                    ...item,
                    name: name,
                });
                setFolderList({
                    total: folderList?.total,
                    page: folderList?.page,
                    items: arr?.sort((a: any, b: any) => a.stt - b.stt),
                });
                toast.success('Sửa tên thành công');
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const confirmDeleteFolder = (id: string) => {
        confirm({
            title: 'Bạn có muốn xóa thư mục này không?',
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            content: '',
            centered: true,
            okText: 'Xóa thư mục',
            cancelText: 'Đóng',
            async onOk() {
                try {
                    const response: any = await deleteFolder(id);
                    if (response?.data && response?.success) {
                        setFolderList({
                            total: folderList?.total - 1,
                            page: folderList?.page,
                            items: folderList?.items?.filter((img: any) => img?.id !== id)?.sort((a: any, b: any) => a.stt - b.stt),
                        });
                        message.success('Xóa thư mục thành công');
                    } else {
                        toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
                    }
                } catch (error) {
                    console.log("error", error);
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const propsImg: UploadProps = {
        multiple: !onlyOne,
        accept: "image/png, image/jpeg",
        fileList: [],
        customRequest: () => {},
        onChange: async (info: any) => {
            const { file, fileList } = info;
            if (file?.uid === fileList[fileList?.length - 1]?.uid) {
                setIsLoading(true);
                const formData = new FormData();
                fileList.map((item: any) => {
                    formData.append('files', item.originFileObj);
                })
                formData.append('channelId', channelId);
                if (folderIdSelected !== '0' && folderIdSelected === '1') {
                    formData.append('folderId', folderIdSelected);
                }
                const response: any = await uploadMedia(formData);
                if (response?.data && response?.success) {
                    setImgList((list: any) => {
                        return {
                            ...imgList,
                            items: response?.data?.concat(list?.items)
                        };
                    });
                    setImgSelected((currentImgList: any) => {
                        return response?.data?.concat(currentImgList);
                    });
                    onSetFileList(response?.data)
                } else {
                    toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
                }
                setIsLoading(false);
            }
        }
    };

    return (
        <Modal
            className={className}
            open={isOpen}
            onCancel={onClose}
            onOk={() => { }}
            closeIcon={null}
            maskClosable={false}
            footer={null}
            width={1010}
            centered
            styles={{
                content: {
                    backgroundColor: '#F2F4F7',
                    padding: 0
                }
            }}
        >
            <Spin spinning={isLoading}>
                <Row style={{ alignItems: 'center', padding: '16px 24px' }}>
                    <Col span={8}>
                        <div style={{
                            fontSize: 18,
                            fontWeight: '500'
                        }}>Thư mục ảnh</div>
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Tìm kiếm ảnh bằng tên"
                            prefix={<SearchOutlined style={{ fontSize: 16 }} />}
                            onChange={(e) => onSearch(e.target.value)}
                        />
                    </Col>
                    <Col span={8} style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <CloseOutlined style={{ fontSize: 20, cursor: 'pointer' }} onClick={onClose} />
                    </Col>
                </Row>
                <Layout style={{ height: 'calc(100vh - 200px)', borderRadius: 12 }}>
                    <Sider width={260} style={{ backgroundColor: 'white', borderRadius: '0 12px 0 12px', position: 'relative' }}>
                        <div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '12px 0 12px 0',
                                overflow: 'auto',
                                height: 'calc(100% - 80px)',
                                padding: '12px 16px',
                            }}
                        >
                            <Input
                                placeholder="Tìm kiếm thư mục bằng tên"
                                prefix={<SearchOutlined style={{ fontSize: 16 }} />}
                                onChange={(e) => onSearchFolder(e.target.value)}
                            />
                            <div style={{ margin: '16px 0 16px 0' }}>
                                <div
                                    className="folder-item"
                                    style={{
                                        backgroundColor: folderIdSelected === '0' ? '#E2F2FF' : '',
                                    }}
                                    onClick={() => setFolderIdSelected('0')}
                                >
                                    <ClockCircleTwoTone
                                        style={{
                                            fontSize: 20,
                                            marginRight: 8
                                        }}
                                    />
                                    <div>Gần đây</div>
                                </div>
                                <div
                                    className="folder-item"
                                    style={{
                                        backgroundColor: folderIdSelected === '1' ? '#E2F2FF' : '',
                                    }}
                                    onClick={() => setFolderIdSelected('1')}
                                >
                                    <HeartFilled
                                        style={{
                                            fontSize: 20,
                                            marginRight: 8,
                                            color: 'rgb(247, 89, 171)'
                                        }}
                                    />
                                    <div>Thường dùng</div>
                                </div>
                                {
                                    folderList?.items?.length > 0 ? (
                                        folderList?.items?.map((folder: any, index: number) => (
                                            <>
                                                {
                                                    idEdit === folder?.id ? (
                                                        <div style={{
                                                            marginBottom: 8
                                                        }}>
                                                            <InputFolder
                                                                name={folder?.name}
                                                                onSetName={(value: string) => {
                                                                    onUpdateFolder(value, folder);
                                                                }}
                                                                onClose={() => setIdEdit(null)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            key={index}
                                                            className="folder-item"
                                                            style={{
                                                                backgroundColor: folderIdSelected === folder?.id ? '#E2F2FF' : '',
                                                                justifyContent: 'space-between'
                                                            }}
                                                            onClick={() => setFolderIdSelected(folder?.id)}
                                                        >
                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                <img
                                                                    src={IconFolder}
                                                                    alt=""
                                                                    style={{
                                                                        width: 20,
                                                                        height: 20,
                                                                        marginRight: 8,
                                                                    }}
                                                                />
                                                                <div>{folder?.name}</div>
                                                            </div>
                                                            <Dropdown
                                                                className={className}
                                                                trigger={['click']}
                                                                placement="bottomLeft"
                                                                dropdownRender={() => (
                                                                    <div className={className} style={{ ...contentStyle, padding: 4 }}>
                                                                        <div
                                                                            className="item-action"
                                                                            style={{
                                                                                marginBottom: 4
                                                                            }}
                                                                            onClick={() => setIdEdit(folder?.id)}
                                                                        >
                                                                            <img
                                                                                src={IconEdit}
                                                                                alt=""
                                                                                style={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    marginRight: 8,
                                                                                }}
                                                                            />
                                                                            <div>Sửa tên</div>
                                                                        </div>
                                                                        <div
                                                                            className="item-action"
                                                                            onClick={() => confirmDeleteFolder(folder?.id)}
                                                                        >
                                                                            <img
                                                                                src={IconDeleteAll}
                                                                                alt=""
                                                                                style={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    marginRight: 8,
                                                                                }}
                                                                            />
                                                                            <div style={{color: 'red'}}>Xóa thư mục</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            >
                                                                <div className="action-folder">
                                                                    <img
                                                                        className="img-action"
                                                                        src={IconOption}
                                                                        alt=""
                                                                        style={{
                                                                            width: 18,
                                                                            height: 18,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Dropdown>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        ))
                                    ) : null
                                }
                            </div>
                            {
                                idEdit === '0' ? (
                                    <InputFolder
                                        name=''
                                        onSetName={(value: string) => {
                                            onCreateFolder(value);
                                        }}
                                        placeholder='Nhập tên thư mục mới'
                                        onClose={() => setIdEdit(null)}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            height: 38,
                                            padding: '8px 12px',
                                            width: '100%',
                                            backgroundColor: '#D2EBFF',
                                            color: '#105CE2',
                                            fontWeight: '500',
                                            textAlign: 'center',
                                            borderRadius: 6,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setIdEdit('0')}
                                    >
                                        Tạo mới thư mục
                                    </div>
                                )
                            }
                        </div>
                        {/* <Dropdown
                            className={className}
                            trigger={['click']}
                            open={isOpenDropdown}
                            placement="bottom"
                            onOpenChange={(open) => setIsOpenDropdown(open)}
                            dropdownRender={() => (
                                <div
                                    className={className} style={{ ...contentStyle, padding: '6px 0', width: 288 }}>
                                    {
                                        dataTip.map((tip) => (
                                            <div className="tip-item">
                                                <div>
                                                    {tip.name}
                                                </div>
                                                {
                                                    tip?.character1 && tip?.character2 ? (
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            <span className="keyboard" style={{ marginRight: 4 }}>{tip?.character1}</span>
                                                            <span className="keyboard">{tip?.character2}</span>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            )}
                        >
                            <div className="tip-info">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <img
                                        src={IconTip}
                                        alt=""
                                        style={{
                                            width: 18,
                                            height: 18,
                                            marginRight: 8,
                                        }}
                                    />
                                    {dataTip[randomNumber].name}
                                </div>
                                {
                                    dataTip[randomNumber]?.character1 && dataTip[randomNumber]?.character2 ? (
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <span className="keyboard" style={{ marginRight: 4 }}>{dataTip[randomNumber]?.character1}</span>
                                            <span className="keyboard">{dataTip[randomNumber]?.character2}</span>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </Dropdown> */}
                    </Sider>
                    <Layout style={{ borderRadius: 12 }}>
                        <div style={{
                            backgroundColor: 'white',
                            marginLeft: 10,
                            borderRadius: '12px 0 12px 0',
                            height: '100%',
                        }}>
                            <div
                                id="img-list"
                                style={{
                                    backgroundColor: 'white',
                                    borderRadius: '12px 0 12px 0',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    overflow: 'auto',
                                    height: 'calc(100% - 66px)',
                                }}
                            >
                                {
                                    imgList?.items.length > 0 ? (
                                        imgList?.items?.map((item: any, index: number) => (
                                            <div
                                                key={index}
                                                style={{
                                                    margin: '16px 0 0 22px'
                                                }}
                                            >
                                                <div
                                                    className="image-item"
                                                    style={{
                                                        border: imgSelected?.find((img: any) => img?.id === item?.id) ? '1px solid #1890ff' : '1px solid #EAECF0',
                                                    }}
                                                >
                                                    <img
                                                        src={item?.location}
                                                        alt=""
                                                        style={{
                                                            width: 120,
                                                            height: 120,
                                                            borderRadius: 12,
                                                            objectFit: 'contain'
                                                        }}
                                                        onClick={() => onChangeList(item)}
                                                    />
                                                    {
                                                        imgSelected?.find((img: any) => img?.id === item?.id) ? (
                                                            <div style={{
                                                                position: 'absolute',
                                                                top: 8,
                                                                left: 8,
                                                                width: 24,
                                                                height: 24,
                                                                borderRadius: '50%',
                                                                border: '1px solid #fff',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                backgroundColor: '#1677FF',
                                                                fontSize: 12,
                                                                color: 'white'
                                                            }}>
                                                                {imgSelected?.findIndex((img: any) => img?.id === item?.id) + 1}
                                                            </div>
                                                        ) : null
                                                    }
                                                    {
                                                        item?.frequentlyUsed ? (
                                                            <div className="image-action-checked">
                                                                <Tooltip title="Bỏ khỏi danh sách yêu thích">
                                                                    <div
                                                                        className="image-action-content"
                                                                        onClick={() => {
                                                                            changeLikeImg(false, item);
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={IconLiked}
                                                                            alt=""
                                                                            style={{ width: 20, height: 20 }}
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                                <div
                                                                    className="image-action-content-checked"
                                                                    onClick={() => {
                                                                        setPreviewImage(item?.location);
                                                                        setPreviewOpen(true);
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={IconView}
                                                                        alt=""
                                                                        style={{ width: 20, height: 20 }}
                                                                    />
                                                                </div>
                                                                <Tooltip title="Xóa ảnh">
                                                                    <div
                                                                        className="image-action-content-checked"
                                                                        onClick={() => confirmDelete(item?.id)}
                                                                    >
                                                                        <img
                                                                            src={IconDelete}
                                                                            alt=""
                                                                            style={{ width: 20, height: 20 }}
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        ) : (
                                                            <div className="image-action">
                                                                <Tooltip title="Thêm vào danh sách yêu thích">
                                                                    <div
                                                                        className="image-action-content"
                                                                        onClick={() => {
                                                                            changeLikeImg(true, item);
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={IconLike}
                                                                            alt=""
                                                                            style={{ width: 20, height: 20 }}
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                                <div
                                                                    className="image-action-content"
                                                                    onClick={() => {
                                                                        setPreviewImage(item?.location);
                                                                        setPreviewOpen(true);
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={IconView}
                                                                        alt=""
                                                                        style={{ width: 20, height: 20 }}
                                                                    />
                                                                </div>
                                                                <Tooltip title="Xóa ảnh">
                                                                    <div
                                                                        className="image-action-content"
                                                                        onClick={() => confirmDelete(item?.id)}
                                                                    >
                                                                        <img
                                                                            src={IconDelete}
                                                                            alt=""
                                                                            style={{ width: 20, height: 20 }}
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <InputImage
                                                    name={item?.originalname}
                                                    onSetName={(value) => onUpdateImg(value, item)}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <div style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <img
                                                src={EmptyImg}
                                                alt=""
                                                style={{ width: 160, height: 160 }}
                                            />
                                            <div style={{
                                                padding: '16px 0',
                                                color: '#667085',
                                                fontSize: 16
                                            }}>
                                                Page chưa có ảnh được tải lên gần đây
                                            </div>
                                            <Upload {...propsImg}>
                                                <div style={{
                                                    padding: '8px 16px',
                                                    fontSize: 16,
                                                    background: '#D2EBFF',
                                                    color: '#105CE2',
                                                    fontWeight: '500',
                                                    borderRadius: 8,
                                                    cursor: 'pointer'
                                                }}>
                                                    Thêm ảnh
                                                </div>
                                            </Upload>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                imgList?.items.length > 0 ? (
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: imgSelected?.length > 0 ? 'space-between' : 'flex-end',
                                        alignItems: 'center',
                                        padding: '12px 16px',
                                    }}>
                                        {
                                            imgSelected?.length > 0 ? (
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <Tooltip title="Xóa ảnh">
                                                        <div
                                                            className="btn-delete"
                                                            onClick={confirmDeleteAll}
                                                        >
                                                            <img
                                                                src={IconDeleteAll}
                                                                alt=""
                                                                style={{ width: 20, height: 20 }}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                    <div
                                                        className="info-delete"
                                                    >
                                                        <span style={{
                                                            marginRight: 4
                                                        }}>Đã chọn {imgSelected?.length} ảnh</span>
                                                        <Tooltip title="Bỏ chọn tất cả">
                                                            <div
                                                                className="icon-delete"
                                                                onClick={() => {
                                                                    setImgSelected([]);
                                                                    onReplaceFileList([]);
                                                                }}
                                                            >
                                                                <CloseOutlined
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: '#667085'
                                                                    }}
                                                                />
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <Upload {...propsImg}>
                                                <div
                                                    style={{
                                                        padding: '8px 16px',
                                                        backgroundColor: '#D2EBFF',
                                                        color: '#105CE2',
                                                        fontWeight: '500',
                                                        borderRadius: 8,
                                                        fontSize: 16,
                                                        cursor: 'pointer',
                                                        marginRight: 8,
                                                    }}
                                                >
                                                    Thêm ảnh
                                                </div>
                                            </Upload>
                                            <div
                                                style={{
                                                    padding: '8px 16px',
                                                    backgroundColor: imgSelected?.length > 0 ? '#105CE2' : '#8496B4',
                                                    color: 'white',
                                                    fontWeight: '500',
                                                    borderRadius: 8,
                                                    fontSize: 16,
                                                    cursor: imgSelected?.length > 0 ? 'pointer' : 'not-allowed'
                                                }}
                                                onClick={onClose}
                                            >
                                                Chọn ảnh
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    </Layout>
                </Layout>
                {previewOpen && (
                    <LightBox
                        isOpen={previewOpen}
                        images={[{ url: previewImage }]}
                        onClose={() => {
                            setPreviewOpen(false);
                            setPreviewImage('');
                        }}
                        defaultIdx={0}
                    />
                )}
            </Spin>
        </Modal>
    );
};

export default styled(Index)`
    .image-item {
        border-radius: 12px;
        margin-bottom: 8px;
        display: inline-block;
        position: relative;
        cursor: pointer;
        &:hover {
            .image-action {
                display: flex;
            }
            .image-action-checked {
                justify-content: space-between;
            }
            .image-action-content-checked {
                display: flex;
            }
        }
    }
    .image-action {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(16, 24, 40, 0) 0%, rgba(16, 24, 40, 0.65) 85%);
        padding: 6px;
        display: none;
        border-radius: 0 0 12px 12px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .image-action-checked {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(16, 24, 40, 0) 0%, rgba(16, 24, 40, 0.65) 85%);
        padding: 6px;
        display: flex;
        border-radius: 0 0 12px 12px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: flex-start;
    }
    .image-action-content-checked {
        display: none;
        width: 28px;
        height: 28px;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        &:hover {
            background-color: #1d293940;
            cursor: pointer;
        }
    }
    .image-action-content {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        &:hover {
            background-color: #1d293940;
            cursor: pointer;
        }
    }
    .btn-delete {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;
        margin-right: 6px;
        &:hover {
            background-color: #FEE4E2;
        }
    }
    .info-delete {
        padding: 4px 8px 4px 10px;
        display: flex;
        align-items: center;
        border-radius: 16px;
        color: #667085;
        background: #F2F4F7;
    }
    .icon-delete {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        cursor: pointer;
        &:hover {
            background-color: #DDE1E7;
        }
    }
    .tip-info {
        cursor: pointer;
        background-color: #EAECF0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        padding: 16px 20px;
        border-radius: 0 0 0 12px;
        color: #475467;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        &:hover {
            background-color: #DDE1E7;
        }
    }
    .tip-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        padding: 9px 12px;
        color: #667085;
        width: 100%;
    }
    .keyboard {
        padding: 0px 8px;
        height: 22px;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        gap: 4px;
        border-radius: 6px;
        color: #667085;
        border: 1px solid #DDE1E7;
        background: #F9FAFB;
        box-shadow: 0px 1.5px 0px 0px #D0D5DD;
    }
    .folder-item {
        display: flex;
        height: 38px;
        align-items: center;
        padding: 10px;
        border-radius: 8px;
        margin-bottom: 8px;
        &:hover {
            cursor: pointer;
            background-color: #F2F4F7;
            .img-action {
                display: flex;
            }
        }
    }
    .action-folder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        border-radius: 6px;
        &:hover {
            background-color: #1d29391f;
        }
    }
    .img-action {
        display: none;
    }
    .item-action {
        padding: 5px 8px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;
        &:hover {
            background-color: #F2F4F7;
        }
    }
`
