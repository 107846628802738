// import React, { useState, useEffect } from "react";
// import { createSelector } from "reselect";
// // hooks
// import { useRedux } from "../../../hooks/index";

// import { Tabs } from 'antd';
// import CreateOrder from "./CreateOder";
// import InfoOrder from "./InfoOrder";
// import styled from "styled-components";
// import ConversationConfig from "./ConversationConfig";

// interface IndexProps {
//   className?: string;
// }
// const Index =  (props: IndexProps) => {
//   const [refreshTime, setRefreshTime] = useState(Math.random());
//   const [activeKey, setActiveKey] = useState('1')

//   const onCreateSuccess = () => {
//     setRefreshTime(Math.random())
//     setActiveKey("3");
//   }

//   return (
//     <div className={props.className}>
//       <div className="user-profile-sidebar">
//          <div className="tabs-conversation">
//           <Tabs
//               activeKey={activeKey}
//               onChange={(key) => setActiveKey(key)}
//               tabBarStyle={{
//                 display: 'flex',
//                 justifyContent: 'space-between'
//               }}
//               items={[
//                 {
//                   key: '1',
//                   label: `Danh sách đơn hàng`,
//                   children: <InfoOrder refreshTime={refreshTime}/>,
//                 },
//                 {
//                   key: '2',
//                   label: `Tạo đơn`,
//                   children: <CreateOrder onCreateSuccess={onCreateSuccess}/>,
//                 },
//                 {
//                   key: '3',
//                   label: `Cấu hình`,
//                   children: <ConversationConfig />,
//                 }
//               ]}
//             />
//          </div>
//       </div>
//     </div>
//   );
// };


// export default styled(Index)`
//   .ant-tabs-nav-list {
//     width: 100%;
//     justify-content: space-around;
//   }
//   .ant-tabs-content-holder {
//     overflow:auto;  
//     height: calc(100vh - 66px); /* Replace 66px with the needed height. 100vh means the whole height of the browser's viewport */
//     height: -webkit-calc(100vh - 66px);
//     height: -moz-calc(100vh - 66px);
//   }
//   .ant-tabs-content {
//     height: 100%;
//   }

// `


import { Tabs } from "antd";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import styled from "styled-components";
import InfoOrder from "./InfoOrder";
import CreateOrder from "./CreateOrder";
import ConversationConfig from "./ConversationConfig";
const { TabPane } = Tabs;
interface IndexProps {
  className?: string;
}
const SwipeableTabs = (props: IndexProps) => {
  const [index, setIndex] = useState(0);
  const [refreshTime, setRefreshTime] = useState(Math.random());
  const handleTabChange = (key: string) => {
    setIndex(parseInt(key, 10));
  };

  const handleSwipeChange = (index: number) => {
    setIndex(index);
  };


  const onCreateSuccess = () => {
    setRefreshTime(Math.random())
    setIndex(3);
  }

  return (
    <div className={props.className}>
      <div className="user-profile-sidebar">
        <div className="tabs-conversation">
          <Tabs activeKey={index.toString()} onChange={handleTabChange}>
            <TabPane tab="Thông tin" key="0" />
            <TabPane tab="Tạo đơn" key="1" />
            <TabPane tab="Cấu hình" key="2" />
          </Tabs>
          <SwipeableViews index={index} onChangeIndex={handleSwipeChange}>
            <div><InfoOrder refreshTime={refreshTime} /></div>
            <div><CreateOrder onCreateSuccess={onCreateSuccess} /></div>
            <div><ConversationConfig /></div>
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
};

export default styled(SwipeableTabs)`
  .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-around;
  }
  .ant-tabs-content-holder {
    overflow:auto;  
  }
  .ant-tabs-content {
    height: 100%;
  }
  .react-swipeable-view-container {
    height: calc(100vh - 66px);
    height: -webkit-calc(100vh - 66px);
    height: -moz-calc(100vh - 66px);
  }
  .ant-tabs .ant-tabs-tab  {
    width: 33%;
    text-align: center;
    font-size:15px;
    margin: 0px !important;
    justify-content: center;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 400;
    color: #2777d5;
    // border-bottom: 3px solid #2777d5;
  }
`
