const hourInDay = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
  
const dayInWeek = [6, 5, 4, 3, 2, 1, 0];

const filterInfo = [
    {
        key: '1',
        name: 'Hôm nay',
        type: 'hour'
    },
    {
        key: '2',
        name: 'Hôm qua',
        type: 'hour'
    },
    {
        key: '3',
        name: '7 ngày',
        type: 'day'
    },
    {
        key: '4',
        name: '30 ngày',
        type: 'day'
    },
    {
        key: '5',
        name: '3 tháng',
        type: 'month'
    },
    {
        key: '6',
        name: '6 tháng',
        type: 'month'
    },
];

const getOneMonth = () => {
    const arr = [];
    for (let i = 30; i >= 0; i--) {
        arr.push(i);
    }
    return arr;
};

const getThreeMonth = () => {
    const arr = [];
    for (let i = 90; i >= 0; i--) {
        arr.push(i);
    }
    return arr;
};

const getSixMonth = () => {
    const arr = [];
    for (let i = 180; i >= 0; i--) {
        arr.push(i);
    }
    return arr;
};

const dayInMoth = getOneMonth();

const dayInThreeMonth = getThreeMonth();

const dayInSixMonth = getSixMonth();

export {
    hourInDay,
    dayInWeek,
    filterInfo,
    dayInMoth,
    dayInThreeMonth,
    dayInSixMonth,
};
  