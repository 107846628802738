import React, { useEffect, useRef, useState } from "react";

// component
import iconFb from "../../../assets/images/users/ic_fb.png";
import iconUser from "../../../assets/images/users/ic_user.png";
import iconGroupUser from "../../../assets/images/users/ic_group_user.png";
import iconAssignPage from "../../../assets/images/users/ic_assign_page.png";
import iconCategory from "../../../assets/images/users/ic_category.png";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useRedux } from "../../../hooks";
import { Modal } from "antd";
import ListTeam from "./ListTeam";
import { getAuthMe } from "../../../api/auth";
import { set } from "date-fns";
import { authLoginApiResponseSuccess } from "../../../redux/auth/login/actions";
import { AuthLoginActionTypes } from "../../../redux/auth/login/types";

interface IndexProps {
    pageList: any[];
    total: any
}

interface User {
    role: string;
}

const Index = (props: IndexProps) => {
    const navigate = useNavigate();
    const { dispatch, useAppSelector } = useRedux();

    const stateLogin = createSelector(
        (state : any) => state.Login,
        (state) => ({
          isUserLogin: state.Login,
          user: state.user,
        })
    );

    const { user  } = useAppSelector(stateLogin);

    const { total } = props;
    const [codeSelected, setCodeSelected] = useState("all");
    const [showModalListTeam, setShowModalListTeam] = useState(false);

    const fetchData = async () => {
        const res: any = await getAuthMe();
        if(res?.success){ 
            dispatch(authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_FB_USER, res?.data || {}))
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="p-3 bg-white" style={{ borderRadius: 8, marginTop: 16 }}>
            <ul className="list-inline user-chat-nav mb-">
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0 mb-2">
                    <div
                        onClick={() => setCodeSelected("all")}
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            // backgroundColor: codeSelected == 'all' ? '#0000ff14' : 'white',
                            border: codeSelected == 'all' ? `1px solid ${localStorage.getItem('colorTheme') || '#4eac6d'}` : '1px solid #d0d0d0',
                            padding: "5px 10px",
                            borderRadius: 5,
                            height: 40,
                        }}
                    >
                        <img
                            src={iconCategory}
                            alt=""
                            style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span style={{ fontWeight: 'bold', marginRight: 40 }}>Tất cả</span>
                        <span className={`badge ${codeSelected == 'all' ? 'bg-primary' : 'bg-secondary'} rounded-pill ms-2`}>{total}</span>
                    </div>
                </li>
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0 mb-2">
                    <div
                        onClick={() => setCodeSelected("facebook")}
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: codeSelected == 'facebook' ? '#0000ff14' : 'white',
                            border: codeSelected == 'facebook' ? '1px solid blue' : '1px solid #d0d0d0',
                            padding: "5px 10px",
                            borderRadius: 5,
                            height: 40,
                        }}
                    >
                        <img
                            src={iconFb}
                            alt=""
                            style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span style={{ fontWeight: 'bold', marginRight: 40 }}>Facebook</span>
                        <span className={`badge ${codeSelected == 'facebook' ? 'bg-primary' : 'bg-secondary'} rounded-pill ms-2`}>{total}</span>
                    </div>
                </li>
                {
                    (user?.role == 'Admin' || user?.role == 'SystemAdmin') && (
                        <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0 mb-2">
                            <div
                                onClick={() => {
                                    setCodeSelected("user_management")
                                    navigate("/user-management");
                                }}
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: codeSelected == 'user_management' ? '#0000ff14' : 'white',
                                    border: codeSelected == 'user_management' ? '1px solid blue' : '1px solid #d0d0d0',
                                    padding: "5px 10px",
                                    borderRadius: 5,
                                    height: 40,
                                }}
                            >
                                <img
                                    src={iconUser}
                                    alt=""
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                <span style={{ fontWeight: 'bold', marginRight: 40 }}>Quản lý user</span>
                            </div>
                        </li>
                    )
                }
                {
                    (user?.role == 'Admin' || user?.role == 'SystemAdmin') && (
                        <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0 mb-2">
                            <div
                                onClick={() => {
                                    setCodeSelected("group_management")
                                    navigate("/group-management");
                                }}
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: codeSelected == 'group_management' ? '#0000ff14' : 'white',
                                    border: codeSelected == 'group_management' ? '1px solid blue' : '1px solid #d0d0d0',
                                    padding: "5px 10px",
                                    borderRadius: 5,
                                    height: 40,
                                }}
                            >
                                <img
                                    src={iconGroupUser}
                                    alt=""
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                <span style={{ fontWeight: 'bold', marginRight: 40 }}>Quản lý nhóm</span>
                            </div>
                        </li>
                    )
                }

                 {
                    ( user?.role == 'Manager') && (
                    // ( 1 === 1) && (
                        <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0 mb-2">
                            <div
                                onClick={() => {
                                    setCodeSelected("assign-page")
                                    setShowModalListTeam(true)
                                }}
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: codeSelected == 'assign-page' ? '#0000ff14' : 'white',
                                    border: codeSelected == 'assign-page' ? '1px solid blue' : '1px solid #d0d0d0',
                                    padding: "5px 10px",
                                    borderRadius: 5,
                                    height: 40,
                                }}
                            >
                                <img
                                    src={iconAssignPage}
                                    alt=""
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                <span style={{ fontWeight: 'bold', marginRight: 40 }}>Gán thành viên vào Page</span>
                            </div>
                        </li>
                    )
                }

            </ul>

            <Modal destroyOnClose title="Vui lòng chọn nhóm" open={showModalListTeam} footer={null} onCancel={() => { setShowModalListTeam(false) }}>
                <ListTeam />    
            </Modal>

        </div>
    );
};

export default Index;
