import React, { useEffect, useRef, useState } from "react";
import _ from 'lodash';
import dayjs from 'dayjs';

// hooks
import {
  getCountries,
  getListOrdersReport,
  getListCustomers,
  getListChannels,
  exportListOrderReport,
} from "../../api/order";

// router
// component
import { Table, Input, Row, Col, notification, Select, DatePicker, Spin } from 'antd';


import styled from "styled-components";
import { Button } from "reactstrap";
import { set } from "date-fns";
import moment from "moment";
import { RangePickerProps } from "antd/es/date-picker";

interface IndexProps {
  className?: string;
}
const { RangePicker } = DatePicker;


const Index = (props: IndexProps) => {

  const [listOrdered, setListOrdered] = useState<any>([]);
  const [listCountries, setListCountries] = useState<any>([])
  const [customerId, setCustomerId] = useState<any>('')
  const [channelId, setChannelId] = useState<any>('')
  const [startTime, setStartTime] = useState<any>('')
  const [endTime, setEndTime] = useState<any>('')

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [q, setQ] = useState('');
  const [listCustomers, setListCustomers] = useState<any>([])
  const [listChannels, setListChannels] = useState<any>([])

  const getData = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page,
      limit
    }
    setListOrdered([])
    const res: any = await getListOrdersReport(data);
    setLoading(false)
    if(res?.success){ 
      setListOrdered(res.data.items)
      setTotal(res.data.total)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại'});
    }
  }


  const handleChangePage = (evt: number, size: number) => {
    onSearch(evt, size);
  };

  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`;
  };

  const getListCountries = async () => {
    const res:any = await getCountries();
    if(res.success) {
        setListCountries(res.data.items || [])
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại'});
    }
  }

  const getCustomers = async (name: string) => {
    const params = {
      page: 1,
      limit: 50,
      q: name
    };
    const res:any = await getListCustomers(params);
    if(res.success) {
      setListCustomers(res.data.items || []);
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại'});
    }
  }

  const getChannels = async (name: string) => {
    const params = {
      page: 1,
      limit: 50,
      q: name
    };
    const res:any = await getListChannels(params);
    if(res.success) {
      setListChannels(res.data.items || []);
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại'});
    }
  }


  const onSearch = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page: page,
      limit: limit,
      q: q,
      customerId,
      channelId,
      startTime: startTime ? moment(startTime, "DD/MM/YYYY").valueOf() : undefined,
      endTime: endTime ? moment(endTime, "DD/MM/YYYY").valueOf() : undefined
    }
    setListOrdered([])
    const res: any = await getListOrdersReport(data);
    setLoading(false)
    if(res?.success){ 
      setListOrdered(res.data.items)
      setTotal(res.data.total)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại'});
    }
  }

  const onExport = async() => {
    setLoadingExcel(true)
    const data = {
      q: q,
      customerId,
      channelId,
      startTime: startTime ? moment(startTime).valueOf() : undefined,
      endTime: endTime ? moment(endTime).valueOf() : undefined
    }
    const res: any = await exportListOrderReport(data);
    console.log("res", res)
    setLoadingExcel(false)
    const date = moment().format("DD-MM-YYYY");

    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `Danh_sách_đơn_hàng_${date}.xlsx`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();

  }
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const onSearchCustomer = _.debounce((e: string) => {
    getCustomers(e)
  }, 300)

  const onSearchChannel = _.debounce((e: string) => {
    getChannels(e)
  }, 300)


  useEffect(() => {
    getChannels('');
   }, [])

  useEffect(() => {
    getCustomers('');
   }, [])

  useEffect(() => {
    getListCountries();
   }, [])

  useEffect(() => {
    getData(1, 10);
  }, []);

  const columns = [
    {
      title: 'Ngày gửi',
      dataIndex: '',
      key: '',
      width: 150,
    },
    {
      title: 'MVĐ',
      dataIndex: '',
      key: '',
      width: 150,
    },
    {
      title: 'Tình trạng',
      dataIndex: '',
      key: '',
      width: 150,
    },
    {
      title: 'Ghi chú lên đơn lại',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Ngày chốt đơn',
      dataIndex: '',
      key: '',
      width: 200,
    },
    {
      title: 'Ghi chú',
      dataIndex: '',
      key: '',
      width: 200,
    },
    {
      title: 'Mã Sale',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Tên Sale',
      dataIndex: ['createdBy', 'name'],
      key: 'createdBy',
      width: 200,

    },
    {
      title: 'Mã page',
      dataIndex: ['channelInfo', 'refId'],
      key: 'channelInfo',
      width: 200,
    },
    {
      title: 'Tên page',
      dataIndex: ['channelInfo', 'name'],
      key: 'channelInfo',
      width: 200,
    },
    {
      title: 'Tên khách hàng',
      dataIndex: ['shippingAddress', 'fullName'],
      key: 'fullName',
      width: 200,

    },
    {
      title: 'SĐT',
      dataIndex: ['shippingAddress', 'phone'],
      key: 'phone',
      width: 200,

    },
    {
      title: 'Mã bưu điện',
      dataIndex: ['shippingAddress', 'postalCode'],
      key: 'postalCode',
      width: 200,

    },
    {
      title: 'Địa chỉ',
      dataIndex: ['shippingAddress', 'streetLine1'],
      key: 'streetLine1',
      width: 200,

    },
      {
      title: 'Ký hiệu sản phẩm',
      dataIndex: 'orderLines',
      key: 'orderLines',
      width: 200,
      render: (orderLines: any) => {
        return orderLines.map((item: any, index: string) => {
          return (
            <div key={index}>
              <p>{item?.productCode || ''} </p>
            </div>
          )
        })
      }
    },
    {
      title: 'Link Facebook',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'orderLines',
      key: 'orderLines',
      width: 200,
      render: (orderLines: any) => {
        return orderLines.map((item: any, index: string) => {
          return (
            <div key={index}>
              <p>{item.productId || ''}</p>
            </div>
          )
        })
      }
    },
 
    {
      title: 'Số lượng',
      dataIndex: 'orderLines',
      key: 'orderLines',
      width: 200,
      render: (orderLines: any) => {
        return orderLines.map((item: any, index: string) => {
          return (
            <div key={index}>
              <p>{item?.qty || ''} </p>
            </div>
          )
        })
      }
    },
    {
      title: 'Giờ nhận(Nhật)',
      dataIndex: '',
      key: '',
      width: 200,
    },
    {
      title: 'Quốc gia',
      dataIndex:  ['shippingAddress', 'countryCode'],
      key: 'countryCode',
      width: 200,
      render: (countryCode: any) => {
        const country =  listCountries.find((item: any) => item?.code === countryCode);
        return <div>{country?.name || ''}</div>
      }
    },
    {
      title: 'Ngoại tệ',
      dataIndex: '',
      key: '',
      width: 200,
    },
    {
      title: 'Ngoại tệ sau phí',
      dataIndex: '',
      key: '',
      width: 200,
    },
    {
      title: 'Tiền việt',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Tiền việt sau phí',
      dataIndex: '',
      key: '',
      width: 200,

    },

    {
      title: 'Mã MKT',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Tên MKT',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Đơn 22H30',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Ghi chú sản phẩm',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Ghi chú khác',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Ngày C/K',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Tiền C/K',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'K/H Nợ',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Ghi chú cọc',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Tỷ lệ TT',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Kho',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Chốt DS',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Tiền về',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Chốt KPI T5',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Chênh lệch',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'KPI tính vào T6',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'KPI tính vào T7',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'KPI tính vào T8',
      dataIndex: '',
      key: '',
      width: 200,

    },
    {
      title: 'Lũy kế KPI',
      dataIndex: '',
      key: '',
      width: 200,

    },
  ];
  return (
    <div className={props.className}>
      <div className="p-4">
        <div className="filtter">
          <div className="title_page">Danh sách đơn hàng</div>
          <Row gutter={12}>
            <Col span={6}>
              <Input allowClear onChange={(e) => {setQ(e.target.value)}} placeholder="Tìm kiếm"/>
            </Col>
            <Col span={6}>
              <Select 
                filterOption={false}
                showSearch
                onSearch={(e) => {
                  onSearchCustomer(e)
                }}
                allowClear onChange={(e) => setCustomerId(e)} style={{width: '100%'}} placeholder="Tên Sale">
                {
                  listCustomers.map((item: any, index: number) => {
                    return <Select.Option key={index} value={item.customerId}>{item.name}</Select.Option>
                  })
                }
              </Select>
            </Col>
            <Col span={6}>
              <Select 
                filterOption={false}
                showSearch
                onSearch={(e) => {
                  onSearchChannel(e)
                }}
                allowClear onChange={(e) => setChannelId(e)} style={{width: '100%'}} placeholder="Chọn Page">
                {
                  listChannels.map((item: any, index: number) => {
                    return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                  })
                }
              </Select>
            </Col>
            <Col span={6}>
              <RangePicker
                  placeholder={['Từ ngày', 'Đến ngày']}

                  disabledDate={disabledDate}
                  format={'DD/MM/YYYY'}
                  style={{width: '100%'}}
                   onChange={(value, dateString) => {
                    if(value === null) {
                      setStartTime(undefined)
                      setEndTime(undefined)
                    } else {
                      setStartTime(dateString[0])
                      setEndTime(dateString[1])
                    }
                  }}
              />
            </Col>
            <Col span={6}>
            </Col>
          </Row>
          <div className="d-flex justify-content-end m-2">
            <div className="d-flex justify-content-end px-3"><Spin spinning={loadingExcel}><Button onClick={onExport}>Xuất Excel</Button></Spin></div>
            <div className="d-flex justify-content-end"><Button onClick={() => onSearch(1, 10)} color="primary">Tìm kiếm</Button></div>
          </div>
        </div>
        <Table
            loading={loading}
            dataSource={listOrdered} columns={columns} 
            pagination={{
              showSizeChanger: true,
              total: total,
              onChange: handleChangePage,
              showTotal: showTotal,
            }}
            scroll={{ x: 800, y: 'calc(100vh - 356px)'}}
          />
      </div>
    </div>
  );
};

export default styled(Index)`
  width: 100%;
  overflow: hidden;
  .filtter {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .title_page {
    margin-bottom: 15px;
    font-weight: 600;
  }
  /* .ant-table-wrapper {
    height: 1000px;
    overflow: hidden;
  } */

`