import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { getListOrders } from "../../../../api/order";
import { Collapse,Divider  } from "antd";
import { createSelector } from "reselect";
import { useRedux } from "../../../../hooks";
import ItemOrder from "./ItemOrder";
import NoteInfo from "./NoteInfo"
// hooks


interface IndexProps {
  className?: string;
  refreshTime: number;
}


const InfoOrder = (props: IndexProps) => {
  const { useAppSelector } = useRedux();


  const data = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      selectedChat: state.selectedChat,
      selectedConversation: state.selectedConversation,
    })
  );

  const { selectedChat,selectedConversation } = useAppSelector(data);

  const [listOrder, setListOrder] = useState<any>([])
  const [activeKey, setActiveKey] = useState<any>([])

  const getListOrder = async () => {
    const params = {
      page: 1,
      limit: 50,
      selectedChat: selectedChat,
    };
    const res: any = await getListOrders(params);
    if (res?.statusCode == 200) {
      setListOrder(res.data.items || [])
      setActiveKey([...activeKey, res.data.items[0]?.id])
    }
  }


  const onChange = (key: any) => {
    setActiveKey(key)
  }

  useEffect(() => {
    getListOrder();
  }, [selectedChat, props.refreshTime])



  const convertItem = () => {
    const listOrderCollapse: any = [];
    // eslint-disable-next-line no-lone-blocks
    {
      listOrder.forEach((item: any, _index: number) => (
        listOrderCollapse.push(
          {
            key: item?.id,
            label: item?.id.slice(-4),
            children: <ItemOrder item={item} />,
          }
        )
      ))
    }

    return listOrderCollapse;
  }
  return (
    <div className={props.className}>
      <NoteInfo props={props} selectedConversation={selectedConversation}/>
      {/* <div className="text-center title mb-3">{`Đơn hàng (${listOrder.length})`}</div> */}
      <Divider style={{  borderColor: '#D9D9D9' }} className="text-center title mb-3">{`Đơn hàng (${listOrder.length})`}</Divider>
      {
        listOrder?.length > 0 ? (
          <Collapse items={convertItem()}
            activeKey={activeKey}
            onChange={onChange}
          />
        ) : null
      }
    </div>
  );
};




export default styled(InfoOrder)`
  .title {
    font-weight: 600;
  } 
  .ant-collapse {
    background-color: rgba(var(--bs-primary-rgb), 1);
  }
  .ant-collapse-expand-icon {
    color: #Fff;
  }
  .ant-collapse-header-text {
    color: #fff;
  }
  .ant-collapse-header {
    padding: 5px 10px !important;
  }
 
`