import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getTagList = (id: string, params: any) => {
    return api.get(url.CHANNELS + "/" + id + url.TAGS, { params: params });
};

const getTagListAll = ( params: any) => {
    return api.create(url.TAGS + '/list', {
        channelId: params.channelId?.split(','),
        isAll: params.isAll,
        isGroup: params.isGroup,
        q:params.q
    });
};

const addTag = (id: string, data: any) => {
    return api.create(url.CHANNELS + "/" + id + url.TAGS, data);
};

const addMultiTag = (data: any) => {
    return api.create(url.TAGS, data);
};

const deleteTag = (channelId: string, tagId: string) => {
    return api.delete(url.CHANNELS + "/" + channelId + url.TAGS + "/" + tagId);
};

const updateTag = (channelId: string, tagId: string, data: any) => {
    return api.update(url.CHANNELS + "/" + channelId + url.TAGS + "/" + tagId, data);
};

const updateTagAll = (channelId: string, data: any) => {
    return api.update(url.CHANNELS + "/" + channelId + url.TAGS, data);
};

const syncTag = (data: any) => {
    return api.create(url.TAGS + url.SYNC, data);
};

export {
    getTagList,
    addTag,
    deleteTag,
    updateTag,
    addMultiTag,
    getTagListAll,
    updateTagAll,
    syncTag,
};
