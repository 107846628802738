import React, { useState, useEffect } from "react";
// hooks
import type { FormProps } from 'antd';
import { Button, notification, Form, Input, Row, Select, Col, InputNumber } from 'antd';
// apis
import {createOrder,  getCountries, checkoutOrder} from '../../../../api/order';
import styled from "styled-components";
import { PlusOutlined, ShoppingCartOutlined, IdcardOutlined, WalletOutlined } from '@ant-design/icons';
import { createSelector } from "reselect";
import { useRedux } from "../../../../hooks";
import ItemProduct from "./ItemProduct";
import formatNumber from "../../../../utils/formatNumber";

import { useSearchParams } from "react-router-dom";
import InputDiscount from "./InputDiscount";

interface IndexProps {
    onCreateSuccess: any;
    className?: string;
}

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
    className?: string;
};

const { Option } = Select;


const CreateOrder = (props: IndexProps) => {
    const { useAppSelector } = useRedux();
    const [searchParams] = useSearchParams();
    const groupId = searchParams.get('groupId');
    const channelId = searchParams.get('channelId');

    const [listCountries, setListCountries] = useState<any>([])
    const data = createSelector(
        (state: any) => state.Chats,
        (state) => ({
            selectedChat: state.selectedChat,
            groupInfo: state.groupInfo,
        })
      );
      
    const { selectedChat, groupInfo } = useAppSelector(data);

    const [form] = Form.useForm();   
    const onFinish: FormProps<FieldType>['onFinish'] = async (values: any) => {
        console.log("values", values)
        try {
			const payload = {
				orderLines: values?.orderLines || [],
				note: values.note || '',
                channelId: groupId ? groupInfo?.channelIds?.toString() : channelId,
                discount: values?.discount || 0,
				shippingAddress: {
                    fullName: values.fullName || '',
                    phone: values.phone || '',
					streetLine1: values.streetLine1 || '',
					streetLine2: values.streetLine2 || '',
					city: values.city || '',
					province: values.province || '',
					countryCode: values.countryCode || '',
					postalCode: values.postalCode || '',
				},
                selectedChat: selectedChat
			}
            console.log("payload", payload)
			const res: any = await createOrder(payload)
			if(res?.success) {
                const resCheckout: any = await checkoutOrder(payload)
                if(resCheckout?.success) {
                    form.resetFields();
                    notification.success({
                        message: "Tạo đơn hàng thành công",
                        description: "Đơn hàng đã được tạo thành công",
                        placement: "top"
                    });
                    props.onCreateSuccess();
                }
			}
		} catch (error) {
			console.log("err", error)
			notification.error({
				message: "Tạo đơn hàng thất bại",
				description: "Đã có lỗi xảy ra",})
		}
    };
      
    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
      
    const getListCountries = async () => {
        const res:any = await getCountries();
        if(res.success) {
            setListCountries(res.data.items || [])
        }
        console.log("err getCountries", res)
    }

   useEffect(() => {
        form.resetFields()
   }, [selectedChat])

   useEffect(() => {
    getListCountries();
   }, [])

  return (
    <div className={props.className}>
        <Form
			form={form}
            name="basic"
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div className="title px-2"><span><IdcardOutlined style={{fontSize: 16, marginRight: 5}}/></span>Khách hàng</div>
            <div className="px-2">
                <Row gutter={10}>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            name="fullName"
                        >
                            <Input placeholder="Họ tên"/>
                        </Form.Item>
                        
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            name="phone"
                        >
                            <Input placeholder="Số điện thoại"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="streetLine1"
                >
                    <Input placeholder="Địa chỉ"/>
                </Form.Item>

                
                <Form.Item name="streetLine2">
                    <Input placeholder="Căn hộ, tòa nhà"/>
                </Form.Item>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name="city"
                        >
                            <Input placeholder="Nhập thành phố"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="province">
                            <Input placeholder="Nhập tiểu bang/tỉnh"/>
                        </Form.Item>
            
                    </Col>
                    <Col span={12}>
                        <Form.Item name="postalCode">
                            <Input placeholder="Mã bưu chính"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="countryCode">
                            <Select
                                placeholder="Quốc gia"
                            >
                                {
                                    listCountries.map((item: any) => (
                                        <Option key={item.id} value={item.code}>{item.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <div className="line"/>
            <div className="title px-2"><span><ShoppingCartOutlined style={{fontSize: 16, marginRight: 5}}/></span>Sản phẩm</div>
            <div className="list_product mx-2">
                <div className="color_table">
                    <Row gutter={6}>
                        <Col span={5}>
                           <div className="table_title">Mã SP</div>
                        </Col>
                        <Col span={5}>
                            <div className="table_title">Tên SP</div>
                        </Col>
                        <Col span={4}>
                            <div className="table_title">SL</div>
                        </Col>
                        <Col span={5}>
                            <div className="table_title">Đơn giá</div>
                        </Col>
                        <Col span={5}>
                            <div className="table_title text-right">Thành tiền</div>
                        </Col>
                    </Row>
                </div>
                <Form.List name="orderLines">
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields?.length > 0 ? fields.map((field, index) => (
                                <ItemProduct key={field.key} 
                                    className={""} 
                                    field={field} 
                                    remove={remove}
                                    form={form}
                                />
                            )) : <div className="text-center empty_cart">Chưa có sản phẩm nào</div>}
                            <div className="d-flex justify-content-end">
                                <Button
                                    type="primary"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                >
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </div>
                        </>
                    )}
                </Form.List>
            </div>
            <div className="line"/>
            <div className="title px-2"><span><WalletOutlined style={{fontSize: 16, marginRight: 5}}/></span>Thanh toán</div>
            <div className="mx-2">
                <div className="d-flex justify-content-between">
                    <div>Tổng giá trị đơn hàng</div>
                    <div >
                        <Form.Item
                            shouldUpdate={(prevValues, currentValues) => prevValues.orderLines !== currentValues.orderLines}
                        >
                            {({ getFieldValue }) => {
                                const orderLines = getFieldValue("orderLines");
                                if(orderLines?.length > 0) {
                                    const sumPrice = orderLines.reduce((sum: any, item: any) => {
                                        return sum + (item?.qty || 0) * (item?.price || 0);
                                    }, 0)
                                    return <span className="price">{formatNumber(sumPrice)}</span>;
                                } return <span className="price">{0}</span>;
                            }}
                        </Form.Item>
                    </div>
                </div>
                <div className="mb-3 d-flex justify-content-between">
                    <div>Giảm giá</div>
                    <div>
                        <InputDiscount form={form}/>
                    </div>
                </div>

                <Form.Item
                    name="note"
                >
                    <Form.Item>
                        <Input.TextArea placeholder="Ghi chú" />
                    </Form.Item>
                </Form.Item>
            </div>
          
            <div className=" bottom_bar ">
                <div className="item_sum_price">
                    <Form.Item
                        shouldUpdate={(prevValues, currentValues) => (prevValues.orderLines !== currentValues.orderLines || prevValues.discount !== currentValues.discount)}
                    >
                        {({ getFieldValue }) => {
                            const orderLines = getFieldValue("orderLines");
                            const sumPrice = orderLines?.reduce((sum: any, item: any) => {
                                return sum + (item?.qty || 0) * (item?.price || 0);
                            }, 0) || 0
                            const discount = getFieldValue("discount") || 0;
                            const total = sumPrice - discount;
                            return <span className="text-right total" style={{color: total > 0 ? "green" : "red"}}>{formatNumber(total)}</span>;
                        }}
                    </Form.Item>   
                </div> 
                <div className="btn_bottom">
                    <Button
                        style={{width: '48%'}}
                        onClick={() => form.resetFields()}
                    >
                        Thiết lập lại
                    </Button>
                    <Button style={{width: '48%'}} type="primary" htmlType="submit">
                        Tạo đơn
                    </Button>
                </div>
            </div>
        </Form>
    </div>
  );
};



export default styled(CreateOrder)`
    height: 100%;
    .ant-form {
        height: 100%;
    }
    .ant-form-item {
        margin-bottom: 10px;
    }
    .title {
        font-weight: 600;
        margin-bottom: 10px;
		font-size: 14px;
        border-bottom: 1px solid #D9DBEA;
        padding-bottom: 4px;
        padding-top: 4px;
    }
    .list_product {
        border: 1px solid #ECEDF4;
        border-radius: 4px;
        margin-bottom: 10px;
    }
    .color_table {
        background: #ECEDF4 !important;
        margin-bottom: 10px;
        border-radius: 3px;
        padding: 5px 5px;
        .table_title {
            font-weight: 600;
            font-size: 13px;
        }
    }
    .btn_bottom {
        display: flex;
        justify-content: space-between;
    }
    .line {
        height: 10px; 
        background-color: #EAECF0; 
        width: 100%;
        border-radius: 0px;
    }
    .price {
        font-weight: 600;
        font-size: 16px;
    }
    .bottom_bar {
        padding: 10px;
        background-color: #fff;
        width: 100%;
        border-top: 1px solid #EAECF0;
    }
    .item_sum_price {
        font-weight: 600;
        font-size: 16px;
        text-align: right;
    }
    .total {
        color: green;
    }
`