import { useEffect } from 'react';
import { Input } from "reactstrap";

// hooks
import { useRedux } from "../../../../hooks/index";
import { createSelector } from "reselect";

interface InputSectionProps {
  value: null | string;
  onChange: (value: string) => void;
  onEnter: () => void;
  isCatching?: boolean;
  onKeyPressEnter?: () => void;
  onKeyPressUp?: () => void;
  onKeyPressDown?: () => void;
}

const InputSection = ({ value, onChange, onEnter, isCatching, onKeyPressEnter, onKeyPressDown, onKeyPressUp }: InputSectionProps) => {

  const { useAppSelector } = useRedux();
  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      selectedConversation: state.selectedConversation,
    })
  );
  
  const { selectedConversation } = useAppSelector(errorData);
  
  useEffect(() => {
    const element = document.getElementById("chat-input");
    if (element && selectedConversation?.conversationId) {
      element.focus();
      onChange('');
    }
  }, [selectedConversation]);

  return (
    <div className="position-relative">
      <Input
        id="chat-input"
        type="textarea"
        className="form-control form-control-lg chat-input"
        style={{
          resize: 'none',
          outline: 'none'
        }}
        placeholder="Nhập nội dung tin nhắn"
        value={value || ""}
        onChange={(e: any) => {
          if (
            // Nếu người dùng paste vào input thì không cần xử lý
            (e.nativeEvent.inputType === "insertFromPaste") ||
            (e.target.value.substr(e.target.value.length - 1) === '\n')
          ) {
            return;
          }
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (isCatching) {
            if (e.code === 'Enter') {
              e.preventDefault();
              onKeyPressEnter && onKeyPressEnter();
            } else if (e.key === 'ArrowUp') {
              e.preventDefault();
              onKeyPressUp && onKeyPressUp();
            } else if (e.key === 'ArrowDown') {
              e.preventDefault();
              onKeyPressDown && onKeyPressDown();
            }
          } else {
            if (e.code === 'Enter') {
              if (!e.shiftKey) {
                onEnter();
              } else {
                onChange(value + '\n');
              }
            }
          }
        }}
        onPaste={(e: any) => { // Xử lý đoạn người dùng paste vào input ở đây
          const items = e.clipboardData.items;
          let isFilePasted = false;
          for (let index in items) {
            const item = items[index];
            if (item.kind === 'file') {
              e.preventDefault();
              isFilePasted = true;
              break;
            }
          }
          if (!isFilePasted) {
            const pastedText = e.clipboardData.getData('Text');
            console.log("pastedText", pastedText)
            onChange(pastedText);
          }
        }}

        autoComplete="off"
      />
    </div>
  );
};
export default InputSection;
