import React, { useState, useEffect } from "react";
// hooks
import { Form, Input, Row, Col, InputNumber, Popover } from 'antd';
// apis
import styled from "styled-components";
import { MinusCircleOutlined, DeleteOutlined} from '@ant-design/icons';
import formatNumber from "../../../../utils/formatNumber";
import { text } from "stream/consumers";


interface IndexProps {
    className?: string;
    form?: any
}


const InputDiscount = (props: IndexProps) => {
    const { form } = props;
    const [percent, setPercent] = useState<number>();
  return (
    <div className={props.className}
    >
        <Popover
            onOpenChange={(e) => {
                
            }}
            placement="left"
            content={
                <div className="d-flex justify-content-center align-items-center">
                    <InputNumber onChange={(e: any) => {
                        setPercent(e)
                        const orderLines = form.getFieldValue('orderLines');
                        if(orderLines?.length > 0) {
                            const sumPrice = orderLines?.reduce((sum: any, item: any) => {
                                return sum + (item?.qty || 0) * (item?.price || 0);
                            }, 0)
                            const discount = sumPrice * e / 100;
                            form.setFieldsValue({
                                discount: discount
                            })
                        }

                    }} value={percent} placeholder="Giảm giá" />
                    <span className="mx-2">%</span>
                </div>
            } trigger="click">
            <Form.Item name="discount" 
                shouldUpdate={(prevValues: any, currentValues: any) => prevValues.orderLines !== currentValues.orderLines || prevValues.discount !== currentValues.discount }
            >
                <InputNumber
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                    style={{width: '100%', textAlignLast: 'right'}} min={1} placeholder="0" 
                />
            </Form.Item>
            
        </Popover>
       
    </div>
  );
};



export default styled(InputDiscount)`
     .ant-input-number-handler-wrap {
        display: none;
        left: 0;
        border-start-start-radius: 0;
        border-start-end-radius: 6px;
        border-end-end-radius: 6px;
     }
`