/* eslint-disable jsx-a11y/img-redundant-alt */
import { styled } from "styled-components";
import React, { useEffect, useState } from 'react';
import { Avatar, List, Mentions, Modal, message, Popover, Popconfirm, Button, Empty, UploadProps, UploadFile, GetProp, Image } from 'antd';
import { toast } from "react-toastify";
import moment from "moment";
import VirtualList from 'rc-virtual-list';
import _ from "lodash";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getQuickNote, postQuickNote, pinQuickNote, updateItemNote, delQuickNote } from "../../../../api/note";
import { uploadMedia } from "../../../../api/index";
import IconPin from "../../../../assets/images/note/pin.svg";
import IconDel from "../../../../assets/images/note/del.svg";
import IconEdit from "../../../../assets/images/note/edit.svg";
import IconPhoto from "../../../../assets/images/note/photo.svg";
import ModalAddImage from "../../../../components/ModalAddImage";
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

interface IndexProps {
    className?: string;
    props: any;
    selectedConversation: any
}

interface IndexImg {
    className?: string;
    props: any;
    images: any,
    item: any,
    editItem: any
}

const ImageOverlay = (props: IndexImg) => {
    const [initImg, setInitImg] = useState(props.item && props?.images.length > 0 ? props?.images[0]?.location : IconPhoto);

    useEffect(() => {
        if ((props?.images.length) > 0) {
            setInitImg(props?.images[0]?.location);
        }
        else {
            setInitImg(IconPhoto) 
        }
    }, [props?.images])

    const extraImagesCount = (props?.images).length - 1;
    return (
        <div style={{ position: 'relative' }}>
            <Image
                src={initImg}
                alt="Main Image"
                style={{ cursor: 'pointer', height: 20, width: 20 }}
                preview={false}
            />
            {extraImagesCount > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '12px',
                    }}
                >
                    +{extraImagesCount}
                </div>
            )}
        </div>
    );
};

const NoteInfo = (props: IndexProps) => {
    const [data, setData] = useState([]);
    const [fileList, setFileList] = useState<any>([]);
    const [hoveredItem, setHoveredItem] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemHistory, setItemHistory] = useState([]);
    const [isModalUpload, setIsModalUpload] = useState(false);
    const ContainerHeight = 400;
    const [editItem, setEditItem] = useState<string | null>(null);
    const [note, setNote] = useState<string>('');
    const [noteItem, setNoteItem] = useState<string>('');
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);

    function formatTimeWithDay(timestamp: any) {
        const date = moment(timestamp);
        const now = moment();
        const diffMinutes = now.diff(date, 'minutes');
        const diffHours = now.diff(date, 'hours');

        if (diffMinutes < 1) {
            return 'Vừa xong';
        } else if (diffMinutes < 60) {
            return `${diffMinutes} phút trước`;
        } else if (diffHours < 24) {
            return `${diffHours} giờ trước`;
        } else if (date.isSame(now, 'day')) {
            return date.format('HH:mm');
        } else if (date.isSame(moment().subtract(1, 'days'), 'day')) {
            return `${date.format('HH:mm')} hôm qua`;
        } else {
            return `${date.format('HH:mm')} ${date.format('DD/MM/YY')}`;
        }
    }


    const getListQuickNote = async () => {
        const params = {
            channelId: props?.selectedConversation?.channelId,
            customerId: props?.selectedConversation?.from?.customerId,
        };
        const res: any = await getQuickNote(params);
        if (res?.statusCode == 200) {
            setData(res.data.items || [])
        }
    }

    useEffect(() => {
        if (props?.selectedConversation?.channelId !== undefined) {
            getListQuickNote();
        }
    }, [props?.selectedConversation?.from?.customerId])


    const handleSaveNote = async () => {
        const params = {
            channelId: props?.selectedConversation?.channelId,
            customerId: props?.selectedConversation?.from?.customerId,
            conversationId: props?.selectedConversation?.conversationId,
            content: note.trim() ? note : "\n",
            images: fileList
        };
        const res: any = await postQuickNote(params);
        if (res?.statusCode === 201) {
            getListQuickNote()
            setNote("")
            setFileList([])
            message.success(`Ghi chú được bổ sung thành công !`);
        }
    }

    const onDeleteNote = async () => {
        const res: any = await delQuickNote(hoveredItem);
        if (res?.statusCode === 200) {
            getListQuickNote()
            setNote("")
        }
        message.success(`Ghi chú đã được xóa thành công !`);
    }

    const onPinNote = async () => {
        const res: any = await pinQuickNote(hoveredItem);
        if (res?.statusCode === 200) {
            getListQuickNote()
            setNote("")
        }
        message.success(`Ghim ghi chú thành công !`);
    }

    const onSaveItemNote = async () => {
        const params = {
            content: noteItem.trim() ? noteItem : "\n",
            images: fileList
        };
        const res: any = await updateItemNote(hoveredItem, params);
        if (res?.statusCode == 200) {
            setEditItem(null)
            getListQuickNote()
            setNoteItem("")
            setFileList([])
            message.success(`Ghi chú được bổ sung thành công !`);
        }
    }

    useEffect(() => {
        if(editItem&&data.length > 0){
            const a:any = data.find((ob:any)=>(ob.id === editItem))
            setFileList(a.images);
        }
        else {
            setFileList([]);
        }
    }, [editItem, data])


    const showModal = (item: any) => {
        setItemHistory(item?.editHistories)
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalUpload = () => {
        setIsModalUpload(true);
    };

    const handleCancelUpload = () => {
        setIsModalUpload(false);
    };
    return (
        <div className={props.className}>
            <div>
                {data?.length > 0 ?
                    <List >
                        <VirtualList
                            data={data}
                            height={ContainerHeight}
                            itemHeight={47}
                            itemKey="email"
                            style={{ padding: '0px 10px',overflowX: 'hidden' }}
                        >
                            {(item: any) => (
                                <List.Item
                                    key={item?.id}
                                    onMouseEnter={() => setHoveredItem(item?.id)}
                                    onMouseLeave={() => setHoveredItem("")}
                                    className={`list-item ${hoveredItem === item?.id ? 'hovered' : ''}`}
                                >
                                    <List.Item.Meta
                                        avatar={<Avatar src={item?.user?.avatar} style={{ borderRadius: 20, width: 22, height: 22 }} />}
                                        title={<a href="#">{item?.user?.name}</a>}
                                        description={
                                            editItem !== item.id ? item?.status !== "DELETED" ?
                                                <div style={{ color: '#000', fontSize: 12 }}>{item?.content}
                                                    {item?.status === "UPDATED" ?
                                                        <span onClick={() => showModal(item)} style={{ color: 'rgb(45, 101, 195)' }}> &nbsp;•&nbsp;
                                                            <span className="show_edit_btn">Đã sửa</span>
                                                        </span> : ''
                                                    }
                                                    <br />
                                                    {(item?.images).map((image: any, index: number) => {
                                                        return (
                                                            <Image src={image?.location} alt={`Image ${index + 1}`} style={{ width: '22px', height: '22px', borderRadius: 4 }} />
                                                        )
                                                    }
                                                    )}
                                                </div> : <div className="delete_by">{(item?.editHistories)[(item?.editHistories)?.length - 1]?.user?.name + " " + "đã xoá ghi chú này" + " " + formatTimeWithDay((item?.editHistories)[(item?.editHistories)?.length - 1]?.updatedAt)}</div> :
                                                <div style={{ backgroundColor: '#F2F4F8', borderRadius: 8, display: 'flex', width: 420,paddingRight:10 }}>
                                                    <Mentions
                                                        defaultValue={item?.content}
                                                        onChange={(text: any) => setNoteItem(text)}
                                                        allowClear={false}
                                                        prefix={"/"}
                                                        onPressEnter={onSaveItemNote}
                                                        placeholder="Viết ghi chú hoặc /shortcut để ghi chú nhanh"
                                                        style={{ padding: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: 12 }}
                                                    />
                                                    <div style={{ flex: '1 1 0%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: 10, cursor: 'pointer' }} onClick={() => {
                                                        showModalUpload()
                                                        setFileList(item.images)
                                                    }}>
                                                        <ImageOverlay props={props} images={fileList} item={item} editItem={editItem} />
                                                    </div>
                                                </div>
                                        }
                                    />{
                                        editItem !== item?.id ?
                                            <div className="create_edit_time">
                                                {item?.status === "DELETED" ? "" : <>
                                                    {item?.pinned === true ?
                                                        <>
                                                            {hoveredItem === item?.id && editItem !== item?.id && (
                                                                <>
                                                                    <Popover content={
                                                                        <div style={{ padding: '5px 10px', backgroundColor: 'rgba(0, 0, 0, .75)', color: '#fff', borderRadius: 4 }}>Sửa ghi chú</div>} >
                                                                        <img src={IconEdit} className="edit-button" alt="Edit" onClick={() => {
                                                                            setEditItem(item?.id)
                                                                            setNoteItem(item?.content)
                                                                            setFileList(item?.images)
                                                                        }} />&nbsp;</Popover>
                                                                    <Popconfirm
                                                                        placement="bottomRight"
                                                                        icon={null}
                                                                        okButtonProps={{
                                                                            style: {
                                                                                display: 'none',
                                                                            },
                                                                        }}
                                                                        cancelButtonProps={{
                                                                            style: {
                                                                                display: 'none',
                                                                            },
                                                                        }}
                                                                        title={<div style={{ padding: '16px' }}>
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div><ExclamationCircleOutlined style={{ color: 'rgb(255, 122, 69)' }} /></div>
                                                                                &nbsp;
                                                                                <div>Bạn có chắc chắn muốn xoá <br />ghi chú này không</div>
                                                                            </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10 }}>
                                                                                <div><Button size="small" style={{ color: "#fff", backgroundColor: '#C9405A', border: 'none' }} onClick={() => onDeleteNote()}>Xoá</Button></div>
                                                                                &nbsp;
                                                                                <div><Button size="small" style={{ color: "#3A3A3C", backgroundColor: '#F2F4F8', border: 'none' }}>Bỏ qua</Button></div>
                                                                            </div>
                                                                        </div>}
                                                                    >
                                                                        <Popover content={<div style={{ padding: '5px 10px', backgroundColor: 'rgba(0, 0, 0, .75)', color: '#fff', borderRadius: 4 }}>Xoá ghi chú</div>} >
                                                                            <img src={IconDel} className="hover-button" alt="Delete" />&nbsp;
                                                                        </Popover>
                                                                    </Popconfirm>
                                                                </>
                                                            )}
                                                            <Popover content={<div style={{ padding: '5px 10px', backgroundColor: 'rgba(0, 0, 0, .75)', color: '#fff', borderRadius: 4 }} >Bỏ Ghim ghi chú</div>} >
                                                                <img src={IconPin} className="hover-button" alt="Pin" onClick={() => onPinNote()}
                                                                    style={{ filter: item?.pinned == true ? "brightness(0) saturate(100%) invert(40%) sepia(500%) hue-rotate(-30deg) saturate(500%) brightness(95%)" : "" }} />
                                                            </Popover>
                                                        </>

                                                        : <>
                                                            {hoveredItem === item?.id && editItem !== item?.id && (
                                                                <>
                                                                    <Popover content={<div style={{ padding: '5px 10px', backgroundColor: 'rgba(0, 0, 0, .75)', color: '#fff', borderRadius: 4 }}>Sửa ghi chú</div>} >
                                                                        <img src={IconEdit} className="edit-button" alt="Edit" onClick={() => {
                                                                            setEditItem(item?.id)
                                                                            setNoteItem(item?.content)
                                                                            setFileList(item?.images)
                                                                        }} />&nbsp;</Popover>
                                                                    <Popconfirm
                                                                        placement="bottomRight"
                                                                        icon={null}
                                                                        okButtonProps={{
                                                                            style: {
                                                                                display: 'none',
                                                                            },
                                                                        }}
                                                                        cancelButtonProps={{
                                                                            style: {
                                                                                display: 'none',
                                                                            },
                                                                        }}
                                                                        title={<div style={{ padding: '16px' }}>
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div><ExclamationCircleOutlined style={{ color: 'rgb(255, 122, 69)' }} /></div>
                                                                                &nbsp;
                                                                                <div>Bạn có chắc chắn muốn xoá <br />ghi chú này không</div>
                                                                            </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10 }}>
                                                                                <div><Button size="small" style={{ color: "#fff", backgroundColor: '#C9405A', border: 'none' }} onClick={() => onDeleteNote()}>Xoá</Button></div>
                                                                                &nbsp;
                                                                                <div><Button size="small" style={{ color: "#3A3A3C", backgroundColor: '#F2F4F8', border: 'none' }}>Bỏ qua</Button></div>
                                                                            </div>
                                                                        </div>}
                                                                    >
                                                                        <Popover content={<div style={{ padding: '5px 10px', backgroundColor: 'rgba(0, 0, 0, .75)', color: '#fff', borderRadius: 4 }}>Xoá ghi chú</div>} >
                                                                            <img src={IconDel} className="hover-button" alt="Delete" />&nbsp;
                                                                        </Popover>
                                                                    </Popconfirm>
                                                                    <Popover content={<div style={{ padding: '5px 10px', backgroundColor: 'rgba(0, 0, 0, .75)', color: '#fff', borderRadius: 4 }} >Ghim ghi chú</div>} >
                                                                        <img src={IconPin} className="hover-button" alt="Pin" onClick={() => onPinNote()}
                                                                            style={{ filter: item?.pinned == true ? "brightness(0) saturate(100%) invert(40%) sepia(500%) hue-rotate(-30deg) saturate(500%) brightness(95%)" : "" }} />
                                                                    </Popover>
                                                                </>
                                                            )}
                                                        </>
                                                    }
                                                </>}
                                                <span style={{ fontSize: 12 }}>{formatTimeWithDay(item?.updatedAt)}</span>
                                                <br/>
                                                <div style={{ fontSize: 12 }}>{moment(item?.updatedAt).format('DD-MM-YYYY')}</div>
                                            </div>
                                            : <div><Button size="small" onClick={() => {
                                                setEditItem(null)
                                                setFileList([])
                                            }} style={{ fontWeight: 500 }}>Huỷ</Button>&nbsp;
                                                <Button size="small" style={{ background: '#2D65C3', color: 'white' }} onClick={() => onSaveItemNote()}>Lưu</Button>
                                            </div>
                                    }
                                </List.Item>
                            )}
                        </VirtualList>
                    </List>
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                        "Chưa có ghi chú"
                    } />}
                <div style={{ margin: '4px 10px 0px', backgroundColor: '#F2F4F8', borderRadius: 8, display: 'flex', cursor: 'pointer' }}>
                    <Mentions autoFocus allowClear={false} prefix={"/"} placeholder="Viết ghi chú hoặc /shortcut để ghi chú nhanh"
                        style={{ padding: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: 12 }}
                        onChange={(text: any) => setNote(text)}
                        value={note}
                        onPressEnter={handleSaveNote}

                    />
                    <div style={{ flex: '1 1 0%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: 10 }} onClick={() => {
                        showModalUpload()
                        setEditItem(null)
                        setFileList([])
                    }}>
                        {editItem ? < img src={IconPhoto} style={{ cursor: 'pointer', height: 20, width: 20 }} alt="Photo" />
                            : <ImageOverlay props={props} images={fileList} item={null} editItem={null} />
                        }
                    </div>
                </div>
                <div>
                    <Modal title={<div style={{
                        padding: "16px 24px", color: 'rgba(0, 0, 0, .65)',
                        background: '#fff',
                        borderBottom: '1px solid #e8e8e8',
                        borderRadius: '4px 4px 0 0'
                    }}>Lịch sử sửa ghi chú</div>} open={isModalOpen} onCancel={handleCancel} className={props.className} cancelText={'Đóng'} okButtonProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                    >
                        <div className="history_edit_note">
                            {_.map(itemHistory, (item: any) => {
                                return (
                                    <>
                                        <div className="item_history_note">
                                            <div className="history_note_info">
                                                <div className="avatar">
                                                    <img src={item?.user?.avatar} style={{ cursor: 'pointer', borderRadius: 16, width: 30, height: 30 }} alt="Photo" />
                                                </div>
                                                <div style={{ marginLeft: 6 }}>
                                                    <div style={{ fontWeight: 500 }}>{item?.user?.name}</div>
                                                    <div>{item?.content}</div>
                                                    <div>
                                                        {(item?.images).map((image: any, index: number) => {
                                                            return (
                                                                <Image src={image?.location} alt={`Image ${index + 1}`} style={{ width: '22px', height: '22px', borderRadius: 4 }} />
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="create_edit_time">
                                                <span style={{ fontSize: 12 }}>{formatTimeWithDay(item?.updatedAt)}</span>
                                                <br/>
                                                <div>{moment(item?.updatedAt).format('DD-MM-YYYY')}</div>
                                            </div>
                                        </div>
                                        <hr style={{ margin: '4px 0px 0px' }} />
                                    </>
                                )
                            })}

                        </div>
                    </Modal>
                    <ModalAddImage
                        isOpen={isModalUpload}
                        channelId={props?.selectedConversation?.channelId}
                        onClose={handleCancelUpload}
                        idItem={editItem}
                        fileLists={fileList}
                        onSetFileList={(data: any) => {
                            setFileList((currentFileList: any) => {
                                return data?.concat(currentFileList);
                            });
                        }}
                        onReplaceFileList={(list: any) => {
                            setFileList(fileList?.filter((item: any) => !item?.mimetype?.includes('image')).concat(list));
                        }}
                    />
                </div>
            </div>

        </div>
    );
};

export default styled(NoteInfo)`
.ant-list .ant-list-item {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 12px 0;
    color: rgba(0, 0, 0, 0.88);
}
.rc-virtual-list-holder {
    overflow-x: hidden;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-avatar {
    margin-inline-end: 8px;
}
.ant-mentions >textarea {
    overflow-y: hidden
}
.create_edit_time {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;
    color: #6B7588;
    padding:0px 0px
}
.ant-mentions-outlined {
  border-width: 0px;
  background-color: #F2F4F8;
}
.show_edit_btn {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #2D65C3;
    cursor: pointer;
}
.delete_by {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000073;
}
.hover-button {
    cursor: pointer;
    filter: grayscale(0%) brightness(0) invert(60%);
    transition: filter 0.3s ease;
    color:#91979A !important
}

.edit-button {
    cursor: pointer;
    filter: grayscale(0%) brightness(0) invert(60%);
    transition: filter 0.3s ease;
    color:#91979A !important
}

.edit-button:hover {
   filter: brightness(100%) saturate(150%) invert(30%) sepia(0%) hue-rotate(200deg) saturate(500%) brightness(25%);
}

.hover-button:hover {
    filter: brightness(0) saturate(100%) invert(40%) sepia(500%) hue-rotate(-30deg) saturate(500%) brightness(95%);
}
.ant-mentions-outlined:focus-within {
    box-shadow: none !important;
}
.ant-upload-wrapper .ant-upload-list {
    display: none !important;
}
.ant-modal-content {
   padding: 0px !important;
}
.ant-modal-header {
   margin-bottom: 0px;
}

.ant-modal-content {
   padding: 0px !important;
}
.ant-modal-body {
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
    max-height: 50em;
    overflow-y: auto;
    padding: 0px 0px;
}
.history_edit_note {
    max-height: 50em;
    overflow-y: auto;
    padding: 0px 24px;
}
.rc-virtual-list-scrollbar-vertical {
    position: absolute;
    width: 4px;
    top: 0px;
    bottom: 0px;
    right: -9px;
  }
    
.item_history_note {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
}
.history_note_info {
    display: flex;
    flex: 1;
    align-items: flex-start;
    max-width: 100%;
}
.avatar {
    display: inline-block;
    width: 32px;
    vertical-align: top;
}
.history_note {
    margin-left:6px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size:14px;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: rgba(0, 0, 0, 0.65) !important;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: rgba(0, 0, 0, 0.65) !important;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.65) !important;
}

`
