import React, { useEffect, useState } from "react";
import { useRedux } from "../../hooks/index";
import { createSelector } from "reselect";
import classnames from "classnames";

// hooks
import {
    getOverviewData,
} from "../../api/index";

// router
import { useSearchParams } from "react-router-dom";

// component
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import {
    CheckOutlined,
} from '@ant-design/icons';
import moment from "moment";

// utils
import {
    hourInDay,
    dayInWeek,
    filterInfo,
    dayInMoth,
    dayInThreeMonth,
    dayInSixMonth,
} from "../../utils";

interface IndexProps { }

const Overview = (props: IndexProps) => {
    const { dispatch, useAppSelector } = useRedux();
    const [searchParams] = useSearchParams();
    const groupId = searchParams.get('groupId');
    const channelId = searchParams.get('channelId');
    const [valueFilter, setValueFilter] = useState<string>('4');
    const [dataOverview, setDataOverView] = useState<any>(null);

    const data = createSelector(
        (state: any) => state.Chats,
        (state) => ({
            groupInfo: state.groupInfo,
        })
    );
    
    const { groupInfo } = useAppSelector(data);

    useEffect(() => {
        if ((groupId && groupInfo?.channelIds) || channelId) {
            getStatisticData(valueFilter);
        }
    }, [groupInfo?.channelIds, groupId, channelId]);

    const getStatisticData = async (filter: string) => {
        try {
            const response: any = await getOverviewData({
                startTime: getStartTime(filter),
                endTime: getEndTime(filter),
                period: filterInfo.find((item: any) => item.key === filter)?.type,
                channelIds: groupId ? groupInfo?.channelIds?.toString() : channelId,
            });
            if (response?.data) {
                console.log("response", response);
                setDataOverView(response.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const getStartTime = (filter: string) => {
        switch (filter) {
            case '1':
                return moment().startOf('day').toISOString();
            case '2':
                return moment().subtract(1, 'days').startOf('day').toISOString();
            case '3':
                return moment().subtract(7, 'days').startOf('day').toISOString();
            case '4':
                return moment().subtract(30, 'days').startOf('day').toISOString();
            case '5':
                return moment().subtract(3, 'months').startOf('day').toISOString();
            case '6':
                return moment().subtract(6, 'months').startOf('day').toISOString();
            default:
                return moment().startOf('day').toISOString();
        }
    };

    const getEndTime = (filter: string) => {
        switch (filter) {
            case '2':
                return moment().subtract(1, 'days').endOf('day').toISOString();
            default:
                return moment().endOf('day').toISOString();
        }
    };

    const categoriesXAsis = (filter: string) => {
        switch (filter) {
            case '1':
                return hourInDay;
            case '2':
                return hourInDay;
            case '3':
                return dayInWeek.map((item: number) => moment().subtract(item, 'days').format('DD'));
            case '4':
                return dayInMoth.map((item: number) => moment().subtract(item, 'days').format('DD'));
            case '5':
                return dayInThreeMonth.map((item: number) => moment().subtract(item, 'days').format('DD'));
            case '6':
                return dayInSixMonth.map((item: number) => moment().subtract(item, 'days').format('DD'));
            default:
                return dayInMoth.map((item: number) => moment().subtract(item, 'days').format('DD'));
        }
    }

    const dataYAsisTotalInbox = (filter: string) => {
        switch (filter) {
            case '1':
                return hourInDay.map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('HH') === i)?.customerInboxCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '2':
                return hourInDay.map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('HH') === i)?.customerInboxCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '3':
                return dayInWeek.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.customerInboxCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '4':
                return dayInMoth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.customerInboxCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '5':
                return dayInThreeMonth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.customerInboxCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '6':
                return dayInSixMonth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.customerInboxCount || 0;
                    } else {
                        return 0;
                    }
                });
            default:
                return dayInMoth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.customerInboxCount || 0;
                    } else {
                        return 0;
                    }
                });
        }
    }

    const dataYAsisTotalCustomer = (filter: string) => {
        switch (filter) {
            case '1':
                return hourInDay.map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('HH') === i)?.newCustomerCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '2':
                return hourInDay.map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('HH') === i)?.newCustomerCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '3':
                return dayInWeek.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newCustomerCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '4':
                return dayInMoth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newCustomerCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '5':
                return dayInThreeMonth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newCustomerCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '6':
                return dayInSixMonth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newCustomerCount || 0;
                    } else {
                        return 0;
                    }
                });
            default:
                return dayInMoth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newCustomerCount || 0;
                    } else {
                        return 0;
                    }
                });
        }
    }
    const dataYAsisTotalOrder = (filter: string) => {
        switch (filter) {
            case '1':
                return hourInDay.map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('HH') === i)?.newOrderCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '2':
                return hourInDay.map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('HH') === i)?.newOrderCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '3':
                return dayInWeek.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newOrderCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '4':
                return dayInMoth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newOrderCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '5':
                return dayInThreeMonth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newOrderCount || 0;
                    } else {
                        return 0;
                    }
                });
            case '6':
                return dayInSixMonth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newOrderCount || 0;
                    } else {
                        return 0;
                    }
                });
            default:
                return dayInMoth.map((day: number) => moment().subtract(day, 'days').format('DD/MM/YYYY')).map((i) => {
                    if (dataOverview) {
                        return dataOverview?.find((item: any) => moment(item?.time).format('DD/MM/YYYY') === i)?.newOrderCount || 0;
                    } else {
                        return 0;
                    }
                });
        }
    }
    const chartOptions: Highcharts.Options = {
        chart: {
            zooming: {
                type: 'xy'
            }
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },
        xAxis: [{
            categories: categoriesXAsis(valueFilter),
            crosshair: true
        }],
        yAxis: [
            { // Primary yAxis
                title: {
                    text: 'Khách hàng / Đơn hàng',
                },
                opposite: true
            },
            { // Secondary yAxis
                gridLineWidth: 0,
                title: {
                    text: 'Tương tác',
                },

            }
        ],
        tooltip: {
            shared: true,
            backgroundColor: '#1A1A1A',
            style: {
                color: 'white',
            },
            headerFormat: '<span style="font-size: 12px">{point.key}</span><br/><br/>',
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: 'Tổng tương tác',
                type: 'column',
                yAxis: 1,
                data: dataYAsisTotalInbox(valueFilter),

            },
            {
                name: 'Khách hàng mới',
                type: 'spline',
                yAxis: 0,
                data: dataYAsisTotalCustomer(valueFilter),
                marker: {
                    enabled: false
                },

            },
            {
                name: 'Số đơn hàng',
                type: 'spline',
                data: dataYAsisTotalOrder(valueFilter),
                marker: {
                    enabled: false
                },
            }
        ],
    };

    const onFilterChange = (value: string) => () => {
        setValueFilter(value);
        getStatisticData(value);
    }

    return (
        <div
            className={classnames("user-chat", "w-100", "overflow-hidden")}
            id="user-chat"
            style={{
                justifyContent: "center",
                display: "flex",
            }}
        >
            <div className="chat-content w-100 h-100" style={{padding: '24px 0 24px 24px'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 24
                }}>
                    <span className="username" style={{ fontWeight: 'bold', fontSize: 18 }}>Tổng quan</span>
                    <Dropdown menu={{
                        items: filterInfo.map((item: any) => ({
                            key: item.key,
                            label: (
                                <div
                                    style={{
                                        padding: '4px 12px',
                                        backgroundColor: item.key === valueFilter ? '#f0f0f0' : 'transparent',
                                        borderRadius: 6,
                                    }}
                                    onClick={onFilterChange(item.key)}
                                >
                                    <span style={{
                                        fontWeight: item.key === valueFilter ? '500' : 'normal',
                                    }}>{item.name}</span>
                                    {
                                        item.key === valueFilter && (
                                            <CheckOutlined
                                                style={{
                                                    color: localStorage.getItem('colorTheme') || '#4eac6d',
                                                    marginLeft: 16,
                                                    fontSize: 16,
                                                }}
                                            />
                                        )
                                    }
                                </div>
                            ),
                        })),
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            padding: '6px 12px',
                            borderRadius: 6,
                            marginRight: 24,
                        }}>
                            <span style={{ marginRight: 8, fontWeight: '500' }}>
                                {
                                    filterInfo.find((item: any) => item.key === valueFilter)?.name
                                }
                            </span>
                            <DownOutlined />
                        </div>
                    </Dropdown>
                </div>
                <div style={{
                    height: 'calc(100vh - 200px)',
                    overflow: 'auto',
                }}>
                <div style={{
                    borderRadius: 8,
                    padding: '16px 0 16px 16px',
                    backgroundColor: 'white',
                    marginRight: 24,
                    minWidth: 1100
                }}>
                    <div style={{
                        marginBottom: 24,
                    }}>
                        <div style={{
                            fontSize: 16,
                            fontWeight: '500',
                            marginBottom: 4,
                        }}>
                            Tổng quan về hoạt động
                        </div>
                        <div style={{
                            fontSize: 12,
                            color: '#666',
                        }}>
                            Thống kê tổng quan
                        </div>
                    </div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        containerProps={{ style: { height: "100%" } }}
                    />
                </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
