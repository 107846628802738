import React, { useState, useEffect } from 'react';
import {
    AreaChartOutlined,
    WechatOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';

// router
import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const { Sider } = Layout;

interface IndexProps {
    children?: React.ReactNode;
}

const Index = (props: IndexProps) => {
    const { children } = props;
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const groupId = searchParams.get('groupId');
    const channelId = searchParams.get('channelId');
    const [selectedKey, setSelectedKey] = useState('');

    useEffect(() => {
        const path = location.pathname.split('/').pop();
        setSelectedKey(path?.toString() || '');
    }, [location]);

    const onSelect = (key: string) => {
        setSelectedKey(key);
        navigate(`/statistic/${key}?${groupId ? `groupId=${groupId}` : channelId ? `channelId=${channelId}` : ''}`);
    }

    return (
        <Layout style={{height: 'calc(100vh - 56px)'}}>
            <Sider width={300} style={{ background: colorBgContainer }}>
                <div className="px-4 pt-4">
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1" style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <h4 className="mb-4">Thống kê</h4>
                        </div>
                    </div>
                </div>
                <Menu
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    items={[
                        {
                            key: 'overview',
                            icon: 
                                <AreaChartOutlined
                                    style={{
                                        fontSize: 16,
                                    }}
                                />,
                            label: 'Tổng quan',
                        },
                        {
                            key: 'inbox',
                            icon: 
                                <WechatOutlined
                                    style={{
                                        fontSize: 16,
                                    }}
                                />,
                            label: 'Tương tác',
                        },
                    ]}
                    onClick={(e) => {
                        onSelect(e.key);
                    }}
                />
            </Sider>
            <Layout>
                {children}
            </Layout>
        </Layout>
    );
};

export default Index;
