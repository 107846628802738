import React, { useEffect, useState, useCallback } from "react";
import { createSelector } from "reselect";
import { Button, Dropdown, Modal, notification, message, Timeline, Tooltip, Spin } from 'antd';
import { Select } from 'antd';


// hooks
import { useRedux } from "../../../hooks/index";

// actions
import styled from "styled-components";

import './custom-modal.css';
import { getHistoryChat } from "../../../api/chatmenu";
import _ from "lodash";
import moment from "moment";

const { Option } = Select;


interface HistoryConversationProps {
  className?: string;
}
const HistoryConversation = ({
  className,
}: HistoryConversationProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const state = createSelector(
    (props: any) => props.Chats,
    (props) => ({
      selectedConversation: props.selectedConversation,
      selectedChat: props.selectedChat,


    })
  );

  // Inside your component
  const { selectedConversation, selectedChat,  } = useAppSelector(state);

  const [dataHistory, setDataHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('history');


  const getHistoryChatData = async (conversationId: any) => {
    try {
      const response: any = await getHistoryChat({ conversationId: conversationId });
      if (response?.success) {
        setDataHistory(response?.data?.items)
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  useEffect(() => {
    if (selectedConversation?.conversationId) {
      getHistoryChatData(selectedConversation?.conversationId)
    }
  }, [selectedConversation])

  return (
    <div className={className}>
       <div className="tab-headers">
            <div
                className={`tab-header ${activeTab === 'history' ? 'active' : ''}`}
                onClick={() => setActiveTab('history')}
            >
                {activeTab === 'history' ? <span style={{ color: '#1890ff' }}>Thẻ hội thoại</span> : "Thẻ hội thoại"}

            </div>
            {/* <div
                className={`tab-header ${activeTab === 'assignment' ? 'active' : ''}`}
                onClick={() => setActiveTab('assignment')}
            >
                {activeTab === 'assignment' ? <span style={{ color: '#1890ff' }}>Phân công nhân viên</span> : "Phân công nhân viên"}

            </div> */}
            </div>
            <div className={`tab-content ${activeTab === 'history' ? 'active' : ''}`}>
            <Timeline style={{ color: 'rgba(0, 0, 0, .65)', height: 'calc(60vh - 65px)', overflow: 'auto', padding: '10px 0px' }}>
                {_.map(dataHistory, (items: any, index) => {
                return (
                    <Timeline.Item key={index} color={items?.action === "DELETE" ? "red" : "blue"}>
                    <img src={items?.user?.avatar} style={{ marginRight: 5, border: '1px solid rgb(255, 255, 255)' }} className="rounded-circle avatar-xs" />
                    <span style={{ fontWeight: 'bold' }}>{items?.user?.name}</span>
                    <span style={{ marginLeft: 5, fontSize: 11, fontStyle: 'italic', color: '#a5a5a5' }}>{moment(items?.updatedAt).format("HH:mm DD/MM")}</span>
                    <div>
                        {items?.action === "DELETE" ? "Đã bỏ thẻ" : "Đã thêm thẻ"}&nbsp;
                        <span style={{ backgroundColor: items?.tag?.color, color: '#fff', textAlign: 'center', borderRadius: 4, display: 'inline-block', }}>&nbsp;{items?.tag?.name}&nbsp;</span>
                    </div>
                    </Timeline.Item>
                )
                })}
            </Timeline>
            </div>
            <div className={`tab-content ${activeTab === 'assignment' ? 'active' : ''}`}>
            {/* Content for "Phân công nhân viên" tab */}
            Phân công nhân viên content goes here...
            </div>
    </div>
  );
};


export default styled(HistoryConversation)`
 .ant-select-selection-item {
  text-align: left;
 }
  .send_by {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B7588;
  } 
.custom-modal .ant-modal-content {
  padding: 16px; /* Adjust padding as needed */
}

.custom-modal .ant-modal-title {
  font-size: 16px; /* Adjust title font size as needed */
}

.custom-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
}

.custom-modal .history-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .icon {
  width: 20px; /* Adjust icon size as needed */
  height: 20px;
  border-radius: 50%;
  margin-right: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .name {
  font-weight: bold;
  margin-right: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .time {
  color: gray;
  margin-right: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .action {
  margin-right: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .label {
  display: inline-block;
  padding: 4px 8px; 
  border-radius: 4px;
  font-size: 12px; 
  color: white;
}
.tags-update-timeline {
    padding: 16px 24px;
    overflow: auto;
    height: calc(60vh - 65px);
}

.custom-modal .history-item .label.orange { background-color: orange; }
.custom-modal .history-item .label.purple { background-color: purple; }
.custom-modal .history-item .label.blue { background-color: blue; }
.custom-modal .history-item .label.green { background-color: green; }
`