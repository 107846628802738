import React, { useEffect, useState } from "react";

// component
import { Input, Tooltip } from 'antd';

// styles
import styled from "styled-components";

interface IndexProps {
    className?: string;
    onSetName: (value: any) => void;
    name: string;
}

const InputImage = (props: IndexProps) => {
    const { name, onSetName, className } = props;
    const [imgName, setImgName] = useState<string>(name);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setImgName(e.target.value);
    };

    const onBlur = () => {
        setIsEdit(false);
        if (imgName?.trim()?.length > 0) {
            onSetName(imgName);
        } else {
            setImgName(name);
        }
    }

    return (
        <div className={className}>
            {
                isEdit ? (
                    <Input
                        value={imgName}
                        onChange={onChange}
                        onPressEnter={onBlur}
                        onBlur={onBlur}
                        onDragEnter={onBlur}
                        autoFocus
                        style={{
                            width: 120,
                            height: 32,
                            fontSize: 14,
                        }}
                        onKeyDown={e => e.stopPropagation()}
                    />
                ) : (
                    <Tooltip
                        title={name}
                        placement="bottom"
                    >
                        <div 
                            className="image-name"
                            onClick={() => setIsEdit(true)}
                        >{name}</div>
                    </Tooltip>
                )
            }
        </div>
    );
};

export default styled(InputImage)`
    .image-name {
        width: 120px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        padding: 0 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 6px;
        &:hover {
            background-color: #E1E1E5;
        }
    }
`
