import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { toast } from "react-toastify";

// components
import QuickReplies from './QuickReplies';
import LightBox from "../../../../../components/LightBox";
import ModalAddImage from "../../../../../components/ModalAddImage";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import type { GetProp, UploadFile, UploadProps } from 'antd';
import {
  Dropdown,
  message,
  notification,
  Spin,
  Upload,
  Tooltip,
  Space,
  Radio,
} from 'antd';
import {
  PictureFilled,
  PlusOutlined,
  SmileOutlined,
  CloseOutlined,
  MailOutlined,
  MessageOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';

// hooks
import { useRedux } from "../../../../../hooks/index";
import { createSelector } from "reselect";

// interface
import {
  uploadMedia,
  getPageSelected,
  getQuickRepliesList,
  onTranslateText,
} from "../../../../../api/index";

// actions
import {
  uploadListImageDraft,
} from "../../../../../redux/actions";

// utils
import {
  checkKeyInsert,
} from "../../../../../utils";

// styles
import styled from "styled-components";
import ListImageDraft from "./ListImageDraft";
import Emoji from "./Emoji";


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface IndexProps {
  onSend: (data: any, fileList: any, type: string, typeMsg: string) => void;
  objConversation: any;
  repliedPrivateMessageId: string;
  className?: string;
}
const Index = ({
  onSend,
  objConversation,
  repliedPrivateMessageId,
  className,
}: IndexProps) => {
  const { dispatch, useAppSelector } = useRedux();
  const [channelSelected, setChannelSelected] = useState<any>(null);
  const [fileList, setFileList] = useState<any>([]);

  /*
  input text
  */
  const [text, setText] = useState<null | string>("");
  const [loadingTranslate, setLoadingTranslate] = useState<boolean>(false);
  const [textOriginal, setTextOriginal] = useState<null | string>("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [modalAddImage, setModalAddImage] = useState<boolean>(false);
  const [position, setPosition] = useState(repliedPrivateMessageId ? 'REPLY_INBOX' : 'REPLY_PRIVATE');
  const onChangeText = (value: string) => {
    setText(value);
    if (position === 'REPLY_PRIVATE') {
      setFileList([]);
    }
  };

  const onChangeEmoji = (value: string) => {
    setText(text + value);
    if (position === 'REPLY_PRIVATE') {
      setFileList([]);
    }
  }

  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      listImageDraft: state.listImageDraft,
      selectedConversation: state.selectedConversation,
    })
  );
  // Inside your component
  const { listImageDraft, selectedConversation } = useAppSelector(errorData);

  const getDetailChannel = async (data: any) => {
    try {
      const response: any = await getPageSelected({ channelIds: data?.channelIds });
      if (response?.data?.items?.length > 0) {
        setChannelSelected(response?.data?.items?.[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const onTranslate = async () => {
    if (selectedConversation?.extraInfo?.translation?.translateSentTo) {
      const payload = {
        message: text,
        language: selectedConversation?.extraInfo?.translation?.translateSentTo,
      }
      setTextOriginal(text);
      setLoadingTranslate(true);
      const res: any = await onTranslateText(payload);
      setLoadingTranslate(false);
      if (res?.success) {
        setText(res?.data?.translated?.translated || '');
      }
    } else {
      notification.warning({ description: "Chưa chọn ngôn ngữ dịch", message: "Thông báo" })
    }

  }

  // Call đến Api channels cùng channelIds để get chi tiết 1 channel
  // -> hiển thị avatar & tên channel ở textbox gửi tin nhắn
  useEffect(() => {
    if (objConversation?.channelId) {
      getDetailChannel({
        channelIds: objConversation?.channelId,
      });
    }
  }, [objConversation?.channelId]);

  // Submit Message
  const onSubmit = async () => {
    let data: any = {};
    let arr: any = [];
    if (text) {
      const res = await checkKeyShortCut(text);
      if (res) {
        data["text"] = res?.messages?.map((item: any) => checkKeyInsert(item?.message, objConversation, channelSelected)).join(' ');
        data["textOriginal"] = textOriginal;
        arr = res?.messages?.map((item: any) => item?.photos).flat().concat(res?.messages?.map((item: any) => item?.files).flat())
      } else {
        data["text"] = text;
        data["textOriginal"] = textOriginal;
      }
    }
    onSend(data, [...fileList, ...listImageDraft, ...arr], objConversation?.type, position);
    dispatch(uploadListImageDraft([]));
    setText("");
    setFileList([]);
  };

  const checkKeyShortCut = async (text: string) => {
    try {
      const response: any = await getQuickRepliesList({ channelId: objConversation?.channelId, q: text });
      if (response?.data?.items?.length == 1 && response?.data?.items?.[0]?.shortcut === text.trim()) {
        return response?.data?.items?.[0];
      }
      return null;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  }

  const propsFile: UploadProps = {
    fileList: fileList?.filter((item: any) => !item?.mimetype?.includes('image')).map((item: any) => ({
      uid: item?.id,
      name: item?.originalname,
      url: item?.location,
    })),
    beforeUpload: (file) => {
      const isFile = !file.type.includes('image');
      if (!isFile) {
        message.error(`${file.name} is not a video or a file`);
      }
      return isFile || Upload.LIST_IGNORE;
    },
    customRequest: async ({ file }) => {
      const formData = new FormData();
      formData.append('files', file);
      const response: any = await uploadMedia(formData);
      if (response?.data && response?.success) {
        setFileList(fileList.concat(response?.data));
      } else {
        toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
      }
    },
    onRemove: (info) => {
      console.log(info);
      setFileList(fileList?.filter((item: any) => item?.id !== info?.uid));
    },
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Thêm ảnh</div>
    </button>
  );

  const propsImg: UploadProps = {
    multiple: true,
    fileList: fileList?.filter((item: any) => item?.mimetype?.includes('image')).map((item: any) => ({
      uid: item?.id,
      name: item?.originalname,
      url: item?.location,
    })),

    customRequest: async ({ file }) => {
      const formData = new FormData();
      formData.append('files', file);
      const response: any = await uploadMedia(formData);
      if (response?.data && response?.success) {
        setFileList((currentFileList: any) => {
          return [...currentFileList, response?.data?.[0]];
        });
      } else {
        toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
      }
    },
    onRemove: (info) => {
      setFileList(fileList.filter((item: any) => item?.id !== info?.uid));
    },
    onPreview: async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
  };

  return (
    <div className={className}>
      <div className="chat-input-section" style={{ backgroundColor: '#fff', borderRadius: '0 0 10px 10px' }}>
        <ListImageDraft />
        <div className="px-3 p-lg-2">
          <Form
            id="chatinput-form"
            onSubmit={(e: any) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="row g-0 align-items-center" style={{ position: 'relative' }}>
              <div className="col input-chat" style={{ position: 'relative' }}>
                <InputSection
                  value={text}
                  onChange={onChangeText}
                  onEnter={onSubmit}
                />
                {
                  channelSelected ? (
                    <Tooltip title={channelSelected?.name}>
                      <img
                        className="avatar"
                        src={channelSelected?.picture}
                        alt=""
                      />
                    </Tooltip>
                  ) : null
                }
                <div className="btn_translate" onClick={onTranslate}>{loadingTranslate ? <Spin /> : "Dịch"}</div>
              </div>
              <div
                // className="col-auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 0px 10px"
                }}
              >
                <div style={{ width: '70%', fontSize: 12, color: 'rgb(136, 136, 136)', }}>
                  <Space>
                    <Radio.Group value={position} onChange={(e) => setPosition(e.target.value)}>
                      <Radio.Button value="REPLY_INBOX" style={{ paddingInline: 6, lineHeight: "20px", height: 'auto' }}><MailOutlined /></Radio.Button>
                      <Radio.Button disabled={repliedPrivateMessageId?.length > 0} value="REPLY_PRIVATE" style={{ paddingInline: 6, lineHeight: "20px", height: 'auto' }}><MessageOutlined /></Radio.Button>
                    </Radio.Group>
                  </Space>&nbsp;{position === "REPLY_PRIVATE" ?
                    <span>Gửi tin nhắn từ bình luận <Tooltip title={"Bạn chỉ có thể gửi tin nhắn văn bản hoặc một ảnh"} >
                      <QuestionCircleOutlined style={{ color: 'rgb(250, 173, 20)' }} /></Tooltip >
                    </span> :
                    <span>Gửi trực tiếp đến hộp thư </span>}
                </div>
                <div style={{
                  display: 'flex', alignItems: 'center', right: 0,
                  position: 'absolute'
                }}>
                  <Dropdown
                    trigger={['click']}
                    placement="topRight"
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    dropdownRender={() => <Emoji onChange={onChangeEmoji} />}
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <Tooltip title={'Emoji'}>
                      <div className="btn_record">
                        <SmileOutlined
                          style={{ fontSize: 20, color: '#adb5c2' }}
                        />

                      </div>
                    </Tooltip>
                  </Dropdown>
                  {
                    (!text || position !== 'REPLY_PRIVATE') ? (
                      <Tooltip title={'Image'}>
                        <div
                          className="btn_chat"
                          onClick={() => setModalAddImage(true)}
                        >
                          <PictureFilled
                            style={{ fontSize: 20, fill: '#adb5c2', color: '#adb5c2' }}
                          />
                          {
                            fileList?.filter((item: any) => item?.mimetype?.includes('image')).length > 0 ? (
                              <>
                                <div className="badge" style={{
                                  fontSize: fileList?.filter((item: any) => item?.mimetype?.includes('image')).length > 99 ? 7 : 10,
                                }}>
                                  {
                                    fileList?.filter((item: any) => item?.mimetype?.includes('image')).length > 99 ?
                                      "99+" :
                                      fileList?.filter((item: any) => item?.mimetype?.includes('image')).length
                                  }
                                </div>
                                <div
                                  className="badge-delete"
                                  onClick={() => {
                                    setFileList(fileList?.filter((item: any) => !item?.mimetype?.includes('image')));
                                  }}
                                >
                                  <CloseOutlined style={{ fontSize: 10, color: 'white' }} />
                                </div>
                              </>
                            ) : null
                          }
                        </div>
                      </Tooltip>
                    ) : null
                  }
                  <QuickReplies
                    selectedConversation={objConversation}
                    channelSelected={channelSelected}
                    onSetTxt={setText}
                    onSetFileList={setFileList}
                  />
                  <EndButtons onSubmit={onSubmit} />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {previewOpen && (
        <LightBox
          isOpen={previewOpen}
          images={[{ url: previewImage }]}
          onClose={() => {
            setPreviewOpen(false);
            setPreviewImage('');
          }}
          defaultIdx={0}
        />
      )}
      {modalAddImage && (
        <ModalAddImage
          isOpen={modalAddImage}
          fileLists={fileList?.filter((item: any) => item?.mimetype?.includes('image'))}
          idItem={'0'}
          channelId={objConversation?.channelId}
          onClose={() => setModalAddImage(false)}
          onSetFileList={(data: any) => {
            setFileList((currentFileList: any) => {
              return data?.concat(currentFileList);
            });
          }}
          onReplaceFileList={(list: any) => {
            setFileList(fileList?.filter((item: any) => !item?.mimetype?.includes('image')).concat(list));
          }}
          onlyOne={position === 'REPLY_PRIVATE'}
        />
      )}
    </div>
  );
};

export default styled(Index)`
  .tag-select {
    .ant-select-selector {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }
  }
  .btn_translate {
    position: absolute;
    right: 0px;
    top: 10px;
    cursor: pointer;
    color: #007bff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    &:hover {
      text-decoration: underline;
    }
  }
  .avatar {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 3px;
  }
  .chat-input {
    padding: 10px 70px 10px 40px;
    border:none;
  }
  .btn_record {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-left: 8px;
  }
  .badge {
    position: absolute;
    top: -9px;
    right: -9px;
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid white;
  }
  .badge-delete {
    position: absolute;
    top: -9px;
    right: -9px;
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 50%;
    color: white;
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid white;
  }
  .btn_chat {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
      .badge-delete {
          display: flex;
      }
    }
  }
  .icon_record {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 50px;
    background-color: red;
  }
  .btn_cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: white;
    width: 80px;
    height: 32px;
    font-weight: 500;
    margin-right: 10px;
    cursor: pointer;
  }
  .btn_send {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: blue;
    width: 80px;
    height: 32px;
    font-weight: 500;
    color: white;
    cursor: pointer;
  }
  .txt_time {
    text-align: center;
    color: white;
    font-weight: 500;
    margin-top: 10px;
  }
`
