import React from "react";
import { Button, Col, Row } from "reactstrap";
import { hexToRgba } from "../../../utils/color";

const Welcome = () => {
  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center">
              <div className="avatar-xl mx-auto mb-4">
                <div className="avatar-title rounded-circle" style={{ backgroundColor : hexToRgba(localStorage.getItem('colorTheme') || '#2f54eb', 0.3)}}>
                  <i className="bx bxs-message-alt-detail display-4 text-primary m-0"></i>
                </div>
              </div>
              <h4>Xin chọn 1 hội thoại từ danh sách bên trái</h4>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
