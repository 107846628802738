import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
} from "reactstrap";

// router
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

// hooks
import { useRedux, useProfile } from "../../hooks/index";
import { createSelector } from "reselect";

//actions
import { loginFacebookUser } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import iconFb from "../../assets/images/login/icon_fb.png";

interface LoginProps { }
const Login = (props: LoginProps) => {
  const { dispatch, useAppSelector } = useRedux();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userProfile, loading } = useProfile();
  const errorData = createSelector(
    (state : any) => state.Login,
    (state) => ({
      isUserLogin: state.isUserLogin,

    })
  );
  
  const { isUserLogin } = useAppSelector(errorData);

  useEffect(() => {
    if (searchParams.get('status') && searchParams.get('status') === 'successed' && searchParams.get('token')) {
      dispatch(loginFacebookUser(searchParams.get('token')));
    }
  }, [searchParams]);

  useEffect(() => {
    if (isUserLogin) {
      navigate("/dashboard");
    }
  }, [isUserLogin, navigate]);

  if (userProfile && !loading) {
    return <Navigate to={{ pathname: "/" }} />;
  }

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader
              title="Welcome!"
            />
            <div className="text-center mt-4">
              <Button
                color="primary"
                className="w-100"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                  href={`${process.env.REACT_APP_BASE_URL + ''}/auth/login/facebook?state=${window.location.origin}/auth-login`}
                >
                  <img
                    src={iconFb}
                    alt=""
                    style={{ width: 20, height: 20, marginRight: 8 }}
                  />
                  <span style={{ color: 'white' }}>Log In Facebook</span>
                </a>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Login;
