import React, { useEffect, useState } from "react";

// component
import { Input } from 'antd';

// styles
import styled from "styled-components";

interface IndexProps {
    className?: string;
    onSetName: (value: any) => void;
    onClose: () => void;
    name: string;
    placeholder?: string;
}

const InputFolder = (props: IndexProps) => {
    const { name, onSetName, placeholder, onClose, className } = props;
    const [folderName, setFolderName] = useState<string>(name);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFolderName(e.target.value);
    };

    const onBlur = () => {
        onClose();
        if (folderName?.trim()?.length > 0) {
            onSetName(folderName);
        }
    }

    return (
        <Input
            value={folderName}
            placeholder={placeholder}
            onChange={onChange}
            onPressEnter={onBlur}
            onBlur={onBlur}
            onDragEnter={onBlur}
            autoFocus
            style={{
                height: 38,
                fontSize: 14,
            }}
            onKeyDown={e => e.stopPropagation()}
        />
    );
};

export default styled(InputFolder)`
`
