import React, { useEffect, useState } from "react";
import { Switch, Select, Divider, notification } from "antd";
import { createSelector } from "reselect";
import { useRedux } from "../../hooks";
import { updateSettings } from "../../api/users";

const subtitleStyle = {
    fontSize: "12px",
    color: "#888",
    marginTop: "4px",
  };


const SettingsPanel = () => {
    const { useAppSelector } = useRedux();

    const stateLogin = createSelector(
        (state : any) => state.Login,
        (state) => ({
          isUserLogin: state.Login,
          user: state.user,
        })
    );

    const { user } = useAppSelector(stateLogin);
    
    const [sound, setSound] = useState(user?.settings?.sound || false);

    const onChange = async (value: any) => {
        setSound(value);
        const payload = {
            settings: {
                sound: value
            }
        }
        const res: any = await updateSettings(payload);
        if(res?.success){
            // notification.success({message: "Cập nhật thành công"});
        } else {
            notification.error({message: "Cập nhật thất bại"});
        }
    }
    

  return (
    <div style={{ width: 400, margin: "20px auto", fontFamily: "Arial, sans-serif" }}>
      <Divider />
      <div style={{ marginBottom: 16 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Âm thanh tin nhắn đến</span>
          <Switch onChange={onChange} value={sound}/>
        </div>
        <div style={subtitleStyle}>
            Khi bạn bật cấu hình này các tin nhắn đến sẽ phát ra âm thanh
        </div>
      </div>

    </div>
  );
};

export default SettingsPanel;
