import React, { useEffect, useState } from "react";

//api
import {
    getListGroup,
} from "../../../api/index";

// components
import {
    Select,
} from 'antd';
import {
    DownOutlined
} from '@ant-design/icons';

// router
import { useNavigate } from "react-router-dom";

// styled-components
import styled from "styled-components";

interface IndexProps {
    className?: string;
}
const Index = (props: IndexProps) => {
    const { className } = props;
    const navigate = useNavigate();
    const [groups, setGroups] = useState<any[]>([]);

    useEffect(() => {
        if (groups.length === 0) {
            getListGroups();
        }
    }, [groups]);

    const getListGroups = async () => {
        try {
            const response: any = await getListGroup({
                page: 1,
                limit: 50
            });
            if (
                response?.data?.items?.length > 1 &&
                response?.data?.items?.filter((group: any) => group?.label !== 'Mặc định' && group?.channelIds?.length > 0)?.length > 0
            ) {
                const groups = response?.data?.items?.filter((group: any) => group?.channelIds?.length > 0)?.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                    channelIds: item.channelIds
                }));
                setGroups(groups);
            }
        } catch (error: any) {
            console.log("error", error);
        }
    };

    const onChange = (value: string) => {
        navigate(`/conversation-list?groupId=${value}`);
    };
    
    if (groups?.length === 0) return null;

    return (
        <div className={className}>
            <Select
                className="tag-select"
                labelRender={() => {
                    return (
                        <span
                            style={{
                                fontSize: 13,
                                fontWeight: '300',
                                color: 'black'
                            }}
                        >
                            Vào chế độ gộp trang
                        </span>
                    );
                }}
                suffixIcon={<DownOutlined style={{ color: '#000' }} />}
                defaultValue="1"
                variant="filled"
                style={{
                    margin: '0 13px 5px',
                    width: 'calc(100% - 26px)',
                    fontSize: 9,
                }}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={groups}
                onChange={onChange}
            />
        </div>
    );
};

export default styled(Index)`
  .tag-select {
    .ant-select-selector {
      background: #f8f9fb !important;
    }
  }
`

