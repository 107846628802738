import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { EditOutlined, DeleteOutlined, UsergroupAddOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { Table, Input, Row, Col, notification, Select, Button, Spin, Space, Modal } from 'antd';

import styled from "styled-components";
import { createTeam, getListTeams, getTeamDetail, onDeleteTeam, onDeleteTeamMemberInPage, updateTeam } from "../../api/teams";
import FormGroup from "./FormGroup";

interface IndexProps {
  className?: string;
}
const { confirm } = Modal;

const Index = (props: IndexProps) => {

  const [data, setData] = useState<any>([]);
  const [showModalEdit, setShowModelEdit] = useState(false)
  const [showModalCreate, setShowModelCreate] = useState(false)
  const [itemSelected, setItemSelected] = useState(null)

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState('');


  const getData = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page,
      limit
    }
    setData([])
    const res: any = await getListTeams(data);
    setLoading(false)
    if (res?.success) {
      setData(res.data.items)
      setTotal(res.data.total)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }


  const handleChangePage = (evt: number, size: number) => {
    onSearch(evt, size);
  };


  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`;
  };

  const onSearch = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page: page,
      limit: limit,
      q: q,

    }
    setData([])
    const res: any = await getListTeams(data);
    setLoading(false)
    if (res?.success) {
      setData(res.data.items)
      setTotal(res.data.total)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }


  const onGetDetail = async (item: any) => {
    const res: any = await getTeamDetail(item?.id);
    if (res?.success) {
      setItemSelected(res.data)
      setShowModelEdit(true)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }

  const onDelete = async (item: any) => {
    confirm({
      title: 'Bạn có muốn nhóm không ?',
      icon: <ExclamationCircleFilled />,
      async onOk() {
        const res: any = await onDeleteTeam(item);
        if (res?.success) {
          getData(1, 10);
          notification.success({ message: 'Xóa nhóm thành công' });
        } else {
          notification.error({ message: 'Lỗi vui lòng thử lại' });
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
        
  }

  const onCreate = async (data: any) => {
    const payload = {
      name: data?.name,
      channelIds: data.channelListSelected.map((channel: any) => channel.key),
      managerUserIds: data.managerListSelected.map((manager: any) => manager.key),
      memberUserIds: data.memberListSelected.map((member: any) => member.key),
    }
    const res: any = await createTeam(payload);
    if (res?.success) {
      getData(1, 10);
      setShowModelCreate(false)
      notification.success({ message: 'Tạo nhóm thành công' });
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }
  const onUpdate = async (data: any) => {
    const payload = {
      id: data?.id,
      name: data?.name,
      channelIds: data.channelListSelected.map((channel: any) => channel.key),
      managerUserIds: data.managerListSelected.map((manager: any) => manager.key),
      memberUserIds: data.memberListSelected.map((member: any) => member.key),
    }
    const res: any = await updateTeam(payload);
    if (res?.success) {
      setShowModelEdit(false)
      getData(1, 10);
      notification.success({ message: 'Cập nhật nhóm thành công' });
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }



  useEffect(() => {
    getData(1, 10);
  }, []);

  const columns = [
    {
      title: 'Tên nhóm',
      dataIndex: 'name',
      key: 'name',
      width: 140,
    },

    {
      title: 'Thành viên',
      dataIndex: 'members',
      key: 'members',
      width: 180,
      render: (item: any) => (
        <div>
          {
            item.map((member: any) => (
              <div key={member?.id} className="pb-2">
                <img
                  src={member?.user?.avatar}
                  alt="avatar"
                  style={{ width: 25, height: 25, marginRight: 8, borderRadius: 5 }}
                />
                 {member?.user?.name || ''}
              </div>
            ))
          }
        </div>
      ),
    },
    {
      title: 'Admin được chỉ định',
      dataIndex: 'members',
      key: 'members',
      width: 170,
      render: (item: any) => (
        <div>
          {
            item.map((member: any) => {
              if(member?.role === "Manager")
              return (
                <div key={member?.id} className="pb-2">
                  <img
                    src={member?.user?.avatar}
                    alt="avatar"
                    style={{ width: 25, height: 25, marginRight: 8, borderRadius: 5 }}
                  />
                  {member?.user?.name || ''}
                </div>
              )
            })
          }
        </div>
      ),
    },
    {
      title: 'Các Page Quản trị',
      dataIndex: 'channels',
      key: 'channels',
      render: (item: any) => (
        <div>
          {
            item.slice(0, 2).map((channel: any) => (
              <div key={channel?.id} className="pb-2">
              <img
                src={channel?.picture}
                alt="channel.name"
                style={{ width: 30, height: 30, marginRight: 8, borderRadius: 5 }}
              />
              {channel.name}
                  </div>
                ))
              }
              {item.length > 2 && (
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 30, height: 25, backgroundColor: '#f0f0f0', borderRadius: 5 }}>
                  <span style={{ fontSize: 12 }}>+{item.length - 2}</span>
                </div>
              )}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '',
      width: 160,
      render: (value: any, item: any) => (
        <Space size="middle">
          <div onClick={() => {
            setShowModelEdit(true)
            onGetDetail(item)
          }} style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }} >
            <EditOutlined />
          </div>
          <div onClick={() => {
            onDelete(item)
          }} style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }} >
            <DeleteOutlined />
          </div>
        </Space>
      ),
    },

  ];

  return (
    <div className={props.className}>
      <div className="p-4">
        <div className="filtter">
          <div className="title_page">Quản lý nhóm</div>
          <Row gutter={12}>
            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Input allowClear onChange={(e) => { setQ(e.target.value) }} placeholder="Tìm kiếm" />
              <div className="d-flex justify-content-end m-2">
                <div className="d-flex justify-content-end"><Button onClick={() => onSearch(1, 10)} color="primary">Tìm kiếm</Button></div>
              </div>
            </Col>
          
            <Col span={12}>
              <div className="d-flex justify-content-end m-2">
                <div className="d-flex justify-content-end"><Button type="primary"
                  onClick={() => {
                    setShowModelCreate(true)
                  }} >Tạo nhóm</Button></div>
              </div>
            </Col>
          </Row>
        </div>
        <Table
          loading={loading}
          dataSource={data} columns={columns}
          pagination={{
            showSizeChanger: true,
            total: total,
            onChange: handleChangePage,
            showTotal: showTotal,
          }}
          scroll={{ x: 800, y: 'calc(100vh - 356px)' }}
        />
      </div>
      <Modal width={"90%"}
        destroyOnClose
        style={{minHeight: '90%'}}
        title="Tạo nhóm chỉ dành system admin và admin "
        open={showModalCreate} footer={null}
        onCancel={() => { setShowModelCreate(false) }}
        
      >
        <FormGroup onSave={onCreate}/>
      </Modal>
      <Modal
        width={"90%"}
        destroyOnClose
        title="Chỉnh sửa nhóm "
        open={showModalEdit}
        footer={null}
        onCancel={() => { setShowModelEdit(false) }}
      >
        <FormGroup itemSelected={itemSelected} onSave={onUpdate} />
      </Modal>

    </div>
  );
};

export default styled(Index)`
  width: 100%;
  overflow: hidden;
  .filtter {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .title_page {
    margin-bottom: 15px;
    font-weight: 600;
  }
  

`