import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();


const getListTeams = (params: any) => {
  return api.get(`${url.TEAMS}`,{ params: params });
};

const getTeamDetail = (id: String) => {
  return api.get(`${url.TEAMS}/${id}`,{  });
};


const getListTeamMembers = (params: any) => {
  return api.get(`${url.TEAM_MEMBERS}`,{ params: params });
};


const createTeam = (data: any) => {
  return api.create(url.TEAMS, data);
};

const updateTeam = (data: any) => {
  return api.update(`${url.TEAMS}/${data?.id}`, data);
};

const onDeleteTeam = (data: any) => {
  return api.delete(`${url.TEAMS}/${data?.id}`);
};

const assignPage = (data: any) => {
  return api.create(url.TEAM_MEMBERS, data);
};

const onDeleteTeamMemberInPage = (data: any) => {
  return api.delete(url.TEAM_MEMBERS, data);
};


export {
  getListTeams,
  getTeamDetail,
  getListTeamMembers,
  createTeam,
  updateTeam,
  onDeleteTeam,
  assignPage,
  onDeleteTeamMemberInPage,
};
