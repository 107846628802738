import {
    Dropdown,
    Tooltip
} from 'antd';

// styles
import styled from "styled-components";

interface ListTagProps {
    tags: any;
    className?: string;
}
const ListTag = ({ tags, className }: ListTagProps) => {
    if (!tags || tags?.length === 0) return null;
    return (
        <div className={className} style={{width: '100%'}}>
            <div className="list-tag">
                {
                    tags?.slice(0, 5)?.map((tag: any) => (
                        <Tooltip title={tag?.name} key={tag?.id}>
                            <span
                                key={tag?.id}
                                className="item-tag"
                                style={{
                                    backgroundColor: tag?.color,
                                }}
                            >
                                {tag?.name}
                            </span>
                        </Tooltip>
                    ))
                }
                {
                    tags?.length > 5 ? ( // Hiện thị dấu + nếu số lượng tag lớn hơn 5
                        <Dropdown
                            placement="topLeft"
                            menu={{
                                items: [{
                                    label: (
                                        <div>
                                            {
                                                tags?.slice(5)?.map((tag: any) => (
                                                    <span key={tag?.id}
                                                        style={{
                                                            padding: "4px",
                                                            backgroundColor: tag?.color,
                                                            borderRadius: "4px",
                                                            marginRight: "4px",
                                                            color: "white",
                                                            fontSize: "8px",
                                                            fontWeight: "bold",
                                                        }}>
                                                        {tag?.name}
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    ),
                                    key: 0,
                                }],
                            }}>
                            <span
                                className="item-tag"
                                style={{
                                    backgroundColor: 'rgb(0, 0, 0, 0.5)',
                                    color: 'white',
                                    padding: '3px 5px',
                                }}
                            >+ {tags?.length - 5}</span>
                        </Dropdown>
                    ) : null
                }
            </div>
        </div>
    );
};

export default styled(ListTag)`
  .list-tag {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .item-tag {
    padding: 3px;
    text-align: center;
    border-radius: 4px;
    color: white;
    font-size: 8px;
    font-weight: bold;
    margin: 1px;
  }
`
