import React, { useEffect, useState } from "react";

// component
import {
    Row,
    Col,
} from "reactstrap";
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import ConnectModal from "../../../components/ConnectModal";
import ModalGroup from "../ModalGroup/index";
import { AppstoreAddOutlined } from '@ant-design/icons';
import { createSelector } from "reselect";
import { useRedux } from "../../../hooks/useRedux";

interface IndexProps {
    onGet: () => void;
    onSearch: (v: string) => void;
    showGroup: boolean;
}

const Index = (props: IndexProps) => {
    const { useAppSelector } = useRedux();


    const stateLogin = createSelector(
        (state : any) => state.Login,
        (state) => ({
          isUserLogin: state.Login,
          user: state.user,
        })
    );

    const { user  } = useAppSelector(stateLogin);
    
    const { onGet, onSearch, showGroup } = props;
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [showModalGroup, setShowModalGroup] = useState<boolean>(false);
    const [valueInput, setValueInput] = useState<string>('');

    const onOpen = () => {
        setIsOpenModal(true);
    };

    const onClose = () => {
        setIsOpenModal(false);
    };

    const searchPages = (e: any) => {
        const value = e.target.value;
        setValueInput(value);
        onSearch(value)
    };

    return (
        <div className="p-3 bg-white" style={{ borderRadius: 8 }}>
            <div style={{marginBottom: 8}}>
                <span className="username" style={{ fontWeight: 'bold' }}>Bảng điều khiển</span>
            </div>
            <Row className="align-items-center">
                <Col sm={4}>
                    <Input
                        placeholder="Tìm kiếm"
                        prefix={<SearchOutlined style={{fontSize: 18}} />}
                        onChange={searchPages}
                        value={valueInput}
                        style={{height: 40}}
                        allowClear
                        variant="filled"
                    />
                </Col>
                <Col sm={8}>
                    <ul className="list-inline user-chat-nav text-end mb-0">
                        {
                            (user?.role == 'Admin' || user?.role == 'SystemAdmin') && (
                                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                                    <div
                                        onClick={onOpen}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "#f1f2f3",
                                            padding: "5px 10px",
                                            borderRadius: 5,
                                            height: 40,
                                        }}
                                    >
                                        <i className="bx bx-link"></i>
                                        <span style={{ marginLeft: 4 }}>Kết nối</span>
                                    </div>
                                </li>
                            )
                        }
                        {
                            showGroup ? (
                                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                                    <div
                                        onClick={() => setShowModalGroup(true)}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: "#f1f2f3",
                                            padding: "5px 10px",
                                            borderRadius: 5,
                                            height: 40,
                                        }}
                                    >
                                        <AppstoreAddOutlined />
                                        <span style={{ marginLeft: 4 }}>Chế độ gộp trang</span>
                                    </div>
                                </li>
                            ) : null
                        }
                    </ul>
                </Col>
            </Row>
            {isOpenModal && (
                <ConnectModal
                    isOpen={isOpenModal}
                    onClose={onClose}
                    onGet={onGet}
                />
            )}
            {showModalGroup && (
                <ModalGroup
                    isOpen={showModalGroup}
                    onClose={() => setShowModalGroup(false)}
                />
            )}
        </div>
    );
};

export default Index;

