import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getQuickNote = (params?: object) => {
  return api.get(url.QUICK_NOTES, { params: params });
};

const postQuickNote = (data: object) => {
  return api.create(url.QUICK_NOTES, data);
};

const updateItemNote = (id:string,data: object) => {
  return api.update(url.QUICK_NOTES+ "/" + id, data);
};

const pinQuickNote = (id?: string) => {
  return api.update(url.QUICK_NOTES+ "/" + id+ "/pin-toggle" ,{ params: { id } });
};

const delQuickNote = (id?: string) => {
  return api.delete(url.QUICK_NOTES+ "/" + id,{ params: { id } });
};




export { getQuickNote, postQuickNote,updateItemNote,pinQuickNote,delQuickNote};
