import React, { useEffect, useRef, useState } from "react";
import _ from 'lodash';

import { hexToRgba } from "../../utils/color";

import { Table, Input, Row, Col, notification, Select, DatePicker, Tooltip, Divider, Modal, Button } from 'antd';

import styled from "styled-components";
import SelectBase from "../../components/SelectBase";
import { CHANNELS } from "../../api/urls";

interface FormGroupProps {
  className?: string;
  itemSelected?: any;
  onSave: any;
}
const { RangePicker } = DatePicker;
const Option = Select.Option;


const FormGroup = (props: FormGroupProps) => {
  const {itemSelected, onSave} = props

  const [name, setName] = useState('')
  const [memberListSelected, setMemberListSelected] = useState<any>([])
  const [channelListSelected, setChannelListSelected] = useState<any>([])

  const [managerListSelected, setManagerListSelected] = useState<any>([])

  const onSubmit = () => {
    if(name == "")  {
      notification.warning({ message: 'Vui lòng nhập tên nhóm' });
      return
    }
    if(managerListSelected.length == 0)  {
      notification.warning({ message: 'Vui lòng chọn Admin' });
      return
    }
    if(channelListSelected.length == 0)  { 
      notification.warning({ message: 'Vui lòng chọn page quản lý' });
      return
    }
    const data = {
      id: itemSelected?.id,
      name,
      memberListSelected,
      channelListSelected,
      managerListSelected,
    }
    onSave(data)
  }

  useEffect(() => {
    if(itemSelected) {
      setName(itemSelected.name)
      setMemberListSelected(itemSelected.members?.map((item: any) => ({
        key: item.user?.id,
        value: item?.user?.name
      })))
      setChannelListSelected(itemSelected.channels?.map((item: any) => ({
        key: item.id,
        value: item.name,
      })))
      const listManager: any = [];

      itemSelected.members?.forEach((item: any) => {
        if(item?.role === "Manager") {
          listManager.push({   
            key: item.user?.id,
            value: item?.user?.name
          })
        }
      })

      setManagerListSelected(listManager)
    }
  }, [itemSelected]);


  return (
    <div className={props.className}>

      <div className="p-2 pb-4" style={{marginBottom: 40}}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div>Tên nhóm</div>
            <Input value={name} onChange={(e) => {setName(e.target.value)}} placeholder="Nhập tên nhóm" />
          </Col>


          <Col span={8}>
            <div>Thành viên</div>
            <SelectBase
              labelInValue
              value={memberListSelected}
              onChange={(items: any) => {
                setMemberListSelected(items)
              }}
              mode="multiple"
              endpoint={"/users"}
              style={{ width: '100%' }}
              placeholder="Thành viên"
              optionRenderer={(option: any) => (
                <div className="d-flex">
                  <img style={{width: 20, height: 20, borderRadius: 100, marginRight: 10}} className="item_page" src={option?.avatar} alt="option" />
                  <p className="item_label_page" style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}> {option.name}</p>
                </div>
              )}
            />
          </Col>

          <Col span={8}>
            <div>Chỉ định Admin</div>

            <SelectBase
              labelInValue
              value={managerListSelected}
              onChange={(items: any) => {
                setManagerListSelected(items)
              }}
              mode="multiple"
              endpoint={"/users?role=Manager"}
              style={{ width: '100%' }}
              placeholder="Chỉ định Admin"
              optionRenderer={(option: any) => (
                <div className="d-flex align-items-center">
                  <img style={{width: 20, height: 20, borderRadius: 100, marginRight: 10}} className="item_page" src={option?.avatar} alt="option" />
                  <div className="item_label_page" style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}> {option.name}</div>
                </div>
              )}
            />
          </Col>
        </Row>
        <div className="mt-2"/>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div>Danh sách page quản lý</div>
            <SelectBase
              labelInValue
              value={channelListSelected}
              onChange={(items: any) => {
                setChannelListSelected(items)
              }}
              mode="multiple"
              endpoint={CHANNELS}
              placeholder="Chọn Page"
              style={{ width: '100%' }}
              optionRenderer={(option: any) => (
                <div className="d-flex align-items-center">
                  <img style={{width: 20, height: 20, borderRadius: 100, marginRight: 10}} className="item_page" src={option?.picture} alt="option" />
                  <div className="item_label_page" style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}> {option.name}</div>
                </div>
              )}
            />
          </Col>
        </Row>
      </div>


      <div className="d-flex justify-content-center pt-3" style={{ position: 'absolute', bottom: 10, left: 0, right: 0, margin: '0 auto', background: '#fff' }}>
        <Button onClick={onSubmit} type="primary">
          Lưu
        </Button>
      </div>
 
    </div>
  );
};

export default styled(FormGroup)`
  width: 100%;
  overflow: hidden;
  min-height: 70vh;
  .title_page {
    margin-bottom: 15px;
    font-weight: 600;
  }
  .list_page {
    display: flex;
    flex-wrap: wrap;
  }
  .channel-item {
 
  }
  .channel-item:hover {
    background-color: ${hexToRgba(localStorage.getItem('colorTheme') || '#2f54eb', 0.3)};
  }
  .channel-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .item_page {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 8px;
  }
  .item_label_page {
    display: grid;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`