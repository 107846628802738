import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { EditOutlined, DeleteOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import { Table, Input, Row, Col, notification, Select, Button, Spin, Space, Modal, Divider } from 'antd';

import styled from "styled-components";
import { getListTeams } from "../../../api/teams";
import { useNavigate } from "react-router-dom";

interface ListTeamProps {
  className?: string;
}

const ListTeam = (props: ListTeamProps) => {
    const navigate = useNavigate();

  const [data, setData] = useState<any>([]);


  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState('');


  const getData = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page,
      limit
    }
    setData([])
    const res: any = await getListTeams(data);
    setLoading(false)
    if (res?.success) {
      setData(res.data.items)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }



  const onSearch = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page: page,
      limit: limit,
      q: q,

    }
    setData([])
    const res: any = await getListTeams(data);
    setLoading(false)
    if (res?.success) {
      setData(res.data.items)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }

  const onSelectTeam = (item: any) => {
    console.log('onSelectTeam', item)
    navigate("/assign-page", { state: { team: item } });
  }

  useEffect(() => {
    getData(1, 50);
  }, []);

  return (
    <div className={props.className}>
      <div className="p-4">
        <div className="filtter">
            <Row gutter={[16, 16]}>
                <Col span={16}>
                <Input allowClear placeholder="Tìm kiếm nhóm" value={q} onChange={(e) => setQ(e.target.value)} />
                </Col>
                <Col span={8}>
                <Button onClick={() => onSearch(1, 10)}>Tìm kiếm</Button>
                </Col>
            </Row>
        </div>
        <Divider/>
        {
            data.map((item: any) => (
                <div key={item.id} onClick={() => onSelectTeam(item)}>
                    <p className="item_team" > {item.name}</p>
                </div>
            ))
        }
       
      </div>
     

    </div>
  );
};

export default styled(ListTeam)`
    .item_team {
        cursor: pointer;
        border: 1px solid #f0f0f0;
        padding: 10px;
        border-radius: 5px;   
    }

`