import React, { useEffect, useState } from "react";
import {
  CalendarOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  TagOutlined
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
} from 'antd';
import dayjs from 'dayjs';

// styles
import styled from "styled-components";
import { getTagListAll } from "../../../api";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { vi } from "date-fns/locale";
import { addDays, endOfDay, startOfDay } from "date-fns";
import { ConversationFilterState } from "../../../redux/chats/types";

interface ChatUserProps {
  className?: string;
  channelSelected: string;
  setItemTagSelected: any
  itemTagSelected: any,
  filter: any,
  onChangeFilterState: (filterState: ConversationFilterState) => void;
}
const FilterConversation = ({ className, setItemTagSelected,  onChangeFilterState, filter }: ChatUserProps) => {
  return (
    <div className={className}>
      <div className="d-flex ">
        <button
          onClick={() => {
            onChangeFilterState({
              isSpam: !filter?.isSpam,
              isRead: true,
            })
            setItemTagSelected(null)
          }}
          className="mx-1 d-flex justify-content-center align-items-center text"
          style={filter?.isSpam ? { background: '#C3DCF5', color: '#0A78BE', fontWeight: 600 } : {}}>
          <InfoCircleOutlined style={{ fontSize: 10 }} /> <span className="mx-2 ">Spam</span>
        </button>
      </div>
    </div>
  );
};

export default styled(FilterConversation)`
  margin-bottom: 10px;
  margin-left: 3px;
  button {
    border: none;
    border-radius: 4px;
    background-color: #F8F9FB;
    &:hover { 
      background-color: #F0F2F5;
    }
  }
  .btn_filter {
    cursor: pointer;
    border-radius: 4px;
    &:hover { 
      background-color: #F0F2F5;
    }
  }
  .text {
    font-weight: 300;
    font-size: 13px;
  }
  .icon_close {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
`
