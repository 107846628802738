import React, { useEffect, useState, useCallback } from "react";

import classnames from "classnames";

// hooks
import {
  onSyncMessage
} from "../../api/index";

// router
import { useSearchParams } from "react-router-dom";

// component
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Tooltip, Modal, Input } from 'antd';

import {
  Button,
} from "reactstrap";
import { toast } from "react-toastify";

interface IndexProps { 

}

const Index = (props: IndexProps) => {
  const { confirm } = Modal;
  const [searchParams] = useSearchParams();
  const channelId = searchParams.get('channelId');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSync = async () => {
    setLoading(true);
    try {
      const response: any = await onSyncMessage({channelId: channelId});
      if (response?.data && response?.success) {
        toast.success('Đồng bộ tin nhắn thành công');
        setIsOpenModal(false);
      } else {
        setIsOpenModal(false);
        toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
 

  return (
    <div
      className={classnames("user-chat", "w-100", "overflow-hidden")}
      id="user-chat"
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div className="chat-content p-3 p-lg-4 w-100 h-100">
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 150, flexDirection: 'column'  }}>
          <div style={{textAlign: 'center'}}>Bạn có thể động bộ tin nhắn ngay lập tức, các tin nhắn của khách sẽ được đồng về danh sách các cuộc hội thoại</div>
          <Button
            style={{ width: 200, margin: 'auto', marginTop: 20 }}
            color="primary"
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Đồng bộ tin nhắn
          </Button>
        </div>
        <Modal
          title="Bạn có muốn đồng bộ không?"
          open={isOpenModal}
          onOk={onSync}
          confirmLoading={loading}
          onCancel={() => {
            console.log('Hủy đồng bộ');
            setIsOpenModal(false);
          }}
          okText="Đồng ý"
          cancelText="Hủy"
        >
        </Modal>
      </div>
    </div>
  );
};

export default Index;