import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";

// actions
import {
    getPageList,
    connectPage
} from "../api/index";

// component
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip } from 'antd';
import { CloseOutlined, CheckSquareTwoTone, LoadingOutlined } from '@ant-design/icons';
import {
    Checkbox,
    Spin
} from "antd";

//images
import iconFb from "../assets/images/users/ic_fb.png";

// styles
import styled from "styled-components";
import { hexToRgba } from "../utils/color";

interface ConnectModalProps {
    isOpen: boolean;
    onClose: () => void;
    onGet: () => void;
    className?: string;
}

const ConnectModal = ({ isOpen, onClose, onGet, className }: ConnectModalProps) => {
    const [pageList, setPageList] = useState<any>(null);
    const [idsSelected, setIdsSelected] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        if (pageList === null) {
          getPageListData({ limit: 20 });
        }
    }, []);

    const getPageListData = async (param: any) => {
        try {
            setIsLoading(true);
            const response: any = await getPageList(param);
            if (response?.data && response?.success) {
              const arr = param?.paging ? [...pageList?.items, ...response?.data?.data] : response?.data?.data;
              setPageList({
                paging: response?.data?.paging,
                items: arr,
              });
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleConnect = async (ids: string[], isAll: boolean) => {
        try {
            setIsLoading(true);
            const response: any = await connectPage({
                isAll: isAll,
                type: "FB_PAGE",
                channels: pageList?.items?.filter((item: any) => ids.includes(item.id)).map((item: any) => ({
                    tasks: item?.tasks,
                    refId: item?.id,
                    name: item?.name,
                    accessToken: item?.access_token,
                    picture: item?.picture,
                })),
            });
            if (response?.data && response?.success) {
                toast.success('Thành công');
                onClose();
                onGet();
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
        }
    };

    const fetchMoreData = () => {
        if (!pageList?.paging?.next) {
            setHasMore(false);
            return;
        }
        setTimeout(async () => {
            try {
                const response: any = await getPageList({
                    limit: 20,
                    after: pageList?.paging?.cursors?.after,
                });
                if (response?.data && response?.success) {
                  const arr = [...pageList?.items, ...response?.data?.data];
                  setPageList({
                    paging: response?.data?.paging,
                    items: arr,
                  });
                } else {
                    toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
                }
            } catch (error) {
                console.log("error", error);
            }
        }, 300);
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            tabIndex={-1}
            centered
            className="audiocallModal"
            contentClassName="shadow-lg border-0"
        >
            <ModalBody className="p-0">
                <Spin spinning={isLoading}>
                    <div className={`${className} text-center p-4 pb-0`}>
                        <div
                            className="text-center"
                            style={{
                                marginBottom: 16,
                                position: 'relative',
                            }}>
                            <h4 className="modal-title">Kết nối</h4>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 4,
                                    right: 0,
                                    cursor: 'pointer',
                                }}
                                onClick={onClose}
                            >
                                <CloseOutlined style={{ fontSize: 16 }} />
                            </div>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <div className="d-flex justify-content-end">
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Checkbox
                                        checked={idsSelected.length > 0 && pageList?.items?.length > 0 && idsSelected.length === pageList?.items?.length}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setIdsSelected(pageList?.items?.map((item: any) => item?.id));
                                            } else {
                                                setIdsSelected([]);
                                            }
                                        }}
                                        style={{ marginRight: 5 }}
                                    />
                                    <span style={{ fontWeight: '500' }}>Chọn tất cả</span>
                                </div>
                            </div>
                        </div>
                        <InfiniteScroll
                            dataLength={pageList?.items?.length || 0}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={pageList?.items?.length > 0 &&
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 30
                                }}>
                                    <Spin indicator={<LoadingOutlined spin />} />
                                </div>
                            }
                            height={'calc(100vh - 250px)'}
                        >
                            {
                                pageList?.items?.length > 0 && pageList?.items?.map((item: any, index: number) => (
                                    <div
                                        key={index}
                                        className="list-inline-item d-none d-lg-inline-block me-2 ms-0"
                                        style={{ marginBottom: 16 }}
                                    >
                                        <div
                                            onClick={() => {
                                                setIdsSelected(
                                                    idsSelected.includes(item?.id)
                                                        ? idsSelected.filter((id) => id !== item?.id)
                                                        : [...idsSelected, item?.id]
                                                );
                                            }}
                                            className="channel-item"
                                        >
                                            <img
                                                src={item?.picture}
                                                alt=""
                                                style={{ width: 64, height: 64, marginRight: 8, borderRadius: 5 }}
                                            />
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start'
                                            }}>
                                                <Tooltip title={item?.name}>
                                                    <div style={{
                                                        width: 300,
                                                        fontWeight: 'bold',
                                                        marginBottom: 4,
                                                        marginRight: 20,
                                                        textAlign: 'left',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis'
                                                    }}>{item?.name}</div>
                                                </Tooltip>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img
                                                        src={iconFb}
                                                        alt=""
                                                        style={{ width: 14, height: 14, marginRight: 4 }}
                                                    />
                                                    <span style={{ fontSize: 12 }}>{item?.id}</span>
                                                </div>
                                            </div>
                                            {
                                                idsSelected.includes(item?.id) ? (
                                                    <div style={
                                                        {
                                                            position: 'absolute',
                                                            right: 8,
                                                            top: 8
                                                        }
                                                    }>
                                                        <CheckSquareTwoTone twoToneColor={localStorage.getItem('colorTheme') || ''} style={{ fontSize: 20 }} />
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </InfiniteScroll>
                        <div className="my-4">
                            <Button
                                type="button"
                                className='btn bg-primary rounded'
                                onClick={() => {
                                    if (idsSelected.length > 0) {
                                        handleConnect(idsSelected, idsSelected.length === pageList?.items?.length)
                                    }
                                }}
                                style={{
                                    opacity: idsSelected.length > 0 ? 1 : 0.5,
                                    border : 'none'
                                }}
                            >
                                <span className="bg-transparent font-size-18">
                                    Kết nối
                                </span>
                            </Button>
                        </div>
                    </div>
                </Spin>
            </ModalBody>
        </Modal>
    );
};

export default styled(ConnectModal)`
  .channel-item {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    min-width: 400px;
  }
  .channel-item:hover {
    background-color: ${hexToRgba(localStorage.getItem('colorTheme') || '#2f54eb', 0.3)};
  }
`
