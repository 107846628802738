import moment from "moment";
import React, { useEffect, useState } from "react";

import classnames from "classnames";
import { createSelector } from "reselect";
import { useRedux } from "../../../../hooks/index";

// components
import LightBox from "../../../../components/LightBox";

//images
import imagePlaceholder from "../../../../assets/images/users/user-dummy-img.jpg";
import MessageTranslate from "../MessageTranslate";

// utils
import { formateDate } from "../../../../utils";

// interface
import {
  ImageTypes,
  MessagesTypes,
} from "../../../../data/messages";

// styles
import styled from "styled-components";

interface ImageProps {
  message: MessagesTypes;
  image: any;
  onImageClick: (id: number) => void;
  index: number;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  onDeleteImg: (imageId: string | number) => void;
}
const Image = ({ message, image, onImageClick, index, onSetReplyData, onDeleteImg }: ImageProps) => {

  return (
    <React.Fragment>
      <div className="message-img-list">
        <div>
          <a
            className="popup-img d-inline-block"
            onClick={() => onImageClick(index)}
          >
            <img src={image.url} alt="" className="rounded border" style={{ cursor: 'zoom-in' }} />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};
interface ImagesProps {
  message: MessagesTypes,
  images: ImageTypes[];
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  onDeleteImg: (imageId: string | number) => void;
}
const Images = ({ message, images, onSetReplyData, onDeleteImg }: ImagesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  
  const onImageClick = (id: number) => {
    setSelected(id);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="message-img mb-0">
        {(images || []).map((image: ImageTypes, key: number) => (
          <Image
            message={message}
            image={image}
            key={key}
            index={key}
            onImageClick={onImageClick}
            onSetReplyData={onSetReplyData}
            onDeleteImg={onDeleteImg}
          />
        ))}
      </div>
      {isOpen && (
        <LightBox
          isOpen={isOpen}
          images={images}
          onClose={onClose}
          defaultIdx={selected}
        />
      )}
    </>
  );
};

interface AttachmentsProps {
  attachments: any;
}
const Attachments = ({ attachments }: AttachmentsProps) => {
  return (
    <>
      {(attachments || []).map((attachment: any, key: number) => (
        <div
          key={key}
          className={classnames("p-3", "border-primary", "border rounded-3", {
            "mt-2": key !== 0,
          })}
          style={{
            maxWidth: 400,
          }}
        >
          <div className="d-flex align-items-center attached-file">
            <div className="flex-shrink-0 avatar-sm me-3 ms-0 attached-file-avatar">
              <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-20">
                <i className="ri-attachment-2"></i>
              </div>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <div className="text-start">
                <h5 className="font-size-14 mb-1" style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>{attachment.url.split('/')[attachment.url.split('/')?.length - 1]?.split('?')[0]}</h5>
                {/* <p className="text-muted text-truncate font-size-13 mb-0">
                  {attachment.desc}
                </p> */}
              </div>
            </div>
            <div className="flex-shrink-0 ms-4">
              <div className="d-flex gap-2 font-size-20 d-flex align-items-start">
                <div>
                  <a
                    href={attachment.url ? attachment.url : "#"}
                    className="text-muted"
                    download
                  >
                    <i className="bx bxs-download"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

interface MessageProps {
  className?: string;
  message: any;
  selectedConversation: any;
  isFromMe: boolean;
}
const Message = ({
  className,
  message,
  selectedConversation,
  isFromMe,
}: MessageProps) => {
  const { useAppSelector } = useRedux();

  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      messagReadedInconverstaion: state.messagReadedInconverstaion,
    })
  );
  const { messagReadedInconverstaion } = useAppSelector(errorData);

  const chatUserprofile = selectedConversation?.from?.avatar
    ? selectedConversation?.from?.avatar
    : imagePlaceholder;
  const profile = chatUserprofile;
  const date = message.createdAt ? formateDate(message.createdAt, "HH:mm") : '';
  const [emoji, setEmoji] = useState(message?.reaction?.emoji || '');
  const [showOptionMess, setShowOptionMess] = useState(false);

  useEffect(() => {
    setEmoji(message?.reaction?.emoji || '');
  }, [message?.reaction?.emoji])

  return (
    <li
      className={classnames(
        className,
        "chat-list",
        { right: isFromMe },
      )}
    >
      <div
        className={classnames(
          "conversation-list",
          { right: isFromMe },
        )}
        onMouseEnter={() => setShowOptionMess(true)}
        onMouseLeave={() => setShowOptionMess(false)}
        style={{ flex: 1 }}
      >

        {
          isFromMe ? null : (
            <div className="chat-avatar">
              <img src={profile} alt="" />
            </div>
          )
        }
        {
          (isFromMe && showOptionMess) && (
            <div className="" style={{ marginRight: 20 }}>
              <div style={{ fontSize: 11, fontWeight: '500', color: '#495057bf' }}>{isFromMe ? message?.createdBy?.name : message?.from?.name}</div>
              {
                message?.createdAt ? (

                  <div style={{ fontSize: 11, color: '#495057bf' }}>
                    {
                      moment(message.createdAt).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY') ?
                        'hôm nay' :
                        moment(message.createdAt).format('DD/MM/YYYY') == moment().subtract(1, 'days').format('DD/MM/YYYY') ?
                          'hôm qua' :
                          moment(message.createdAt).format('DD/MM/YYYY')
                    }
                  </div>
                ) : null
              }
            </div>
          )
        }
        <div className="ctext-wrap">
          <div className="ctext-wrap-content">

            {message.text && (
              message?.messageTags?.find((cmt: any) => message.text.includes(cmt?.name)) ? (
                <p className="mb-0 ctext-content" style={isFromMe ? { textAlign: 'left' } : {}}>
                  <span style={{
                    backgroundColor: '#cfdde6',
                    borderRadius: 4,
                    padding: '2px 5px'
                  }}>
                    {
                      message?.messageTags?.find((cmt: any) => message.text.includes(cmt?.name))?.name
                    }
                  </span>
                  {message.text?.replace(message?.messageTags?.find((cmt: any) => message.text.includes(cmt?.name))?.name, '')}
                </p>
              ) : (
                <p className="mb-0 ctext-content" style={isFromMe ? { textAlign: 'left' } : {}}>{message.text}</p>
              )
            )}
            {
              message?.attachments && message?.attachments?.length > 0 && message?.attachments?.find((item: any) => item?.type && item?.url) ? (
                message.attachments[0]?.type.includes('image') ? (
                  <Images images={message.attachments} message={message} onSetReplyData={() => { }} onDeleteImg={() => { }} />
                ) : message.attachments[0]?.type.includes('video') ? (
                  <video
                    src={message.attachments[0]?.url}
                    width="320"
                    height="240"
                    controls
                    style={{
                      backgroundColor: '#000',
                      borderRadius: 8,
                    }}
                  />
                ) : message.attachments[0]?.type.includes('audio') ? (
                  <audio
                    src={message.attachments[0]?.url}
                    controls
                    style={{
                      borderRadius: 8,
                    }}
                  />
                ) : (
                  <Attachments attachments={message.attachments} />
                )
              ) : null
            }
            <MessageTranslate
              message={message}
              isFromMe={isFromMe}
            />
            <div className="time-action">
              {isFromMe ? (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}>
                  <div className="time-info" style={{ marginLeft: 4 }}>
                    {date}
                  </div>
                </div>
              ) : (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}>
                  <div className="time-info" style={{ marginRight: 4 }}>
                    {date}
                  </div>
                </div>
              )}
            </div>
            {
              emoji && <div className="reacted">{emoji}</div>
            }
            {
              isFromMe && messagReadedInconverstaion?.messageId == message?.messageId ? (
                <div className="user-read">
                  <img src={profile} alt="" />
                </div>
              ) : null
            }

          </div>
        </div>
        {
          (!isFromMe && showOptionMess) && (
            <div
              className="btn-option-mes">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column">
                  {
                    message?.createdAt ? (

                      <div style={{ fontSize: 11, color: '#495057bf' }}>
                        {
                          moment(message.createdAt).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY') ?
                            'hôm nay' :
                            moment(message.createdAt).format('DD/MM/YYYY') == moment().subtract(1, 'days').format('DD/MM/YYYY') ?
                              'hôm qua' :
                              moment(message.createdAt).format('DD/MM/YYYY')
                        }
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
          )
        }
      </div>
    </li>
  );
};

export default styled(Message)`
  .time-action {
    margin-top: 4px;
  }
  .cmt-action {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1px;
    border-radius: 2px;
    &:hover {
      border: 1px solid gray;
    }
  }
  .time-info {
    color: #495057bf;
    font-size: 11px;
    font-weight: 500;
  }
  .send_to {
    color: #de343b;
    font-weight: 400;
    padding: 2px 8px;
    border: 1px solid rosybrown;
    border-radius: 2px;
    background-color: floralwhite;
    cursor: pointer;
  }
  .ant-modal-content {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
}
`
