import { Col } from "antd";

interface ColumnProps {
    children: any;
    title: string
}

const Column = (props: ColumnProps) => {
    return (
        <Col
            span={6}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
            <div style={{ fontWeight: 700, lineHeight: 2.4 }}>{props.title}</div>
            {props.children}
        </Col>
    );
};

export default Column;