import React, { useState, useEffect } from "react";
// hooks
import { Form, Input, Row, Col, InputNumber } from 'antd';
// apis
import styled from "styled-components";
import { MinusCircleOutlined, DeleteOutlined} from '@ant-design/icons';
import formatNumber from "../../../../utils/formatNumber";


interface IndexProps {
    field: any;
    className: string;
    remove: any;
    form: any;
}


const ItemProduct = (props: IndexProps) => {
    const [isHover, setIsHover] = useState(false);
    const {field, remove, form} = props;
    const [money, setMoney] = useState(0)
    const [qty, setQty] = useState(0)
    const [price, setPrice] = useState(0)

    const calculation = (qty: any, price: any) => {
        setMoney(qty * price)
    }

  return (
    <div key={field.key} className={props.className}
        onMouseEnter={e => { setIsHover(true); }}
        onMouseLeave={e => { setIsHover(false); }}
    >
        <Row gutter={5}>
            <div className="btn_delete" 
                style={{display: isHover ? "block" : "none"}}
            >
                <DeleteOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                />
            </div>
            <Col span={5}>
                <Form.Item rules={[{ required: true, message: 'Please input!' }]}
                     name={[field.name, 'productCode']} >
                    <Input defaultValue={""} placeholder="Mã sản phẩm" />
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item rules={[{ required: true, message: 'Please input!' }]}
                     name={[field.name, 'productId']} >
                    <Input defaultValue={""} placeholder="Tên sản phẩm" />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item rules={[{type: "number", required: true, message: 'Please input!' }]} name={[field.name, 'qty']} >
                    <InputNumber 
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                        onChange={(e) => {calculation(e, price); setQty(Number(e))}} 
                        style={{width: '100%'}} min={1} placeholder="0" 
                    />
                </Form.Item>
            </Col>
            <Col span={5}>
                <Form.Item rules={[{type: "number", required: true, message: 'Please input!' }]} name={[field.name, 'price']} >
                    <InputNumber 
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                        onChange={(e) => {calculation(qty, e); setPrice(Number(e))}} 
                        style={{width: '100%'}} min={1} placeholder="0" />
                </Form.Item>
            </Col>
            <Col span={5}>
                <div className="money">
                    {formatNumber(money)}
                </div>
            </Col>
        </Row>
    </div>
  );
};



export default styled(ItemProduct)`
    margin: 0px 5px;
    .title {
        font-weight: 600;
        margin-bottom: 10px;
		font-size: 15px;
    }
    .btn_delete {
        position: absolute;
        background-color: #fff;
        left: 3px;
        z-index: 1000;
        border: 1px solid #D9DBEA;
        border-radius: 4px;
        padding: 3px;
    }
    .money {
        text-align: right;
        margin: 0 auto;
        font-weight: 600;
    }
    .ant-form-item {
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 1400px) {
        input {
            font-size: 10px !important;
        }
    }
`