import React, { useEffect, useState } from "react";
import type { FormInstance } from 'antd';
import { Button, Form, Input, Space, InputNumber, ColorPicker, Modal, message } from 'antd';
import {
    addTopic,
    updateTopicAll,
} from "../../../api/index";
import { DownOutlined } from '@ant-design/icons';

import { toast } from "react-toastify";

interface SubmitButtonProps {
    form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
    const [submittable, setSubmittable] = React.useState<boolean>(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [form, values]);

    return (
        <Button type="primary" htmlType="submit" disabled={!submittable}>
            {children}
        </Button>
    );
};

const colors = [
    '#fa8c16',
    '#13c2c2',
    '#1890ff',
    '#597ef7',
    '#9254de',
    '#ffc53d',
    '#1cae67',
];

interface AddTopicModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    channelSelected: string;
    topicLength: number;
    topicSelected: any;
    addMultiple?: boolean;
    topicList?: any;
}

const AddTopicModal = ({ isOpen, onClose, onSuccess, channelSelected, topicLength, topicSelected, addMultiple, topicList }: AddTopicModalProps) => {
    const [colorSelected, setColorSelected] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} bắt buộc!',
    };

    useEffect(() => {
        if (topicSelected) {
            form.setFieldsValue({
                orderNumber: topicSelected.sequence,
                name: topicSelected.name,
                color: topicSelected.color,
            });
            setColorSelected(topicSelected.color);
        } else {
            const color = colors[Math.floor(Math.random() * colors.length)];
            form.setFieldsValue({
                orderNumber: addMultiple ? 0 : topicLength + 1,
                name: addMultiple ? '' : 'Chủ đề ' + (topicLength + 1),
                color: color,
            });
            setColorSelected(color);
        }
    }, [topicLength]);

    const onFinish = async (values: any) => {
        try {
            let response: any;
            if (addMultiple) {
                // response = await addMultiTag({
                //     name: values.name,
                //     color: values.color,
                //     sequence: values.orderNumber,
                //     channelIds: channelSelected.split(',').map((item: string) => item),
                // });
            } else {
                response = await addTopic(channelSelected, {
                    name: values.name,
                    color: values.color,
                    sequence: values.orderNumber,
                });
            }
            if (response?.data && response?.success) {
                onSuccess();
                toast.success('Thêm mới chủ đề thành công');
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const onSave = async (values: any) => {
        try {
            const tagIndex = values.orderNumber <= topicLength ? values.orderNumber : topicLength;
            const arr = topicList.filter((i: any) => i?.id !== topicSelected?.id);
            arr?.splice(
                tagIndex,
                0,
                {
                    ...topicSelected,
                    name: values.name,
                    color: values.color,
                    sequence: tagIndex,
                }
            );
            const response: any = await updateTopicAll(channelSelected, {
                items: arr.map((item: any, key: number) => ({...item, sequence: key}))
            });
            if (response?.data && response?.success) {
                message.success('Cài đặt đã được cập nhật')
                onSuccess();
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <Modal
            open={isOpen}
            footer={null}
            onCancel={onClose}
        >
            <div className="p-0">
                <div className="p-4 pb-0">
                    <div className="text-center mb-2">
                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                            {
                                topicSelected ? 'Chỉnh sửa chủ đề' : 'Thêm mới chủ đề'
                            }
                        </span>
                    </div>
                    <Form
                        form={form}
                        name="add-tag"
                        layout="vertical"
                        autoComplete="off"
                        onFinish={topicSelected ? onSave : onFinish}
                        validateMessages={validateMessages}
                    >
                        <Form.Item name="orderNumber" label="Số thứ tự" rules={[{ required: true }]}>
                            <InputNumber min={1} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name="name" label="Tên chủ đề" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="color" label="Bộ chọn màu" className="ant-color">
                            <ColorPicker
                                value={colorSelected}
                                onChange={(color, hex) => {
                                    form.setFieldsValue({ color: hex });
                                    setColorSelected(hex);
                                }}
                                open={open}
                                onOpenChange={setOpen}
                                showText={() => (
                                    <DownOutlined
                                        rotate={open ? 180 : 0}
                                        style={{
                                            color: 'rgba(0, 0, 0, 0.25)',
                                        }}
                                    />
                                )}
                            />
                            {
                                colors.map((color, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            padding: '2px',
                                            border: form.getFieldValue('color') == color ? '1.5px solid green' : '1px solid #fff',
                                            display: 'inline-block',
                                            borderRadius: 4,
                                            marginLeft: 5,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            form.setFieldsValue({ color: color });
                                            setColorSelected(color);
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: 'block',
                                                width: 24,
                                                height: 24,
                                                backgroundColor: color,
                                                borderRadius: 4,
                                            }}
                                        />
                                    </div>
                                ))
                            }
                        </Form.Item>
                        <Form.Item label="Màu chủ đề sẽ được hiển thị">
                            <div
                                style={{
                                    display: 'inline-block',
                                    backgroundColor: form.getFieldValue('color'),
                                    borderRadius: 4,
                                    padding: '0 28px',
                                    color: '#fff',
                                    fontWeight: '500',
                                    height: 24,
                                    lineHeight: '24px',
                                }}
                            >
                                {form.getFieldValue('name')}
                            </div>
                        </Form.Item>
                        <Form.Item className="text-center">
                            <Space>
                                <Button
                                    onClick={onClose}
                                >Đóng</Button>
                                <SubmitButton form={form}>
                                    {
                                        topicSelected ? 'Lưu' : 'Thêm mới'
                                    }
                                </SubmitButton>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default AddTopicModal;
