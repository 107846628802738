import React, { useState } from "react";
import { Dropdown } from 'antd';
import {
    CheckOutlined,
    DownOutlined,
    UserOutlined,
    BookOutlined,
    UnorderedListOutlined,
    UsergroupAddOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';
import { on } from "events";

const options = [
    {
        key: '1',
        name: 'Hôm nay',
    },
    {
        key: '2',
        name: 'Ngày mai',
    },
    {
        key: '3',
        name: 'Hôm qua',
    },
];

const optionsStyle = [
    {
        key: '1',
        name: 'DD/MM/YYYY',
    },
    {
        key: '2',
        name: 'MM/DD/YYYY',
    },
    {
        key: '3',
        name: 'Date-Month name-Year',
    },
];

interface InsertInfoProps {
    onInsert: (value: string) => void;
}

const InsertInfo = ({ onInsert }: InsertInfoProps) => {
    const [colorSelected, setColorSelected] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [option, setOption] = useState<string>('1');
    const [optionStyle, setOptionStyle] = useState<string>('1');

    const onChange = (value: string) => () => {
        setOption(value);
    }

    const onChangeStyle = (value: string) => () => {
        setOptionStyle(value);
    }

    const renderValue = () => {
        return `#{${renderOption()}(${renderOptionStyle()})}`;
    }

    const renderOption = () => {
        switch (option) {
            case '1':
                return 'TODAY';
            case '2':
                return 'TOMORROW';
            case '3':
                return 'YESTERDAY';
            default:
                return '';
        }
    }

    const renderOptionStyle = () => {
        switch (optionStyle) {
            case '1':
                return 'DD/MM/YYYY';
            case '2':
                return 'MM/DD/YYYY';
            case '3':
                return 'LL';
            default:
                return '';
        }
    }

    return (
        <>
            <div style={{
                marginBottom: 24,
                marginLeft: -24,
                marginRight: -24,
                borderBottom: '1px solid #d0d0d0',
            }}>
                <span className="username" style={{ color: localStorage.getItem('colorTheme') || '#2f54eb', paddingLeft: 16 }}>Chèn thông tin</span>
                <div style={{
                    marginTop: 16,
                    width: 120,
                    height: 2,
                    backgroundColor: localStorage.getItem('colorTheme') || '#2f54eb',
                }} />
            </div>
            <div style={{
                borderBottom: '1px solid #d0d0d0',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 16,
                }}>
                    <UserOutlined style={{ fontSize: 15 }} />
                    <span style={{ marginLeft: 10, fontWeight: '500' }}>Khách hàng</span>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Tên đầy đủ của khách hàng</div>
                    <div
                    onClick={() => onInsert('#{FULL_NAME}')}
                     style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{FULL_NAME}'}</span>
                    </div>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Tên của khách hàng</div>
                    <div
                    onClick={
                        () => onInsert('#{FIRST_NAME}')
                    
                    } 
                    style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{FIRST_NAME}'}</span>
                    </div>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Họ của khách hàng</div>
                    <div 
                    onClick={
                        () => onInsert('#{LAST_NAME}')
                    }
                    style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{LAST_NAME}'}</span>
                    </div>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Giới tính của khách hàng</div>
                    <div 
                    onClick={
                        () => onInsert('#SEX{MALE | FEMALE | UNKNOWN}')
                    }
                    style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#SEX{MALE | FEMALE | UNKNOWN}'}</span>
                    </div>
                </div>
            </div>
            <div style={{
                borderBottom: '1px solid #d0d0d0',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '16px 0 16px 0',
                }}>
                    <BookOutlined style={{ fontSize: 15 }} />
                    <span style={{ marginLeft: 10, fontWeight: '500' }}>Trang</span>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Tên trang</div>
                    <div 
                    onClick={
                        () => onInsert('#{PAGE_NAME}')
                    }
                    style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{PAGE_NAME}'}</span>
                    </div>
                </div>
            </div>
            <div style={{
                borderBottom: '1px solid #d0d0d0',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '16px 0 16px 0',
                }}>
                    <UnorderedListOutlined style={{ fontSize: 15 }} />
                    <span style={{ marginLeft: 10, fontWeight: '500' }}>Lựa chọn</span>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Lựa chọn ngẫu nhiên giữa các từ</div>
                    <div
                    onClick={
                        () => onInsert('#{SPIN_1 | SPIN_2}')
                    }
                     style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{SPIN_1 | SPIN_2}'}</span>
                    </div>
                </div>
            </div>
            <div style={{
                borderBottom: '1px solid #d0d0d0',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '16px 0 16px 0',
                }}>
                    <UsergroupAddOutlined style={{ fontSize: 15 }} />
                    <span style={{ marginLeft: 10, fontWeight: '500' }}>Nhân viên</span>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Tên đầy đủ của nhân viên trả lời</div>
                    <div
                    onClick={
                        () => onInsert('#{STAFF_NAME}')
                    }
                     style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{STAFF_NAME}'}</span>
                    </div>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Tên của nhân viên trả lời</div>
                    <div 
                    onClick={
                        () => onInsert('#{STAFF_FIRST_NAME}')
                    }
                    style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{STAFF_FIRST_NAME}'}</span>
                    </div>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Họ của nhân viên trả lời</div>
                    <div 
                    onClick={
                        () => onInsert('#{STAFF_LAST_NAME}')
                    }
                    style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{STAFF_LAST_NAME}'}</span>
                    </div>
                </div>
                <div style={{
                    marginBottom: 16,
                }}>
                    <div>Thông tin chi tiết nhân viên trả lời</div>
                    <div
                    onClick={
                        () => onInsert('#{STAFF_DETAILS}')
                    }
                     style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{'#{STAFF_DETAILS}'}</span>
                    </div>
                </div>
            </div>
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '16px 0 16px 0',
                }}>
                    <ClockCircleOutlined style={{ fontSize: 15 }} />
                    <span style={{ marginLeft: 10, fontWeight: '500' }}>Chọn thời gian</span>
                </div>
                <div style={{
                    display: 'flex',
                }}>
                    <div style={{
                        marginRight: 20,
                    }}>
                        <div style={{marginBottom: 5}}>Chọn ngày:</div>
                        <Dropdown menu={{
                            items: options.map((item: any) => ({
                                key: item.key,
                                label: (
                                    <div
                                        style={{
                                            padding: '4px 12px',
                                            backgroundColor: item.key === option ? '#f0f0f0' : 'transparent',
                                            borderRadius: 6,
                                        }}
                                        onClick={onChange(item.key)}
                                    >
                                        <span>{item.name}</span>
                                        {
                                            item.key === option && (
                                                <CheckOutlined
                                                    style={{
                                                        color: 'blue',
                                                        marginLeft: 16,
                                                        fontSize: 16,
                                                    }}
                                                />
                                            )
                                        }
                                    </div>
                                ),
                            })),
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                padding: '4px 12px',
                                border: '1px solid #d0d0d0',
                                borderRadius: 6,
                            }}>
                                <span>
                                    {
                                        options.find((item: any) => item.key === option)?.name
                                    }
                                </span>
                            </div>
                        </Dropdown>
                    </div>
                    <div style={{
                        marginRight: 20,
                    }}>
                        <div style={{marginBottom: 5}}>Chọn định dạng:</div>
                        <Dropdown menu={{
                            items: optionsStyle.map((item: any) => ({
                                key: item.key,
                                label: (
                                    <div
                                        style={{
                                            padding: '4px 12px',
                                            backgroundColor: item.key === optionStyle ? '#f0f0f0' : 'transparent',
                                            borderRadius: 6,
                                        }}
                                        onClick={onChangeStyle(item.key)}
                                    >
                                        <span>{item.name}</span>
                                        {
                                            item.key === optionStyle && (
                                                <CheckOutlined
                                                    style={{
                                                        color: 'blue',
                                                        marginLeft: 16,
                                                        fontSize: 16,
                                                    }}
                                                />
                                            )
                                        }
                                    </div>
                                ),
                            })),
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                padding: '4px 12px',
                                border: '1px solid #d0d0d0',
                                borderRadius: 6,
                            }}>
                                <span>
                                    {
                                        optionsStyle.find((item: any) => item.key === optionStyle)?.name
                                    }
                                </span>
                            </div>
                        </Dropdown>
                    </div>
                </div>
                <div style={{
                    marginTop: 10,
                }}>
                    <div>Chọn định dạng</div>
                    <div
                    onClick={
                        () => onInsert(renderValue())
                    }
                     style={{
                        display: 'inline-block',
                        backgroundColor: '#F2F4F7',
                        borderRadius: 6,
                        padding: '5px 8px',
                        cursor: 'pointer',
                    }}>
                        <span style={{ fontWeight: '500' }}>{renderValue()}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InsertInfo;
