import React, { useEffect, useState } from "react";
// components
// hooks
import { useRedux } from "../../../../hooks/index";
import { createSelector } from "reselect";
import {
    CloseCircleOutlined,
  } from '@ant-design/icons';
// styles
import styled from "styled-components";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

interface IndexProps {
  className?: string;
  onChange?: any
}
const Emoji = ({
  className,
  onChange,
}: IndexProps) => {
    return (
        <div style={{
          }}>
            <Picker data={data} 
                onEmojiSelect={(emoji: any) => {
                  console.log("emoji", emoji)
                    onChange(emoji.native)
                }} 
                navPosition={"bottom"}
                previewPosition={"none"}
            />

        </div>
  );
};

export default styled(Emoji)`
    
`
