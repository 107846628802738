import React, { useState } from "react";
import { Table, Checkbox, Space, Menu, Dropdown, Button, Tooltip } from 'antd';
import styled from "styled-components";
import moment from "moment";

interface IndexProps {
    data: any[];
    visibleColumns: any;
    className?: string;
}

const InteractByStaff = (props: IndexProps) => {
    const { data, visibleColumns, className } = props;
    const getTotal = (key: string) => {
        return data.length > 0 && data.reduce((total: number, item: any) => total + (item[key] || 0), 0);
    };
    const getSummaryData = () => {
        const totalCustomerInteractions = getTotal('oldCustomerCount') + getTotal('newCustomerCount');
        const totalOrders = getTotal('orderNewCustomerCount') + getTotal('orderOldCustomerCount');
        const totalOrderConversionRate = totalCustomerInteractions
            ? ((totalOrders / totalCustomerInteractions) * 100).toFixed(0) + "%"
            : "0%";
        const newCustomerConversionRate = totalCustomerInteractions
            ? ((getTotal('orderNewCustomerCount') / getTotal('newCustomerCount')) * 100).toFixed(0) + "%"
            : "0%";
        const oldCustomerConversionRate = totalCustomerInteractions
            ? ((getTotal('orderOldCustomerCount') / getTotal('oldCustomerCount')) * 100).toFixed(0) + "%"
            : "0%";
        return {
            oldCustomerCount: getTotal('oldCustomerCount'),
            newCustomerCount: getTotal('newCustomerCount'),
            totalCustomerCount: getTotal('oldCustomerCount') + getTotal('newCustomerCount'),
            customerInboxCount: getTotal('customerInboxCount'),
            customerCommentCount: getTotal('customerCommentCount'),
            newConversationCount: getTotal('newConversationCount'),
            orderNewCustomerCount: getTotal('orderNewCustomerCount'),
            orderOldCustomerCount: getTotal('orderOldCustomerCount'),
            totalOrderCount: getTotal('orderNewCustomerCount') + getTotal('orderOldCustomerCount'),
            totalOrderConversionRate: totalOrderConversionRate,
            newCustomerConversionRate: newCustomerConversionRate,
            oldCustomerConversionRate: oldCustomerConversionRate,
        };
    };


    const calculateConversionRate = (row: any) => {
        const totalCustomerInteractions = row.oldCustomerCount + row.newCustomerCount;
        const totalOrders = row.orderNewCustomerCount + row.orderOldCustomerCount;
        return totalCustomerInteractions
            ? ((totalOrders / totalCustomerInteractions) * 100).toFixed(0) + "%"
            : "0%";
    };

    const calculateNewCustomerConversionRate = (row: any) => {
        const newCustomerOrders = row.orderNewCustomerCount || 0;
        const totalNewCustomerInteractions = row.newCustomerCount || 0;
        return totalNewCustomerInteractions ? ((newCustomerOrders / totalNewCustomerInteractions) * 100).toFixed(0) + "%" : "0%";
    };

    const calculateOldCustomerConversionRate = (row: any) => {
        const oldCustomerOrders = row.orderOldCustomerCount || 0;
        const totalOldCustomerInteractions = row.oldCustomerCount || 0;
        return totalOldCustomerInteractions ? ((oldCustomerOrders / totalOldCustomerInteractions) * 100).toFixed(0) + "%" : "0%";
    };

    const formatConversionRate = (rate?: number) => {
        if (rate === undefined || isNaN(rate)) {
            return "0%";
        }
        return `${rate.toFixed(2)}%`;
    };

    const columns = [
        {
            title: <div style={{ fontSize: 14, fontWeight: 600 }}>Nhân viên</div>,
            dataIndex: "name",
            width: 160,
            key: "name",
            render: (e: any) => {
                return <div style={{ fontSize: 14 }}>{e}</div>
            }
        },
        {
            title: <div style={{ fontSize: 14, fontWeight: 600,padding:10 }}>Tương tác khách hàng</div>,
            children: [
                visibleColumns.oldCustomerCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Số lượt tài khoản tương tác với khách hàng cũ (bằng cả tin nhắn và bình luận)</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Khách hàng cũ (1)</div>
                    </Tooltip>,
                    dataIndex: "oldCustomerCount",
                    width: 70,
                    align: 'center',
                    key: "oldCustomerCount",
                    render: (e: any) => <div>{e}</div>
                },
                visibleColumns.newCustomerCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Số lượt tài khoản tương tác với khách hàng cũ (bằng cả tin nhắn và bình luận)</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Khách hàng mới (2)</div>
                    </Tooltip>,
                    dataIndex: "newCustomerCount",
                    width: 70,
                    align: 'center',
                    key: "newCustomerCount",
                    render: (e: any) => <div>{e}</div>
                },
                visibleColumns.totalCustomerCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Tổng số lượt tài khoản tương tác với khách bằng bình luận và tin nhắn
                        (mỗi khách hàng chỉ tính một lần trong ngày, bao gồm khách hàng cũ.
                        Nếu vừa tương tác bình luận và tin nhắn cho một khách sẽ được tính 1 lần)</div>}>
                        <div style={{
                            textAlign: 'center',
                            fontWeight: 600,
                            fontSize: 12,
                            borderTop: '3px solid #43B5C3',
                            borderLeft: '1px solid #43B5C3',
                            borderRight: '1px solid #43B5C3',
                            paddingTop: 4,
                            borderRadius: '4px 4px 0px 0px'
                        }}>Tổng<br />(1) + (2)</div>
                    </Tooltip>,
                    width: 80,
                    align: 'center',
                    render: (e: any, record: any) => <div style={{
                        fontWeight: 600, textAlign: 'center',
                        fontSize: 12,
                        borderLeft: '1px solid #43B5C3',
                        borderRight: '1px solid #43B5C3',
                        paddingTop: 6,
                    }}>{record.oldCustomerCount + record.newCustomerCount}</div>
                },
                visibleColumns.customerInboxCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Tổng số lượt tài khoản tương tác với khách bằng tin nhắn
                        (mỗi khách hàng chỉ tính một lần trong ngày,
                        bao gồm khách hàng cũ)"</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Tin nhắn</div>
                    </Tooltip>,
                    dataIndex: "customerInboxCount",
                    width: 100,
                    align: 'center',
                    key: "customerInboxCount",
                    render: (e: any) => <div>{e}</div>
                },
                visibleColumns.customerCommentCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Tổng số lượt tài khoản tương tác với khách bằng bình luận
                        (mỗi khách hàng chỉ tính một lần trong ngày,
                        bao gồm khách hàng cũ)</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Bình luận</div>
                    </Tooltip>,
                    width: 100,
                    align: 'center',
                    dataIndex: "customerCommentCount",
                    key: "customerCommentCount",
                    render: (e: any) => <div>{e}</div>
                },
                visibleColumns.newConversationCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Khách hàng từ hội thoại tin nhắn mới tạo (Vẫn tính nếu trước đó đã tương tác khách qua bình luận)</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Hội thoại tin nhắn mới</div>
                    </Tooltip>,
                    width: 80,
                    align: 'center',
                    dataIndex: "newConversationCount",
                    key: "newConversationCount",
                    render: (e: any) => <div>{e}</div>
                },
            ].filter(Boolean),
        },
        {
            title: <div style={{ fontSize: 14, fontWeight: 600,padding:10 }}>Đơn hàng đã chốt</div>,
            children: [
                visibleColumns.orderNewCustomerCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Số đơn hàng chốt được từ khách hàng mới</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Khách mới (3)</div>
                    </Tooltip>,
                    width: 100,
                    align: 'center',
                    dataIndex: "orderNewCustomerCount",
                    key: "orderNewCustomerCount",
                    render: (e: any) => <div>{e}</div>
                },
                visibleColumns.orderOldCustomerCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Số đơn hàng chốt được từ khách hàng cũ</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Khách cũ (4)</div>
                    </Tooltip>,
                    width: 100,
                    align: 'center',
                    dataIndex: "orderOldCustomerCount",
                    key: "orderOldCustomerCount",
                    render: (e: any) => <div>{e}</div>
                },
                visibleColumns.totalOrderCount && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Tổng số đơn hàng chốt được</div>}>
                        <div style={{
                            textAlign: 'center',
                            fontWeight: 600,
                            fontSize: 12,
                            borderTop: '3px solid #43B5C3',
                            borderLeft: '1px solid #43B5C3',
                            borderRight: '1px solid #43B5C3',
                            paddingTop: 4,
                            borderRadius: '4px 4px 0px 0px'
                        }}>Tổng<br />(3) + (4)</div>
                    </Tooltip>,
                    width: 80,
                    align: 'center',
                    render: (e: any, record: any) => <div style={{
                        fontWeight: 600, textAlign: 'center',
                        fontSize: 12,
                        borderLeft: '1px solid #43B5C3',
                        borderRight: '1px solid #43B5C3',
                        paddingTop: 6,
                    }}>{record.orderNewCustomerCount + record.orderOldCustomerCount}</div>
                },
                visibleColumns.totalOrderConversionRate && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Tổng đơn hàng đã chốt / tổng lượt tương tác khách hàng</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Tỉ lệ chuyển đổi tổng đơn hàng</div>
                    </Tooltip>,
                    align: 'center',
                    dataIndex: "totalOrderConversionRate",
                    width: 100,
                    render: (text: any, record: any) => calculateConversionRate(record),
                },
                visibleColumns.newCustomerConversionRate && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Đơn hàng đã chốt khách hàng mới / tổng lượt tương tác khách hàng mới</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Tỉ lệ chuyển đổi khách hàng mới</div>
                    </Tooltip>,
                    align: 'center',
                    dataIndex: "",
                    width: 100,
                    key: "newCustomerConversionRate",
                    render: (text: any, record: any) => calculateNewCustomerConversionRate(record),
                },
                visibleColumns.oldCustomerConversionRate && {
                    title: <Tooltip title={<div style={{ width: 89 }}>Đơn hàng đã chốt khách hàng cũ / tổng lượt tương tác khách hàng cũ"</div>}>
                        <div style={{ textAlign: 'center', fontSize: 12 }}>Tỉ lệ chuyển đổi khách hàng cũ</div>
                    </Tooltip>,
                    align: 'center',
                    dataIndex: "oldCustomerConversionRate",
                    width: 100,
                    key: "oldCustomerConversionRate",
                    render: (text: any, record: any) => calculateOldCustomerConversionRate(record),
                },
            ].filter(Boolean),
        },
    ];

    return (
        <div className={className}>
            <Table
                className="table-container"
                dataSource={data}
                pagination={false}
                bordered
                scroll={{ y: "calc(100vh - 400px)" }}
                columns={columns}
                summary={() => (
                    <Table.Summary.Row style={{ textAlign: 'center', fontWeight: 600 }}>
                        <Table.Summary.Cell index={0}>Tổng</Table.Summary.Cell>
                        {visibleColumns.oldCustomerCount && <Table.Summary.Cell index={1}>{getSummaryData().oldCustomerCount}</Table.Summary.Cell>}
                        {visibleColumns.newCustomerCount && <Table.Summary.Cell index={2}>{getSummaryData().newCustomerCount}</Table.Summary.Cell>}
                        {visibleColumns.totalCustomerCount && <Table.Summary.Cell index={3}><div style={{
                            fontWeight: 600, textAlign: 'center',
                            fontSize: 12,
                            borderLeft: '1px solid #43B5C3',
                            borderRight: '1px solid #43B5C3',
                            borderBottom: '3px solid #43B5C3',
                            paddingTop: 6,
                        }}>{getSummaryData().totalCustomerCount}</div></Table.Summary.Cell>}
                        {visibleColumns.customerInboxCount && <Table.Summary.Cell index={4}>{getSummaryData().customerInboxCount}</Table.Summary.Cell>}
                        {visibleColumns.customerCommentCount && <Table.Summary.Cell index={5}>{getSummaryData().customerCommentCount}</Table.Summary.Cell>}
                        {visibleColumns.newConversationCount && <Table.Summary.Cell index={6}>{getSummaryData().newConversationCount}</Table.Summary.Cell>}
                        {visibleColumns.orderNewCustomerCount && <Table.Summary.Cell index={7}>{getSummaryData().orderNewCustomerCount}</Table.Summary.Cell>}
                        {visibleColumns.orderOldCustomerCount && <Table.Summary.Cell index={8}>{getSummaryData().orderOldCustomerCount}</Table.Summary.Cell>}
                        {visibleColumns.totalOrderCount && <Table.Summary.Cell index={9}><div style={{
                            fontWeight: 600, textAlign: 'center',
                            fontSize: 12,
                            borderLeft: '1px solid #43B5C3',
                            borderRight: '1px solid #43B5C3',
                            borderBottom: '3px solid #43B5C3',
                            paddingTop: 6,
                        }}>{getSummaryData().totalOrderCount}</div></Table.Summary.Cell>}
                        {visibleColumns.totalOrderConversionRate && <Table.Summary.Cell index={10}>{getSummaryData().totalOrderConversionRate}</Table.Summary.Cell>}
                        {visibleColumns.newCustomerConversionRate && <Table.Summary.Cell index={11}>{formatConversionRate(parseInt(getSummaryData().newCustomerConversionRate))}</Table.Summary.Cell>}
                        {visibleColumns.oldCustomerConversionRate && <Table.Summary.Cell index={12}>{getSummaryData().oldCustomerConversionRate}</Table.Summary.Cell>}
                    </Table.Summary.Row>
                )}
            />
        </div>
    );
};

export default styled(InteractByStaff)`
  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: none;
  }
    .ant-tooltip-content {
        width: 89px !important;;
    }
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ant-table-thead > tr > th {
    color: #605e5c;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .ant-table-expanded-row-fixed {
    height: 100px;
    .ant-empty-normal {
      margin: 2px 0;
      .ant-empty-normal .ant-empty-image {
        height: 30px;
      }
    }
  }
  .ant-table-cell {
      background-color: #fff;
      position: sticky;
      bottom: 0;
      z-index:0
    }
    
`;
