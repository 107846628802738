import React from "react";

import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
interface EndButtonsProps {
  onSubmit: () => void;
  disabled?: boolean;
}
const EndButtons = ({ onSubmit, disabled }: EndButtonsProps) => {
  return (
    <div>
      <div className="links-list-item">
        <Button
          // disabled={disabled}
          size="small"
          style={{backgroundColor:'#fff', border:'none',marginBottom:2}}
        >
          <i className="bx bxs-send align-middle" style={{color:'rgb(9, 109, 217)',display:'flex', justifyContent:'center',fontSize:24}}></i>
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;
