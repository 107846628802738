export class DATE_FILTER {
    static TODAY = {
        value: 'TODAY',
        label: 'Hôm nay',
    };
    static YESTERDAY = {
        value: 'YESTERDAY',
        label: 'Hôm qua',
    };
    static THIS_WEEK = {
        value: 'THIS_WEEK',
        label: 'Tuần này',
    };
    static LAST_WEEK = {
        value: 'LAST_WEEK',
        label: 'Tuần trước',
    };
    static THIS_MONTH = {
        value: 'THIS_MONTH',
        label: 'Tháng này',
    };
    static LAST_MONTH = {
        value: 'LAST_MONTH',
        label: 'Tháng trước',
    };
    static THIS_YEAR = {
        value: 'THIS_YEAR',
        label: 'Năm nay',
    };
    static LAST_YEAR = {
        value: 'LAST_YEAR',
        label: 'Năm ngoái',
    };
    static LAST_7_DAYS = {
        value: 'LAST_7_DAYS',
        label: '7 ngày trước',
    };
    static LAST_30_DAYS = {
        value: 'LAST_30_DAYS',
        label: '30 ngày trước',
    };
    static LAST_60_DAYS = {
        value: 'LAST_60_DAYS',
        label: '60 ngày trước',
    };
    static LAST_90_DAYS = {
        value: 'LAST_90_DAYS',
        label: '90 ngày trước',
    };


};