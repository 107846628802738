import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
// components
// hooks
import { useRedux } from "../../../../hooks/index";
import { createSelector } from "reselect";
import {
    CloseCircleOutlined,
  } from '@ant-design/icons';
// styles
import styled from "styled-components";
import { uploadListImageDraft } from "../../../../redux/actions";


interface IndexProps {
  className?: string;
}
const ListImageDraft = ({
  className,
}: IndexProps) => {
    const { dispatch, useAppSelector } = useRedux();
    const errorData = createSelector(
        (state: any) => state.Chats,
        (state) => ({
            channelSelected: state.channelSelected,
            selectedChat: state.selectedChat,
            listImageDraft: state.listImageDraft,
        })
    );
    // Inside your component
    const {listImageDraft } = useAppSelector(errorData);

    const onDeleteImage = (item: any) => {
        const listImageDraftNew = listImageDraft.filter((image: any) => image.id !== item.id);
        dispatch(uploadListImageDraft([...listImageDraftNew]))
    }
    return (
        listImageDraft?.length > 0 &&
            <div className={className}>
                {listImageDraft?.map((item: any) => (
                    <div className="item_image" key={item?.id}>
                        <img className="image" src={item?.location} alt={item?.originalname} />
                        <div className="icon_close" onClick={() => onDeleteImage(item)}><CloseCircleOutlined /></div>
                    </div>
                ))}
            </div>
  );
};

export default styled(ListImageDraft)`
    background-color: rgba(0, 0, 0, 0.05);
    padding: 12px 8px 4px 8px;
    flex-wrap: wrap;
    display: flex;
    .item_image {
        margin-bottom: 5px;
        margin-right: 10px;
        position: relative;
        width: 64px;
        height: 64px;
    }
    .image {
        width: 64px;
        height: 64px;
    }
    .icon_close {
        position: absolute;
        top: -8px;
        right: -5px;
        cursor: pointer;
        :hover {
            color: red;
        }
    }
`
