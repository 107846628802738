import React, { useState } from "react";

import classnames from "classnames";

// component
import ChannelsPage from "./ChannelsPage/index";

interface IndexProps { }

const Index = (props: IndexProps) => {
  return (
    <div
      className={classnames("user-chat", "w-100", "overflow-hidden")}
      id="user-chat"
    >
      <div className="chat-content p-3 p-lg-4 w-100 h-100">
        <ChannelsPage />
      </div>
    </div>
  );
};

export default Index;
