/*
pages
*/
//root
import Root from "../pages/Root/index";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// dashboard
import Dashboard from "../pages/Dashboard/index";
import StarterPage from "../pages/StarterPage/index";

// conversation
import ConversationList from "../pages/ConversationList/index";

// tags
import Tags from "../pages/Tags/index";

// Statistic
import LayoutStatistic from "../pages/Statistic/index";
import Overview from "../pages/Statistic/Overview";
import Inbox from "../pages/Statistic/Interact";

// order
import OrderedList from "../pages/OrderedList";

// setting
import LayoutSetting from "../pages/Setting/index";

// quick-replies
import QuickReplies from "../pages/QuickReplies/index";
import SyncMessage from "../pages/SyncMessage";
import UserManagement from "../pages/UserManagement";
import GroupManagement from "../pages/GroupManagement";
import AssignPage from "../pages/AssignPage";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const publicRoutes: Array<RouteProps> = [
  { path: "/auth-login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
];

const privateRoutes: Array<RouteProps> = [
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/list-order", component: <Dashboard /> },
  { path: "/conversation-list", component: <ConversationList /> },
  { path: "/ordered-list", component: <OrderedList /> },
  { path: "/user-management", component: <UserManagement /> },
  { path: "/group-management", component: <GroupManagement /> },
  { path: "/assign-page", component: <AssignPage /> },
  {
    path: "/setting/tags",
    component:
      <LayoutSetting>
        <Tags />
      </LayoutSetting>
  },
  {
    path: "/setting/quick-replies",
    component:
      <LayoutSetting>
        <QuickReplies />
      </LayoutSetting>
  },
  {
    path: "/setting/sync-message",
    component:
      <LayoutSetting>
        <SyncMessage />
      </LayoutSetting>
  },
  {
    path: "/statistic/overview",
    component:
      <LayoutStatistic>
        <Overview />
      </LayoutStatistic>
  },
  {
    path: "/statistic/inbox",
    component:
      <LayoutStatistic>
        <Inbox />
      </LayoutStatistic>
  },
  { path: "/", component: <Root /> },
];

export { publicRoutes, privateRoutes };
