import { styled } from "styled-components";
// hooks
import { SkinOutlined, HomeOutlined, PhoneOutlined, IdcardOutlined, WalletOutlined,
     ClockCircleOutlined, FileTextOutlined, UserOutlined, RightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import formatNumber from "../../../../utils/formatNumber";
import moment from 'moment';
import { Tooltip } from 'antd';


interface IndexProps {
  className?: string;
  item: any;
}


const ItemOrder = (props: IndexProps) => {
  const {item} = props;

  const renderOrderLines = (item: any) => {
    if(item?.orderLines?.length > 1) {
      return <Tooltip getPopupContainer={(trigger: any) => trigger.parentNode} color={"#fff"} title={<div>
        {
          item.orderLines.map((item: any, index: number) => (
            <div key={index}>
                <span className="color_many_order mx-2">{item.productId} x {item.qty}</span>
            </div>))
        }
      </div>}>
      <div className="title title_many_order mx-2">Nhiều sản phẩm <RightOutlined/></div>    </Tooltip>
    }
    if(item?.orderLines?.length == 1) { 
      return item.orderLines.map((item: any, index: number) => (
        <div key={index}>
            <span className="title mx-2">{item.productId} x {item.qty}</span>
        </div>))
    } else {
      return <div><span className="not_product mx-2"> Chưa có sản phẩm nào</span></div>
    }
  }
  let sumPrice = _.sumBy(item.orderLines, function (o: any) { return o.price; });
  return (
    <div className={props.className}>
        <div>
          <IdcardOutlined/><span className="mx-2">{item?.shippingAddress?.fullName || ''}</span>
        </div>
        <div>
          <PhoneOutlined/><span className="mx-2">{item?.shippingAddress?.phone || ''}</span>
        </div>
        <div>
          <HomeOutlined/><span className="mx-2">{item?.shippingAddress?.streetLine1 || ''} {item?.shippingAddress?.streetLine2 || ''} {item?.shippingAddress?.city || ''}</span>
        </div>
        <div className="d-flex">
          <SkinOutlined/>{renderOrderLines(item)}
        </div>
        <div><WalletOutlined/><span className="color_price mx-2">{formatNumber((item?.total || 0) - (item?.discount || 0))}</span></div>

        <div>
          <ClockCircleOutlined/><span className="mx-2">Tạo lúc: {moment(item?.createdAt).format("HH:mm")}</span>
        </div>
        <div>
          <FileTextOutlined/><span className="mx-2">Ghi chú: {item.note || 'Chưa có'}</span>
        </div>
        <div>
          <UserOutlined/><span className="mx-2">NV sửa cuối: {item?.updatedBy?.name || ''}</span>
        </div>
        <div>
          <UserOutlined/><span className="mx-2">NV tạo đơn:  {item?.createdBy?.name || ''}</span>
        </div>
    </div>
  );
};




export default styled(ItemOrder)`
  .title {
  }
  .not_product {
    color: rgb(255, 56, 96);
    font-weight: 600;
  }
  .color_price {
    color: #389E0D;
    font-weight: 600;
  }
  .title_many_order {
    color: rgb(16, 142, 233);
  }
  .color_many_order {
    color: #000
  }
`