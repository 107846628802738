import { Centrifuge } from 'centrifuge';

async function getToken() {
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/realtime/token`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
    if (res.ok) {
        const result = await res.json();
        return result.data.token;
    }
}

export const client = new Centrifuge(process.env.REACT_APP_WEBSOCKET_URL, {
  getToken
});